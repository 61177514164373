/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RsvpGuestBookSummaryResponseData
 */
export interface RsvpGuestBookSummaryResponseData {
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  event_title?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_checked_in?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_invitations_confirmed?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_pax_attended?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_pax_confirmed?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_received_gift?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponseData
   */
  total_received_souvenir?: number;
}

/**
 * Check if a given object implements the RsvpGuestBookSummaryResponseData interface.
 */
export function instanceOfRsvpGuestBookSummaryResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestBookSummaryResponseDataFromJSON(
  json: any
): RsvpGuestBookSummaryResponseData {
  return RsvpGuestBookSummaryResponseDataFromJSONTyped(json, false);
}

export function RsvpGuestBookSummaryResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestBookSummaryResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_title: !exists(json, "event_title") ? undefined : json["event_title"],
    total_checked_in: !exists(json, "total_checked_in")
      ? undefined
      : json["total_checked_in"],
    total_invitations_confirmed: !exists(json, "total_invitations_confirmed")
      ? undefined
      : json["total_invitations_confirmed"],
    total_pax_attended: !exists(json, "total_pax_attended")
      ? undefined
      : json["total_pax_attended"],
    total_pax_confirmed: !exists(json, "total_pax_confirmed")
      ? undefined
      : json["total_pax_confirmed"],
    total_received_gift: !exists(json, "total_received_gift")
      ? undefined
      : json["total_received_gift"],
    total_received_souvenir: !exists(json, "total_received_souvenir")
      ? undefined
      : json["total_received_souvenir"],
  };
}

export function RsvpGuestBookSummaryResponseDataToJSON(
  value?: RsvpGuestBookSummaryResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_title: value.event_title,
    total_checked_in: value.total_checked_in,
    total_invitations_confirmed: value.total_invitations_confirmed,
    total_pax_attended: value.total_pax_attended,
    total_pax_confirmed: value.total_pax_confirmed,
    total_received_gift: value.total_received_gift,
    total_received_souvenir: value.total_received_souvenir,
  };
}
