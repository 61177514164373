import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { RoutePath } from "@constants/RoutesNavigator";
import type { User } from "@/services/openapi/v1";
import { useQueryClient } from "react-query";

interface Props {
  history: RouteComponentProps["history"];
  authUser: User;
}

const ProfileMenu = (props: Props) => {
  const { history, authUser } = props;
  const queryClient = useQueryClient();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const logout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    queryClient.removeQueries();
    history.push(RoutePath.LOGIN);
  };

  return (
    <React.Fragment>
      <Dropdown
        show={menu}
        onToggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <Dropdown.Toggle
          className="btn header-item"
          id="page-header-user-dropdown"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
          <div className="rounded-circle header-profile-user default-avatar">
            <i className="bx bxs-user"></i>
          </div>

          <span className="d-none d-md-inline-block ms-2 me-1">
            {authUser?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Item tag="a" href="#">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile{" "}
          </Dropdown.Item>
          <Dropdown.Item tag="button" onClick={logout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1" />
            Logout{" "}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authReducer,
  };
};

export default withRouter(connect(mapStateToProps)(ProfileMenu));
