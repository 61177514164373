/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const Template = {
  TEMPLATE_1: "TEMPLATE_1",
  TEMPLATE_2: "TEMPLATE_2",
  TEMPLATE_2_1: "TEMPLATE_2_1",
  TEMPLATE_2_2: "TEMPLATE_2_2",
  TEMPLATE_3: "TEMPLATE_3",
  TEMPLATE_4: "TEMPLATE_4",
  TEMPLATE_5: "TEMPLATE_5",
  TEMPLATE_6: "TEMPLATE_6",
  TEMPLATE_7: "TEMPLATE_7",
  CUSTOM: "CUSTOM",
} as const;
export type Template = (typeof Template)[keyof typeof Template];

export function TemplateFromJSON(json: any): Template {
  return TemplateFromJSONTyped(json, false);
}

export function TemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Template {
  return json as Template;
}

export function TemplateToJSON(value?: Template | null): any {
  return value as any;
}
