/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpConfirmationStatus } from "./RsvpConfirmationStatus";
import {
  RsvpConfirmationStatusFromJSON,
  RsvpConfirmationStatusFromJSONTyped,
  RsvpConfirmationStatusToJSON,
} from "./RsvpConfirmationStatus";

/**
 *
 * @export
 * @interface SearchGuestsInCheckInAppResponseData
 */
export interface SearchGuestsInCheckInAppResponseData {
  /**
   *
   * @type {string}
   * @memberof SearchGuestsInCheckInAppResponseData
   */
  code?: string;
  /**
   *
   * @type {RsvpConfirmationStatus}
   * @memberof SearchGuestsInCheckInAppResponseData
   */
  confirmation_status?: RsvpConfirmationStatus;
  /**
   *
   * @type {string}
   * @memberof SearchGuestsInCheckInAppResponseData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchGuestsInCheckInAppResponseData
   */
  phone_number?: string;
}

/**
 * Check if a given object implements the SearchGuestsInCheckInAppResponseData interface.
 */
export function instanceOfSearchGuestsInCheckInAppResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function SearchGuestsInCheckInAppResponseDataFromJSON(
  json: any
): SearchGuestsInCheckInAppResponseData {
  return SearchGuestsInCheckInAppResponseDataFromJSONTyped(json, false);
}

export function SearchGuestsInCheckInAppResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SearchGuestsInCheckInAppResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, "code") ? undefined : json["code"],
    confirmation_status: !exists(json, "confirmation_status")
      ? undefined
      : RsvpConfirmationStatusFromJSON(json["confirmation_status"]),
    name: !exists(json, "name") ? undefined : json["name"],
    phone_number: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
  };
}

export function SearchGuestsInCheckInAppResponseDataToJSON(
  value?: SearchGuestsInCheckInAppResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    confirmation_status: RsvpConfirmationStatusToJSON(
      value.confirmation_status
    ),
    name: value.name,
    phone_number: value.phone_number,
  };
}
