/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RsvpGuestListRequest
 */
export interface RsvpGuestListRequest {
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListRequest
   */
  event_id?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListRequest
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListRequest
   */
  page?: number;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListRequest
   */
  search_query?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof RsvpGuestListRequest
   */
  slugs?: Array<string>;
}

/**
 * Check if a given object implements the RsvpGuestListRequest interface.
 */
export function instanceOfRsvpGuestListRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestListRequestFromJSON(json: any): RsvpGuestListRequest {
  return RsvpGuestListRequestFromJSONTyped(json, false);
}

export function RsvpGuestListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestListRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_id: !exists(json, "event_id") ? undefined : json["event_id"],
    limit: !exists(json, "limit") ? undefined : json["limit"],
    page: !exists(json, "page") ? undefined : json["page"],
    search_query: !exists(json, "search_query")
      ? undefined
      : json["search_query"],
    slugs: !exists(json, "slugs") ? undefined : json["slugs"],
  };
}

export function RsvpGuestListRequestToJSON(
  value?: RsvpGuestListRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_id: value.event_id,
    limit: value.limit,
    page: value.page,
    search_query: value.search_query,
    slugs: value.slugs,
  };
}
