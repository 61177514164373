/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExportRsvpGuestBookRequest
 */
export interface ExportRsvpGuestBookRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof ExportRsvpGuestBookRequest
   */
  event_ids: Array<number>;
}

/**
 * Check if a given object implements the ExportRsvpGuestBookRequest interface.
 */
export function instanceOfExportRsvpGuestBookRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "event_ids" in value;

  return isInstance;
}

export function ExportRsvpGuestBookRequestFromJSON(
  json: any
): ExportRsvpGuestBookRequest {
  return ExportRsvpGuestBookRequestFromJSONTyped(json, false);
}

export function ExportRsvpGuestBookRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExportRsvpGuestBookRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_ids: json["event_ids"],
  };
}

export function ExportRsvpGuestBookRequestToJSON(
  value?: ExportRsvpGuestBookRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_ids: value.event_ids,
  };
}
