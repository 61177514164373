/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Template } from "./Template";
import {
  TemplateFromJSON,
  TemplateFromJSONTyped,
  TemplateToJSON,
} from "./Template";

/**
 *
 * @export
 * @interface GetLockConfigByOptionSlugResponse
 */
export interface GetLockConfigByOptionSlugResponse {
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  base_color?: string;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  bride_name?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  custom_config?: { [key: string]: object };
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  custom_css?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  groom_name?: string;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  status_code?: number;
  /**
   *
   * @type {Template}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  template?: Template;
  /**
   *
   * @type {string}
   * @memberof GetLockConfigByOptionSlugResponse
   */
  title?: string;
}

/**
 * Check if a given object implements the GetLockConfigByOptionSlugResponse interface.
 */
export function instanceOfGetLockConfigByOptionSlugResponse(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetLockConfigByOptionSlugResponseFromJSON(
  json: any
): GetLockConfigByOptionSlugResponse {
  return GetLockConfigByOptionSlugResponseFromJSONTyped(json, false);
}

export function GetLockConfigByOptionSlugResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLockConfigByOptionSlugResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    base_color: !exists(json, "base_color") ? undefined : json["base_color"],
    bride_name: !exists(json, "bride_name") ? undefined : json["bride_name"],
    custom_config: !exists(json, "custom_config")
      ? undefined
      : json["custom_config"],
    custom_css: !exists(json, "custom_css") ? undefined : json["custom_css"],
    description: !exists(json, "description") ? undefined : json["description"],
    groom_name: !exists(json, "groom_name") ? undefined : json["groom_name"],
    image_url: !exists(json, "image_url") ? undefined : json["image_url"],
    primary_color: !exists(json, "primary_color")
      ? undefined
      : json["primary_color"],
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
    template: !exists(json, "template")
      ? undefined
      : TemplateFromJSON(json["template"]),
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function GetLockConfigByOptionSlugResponseToJSON(
  value?: GetLockConfigByOptionSlugResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    base_color: value.base_color,
    bride_name: value.bride_name,
    custom_config: value.custom_config,
    custom_css: value.custom_css,
    description: value.description,
    groom_name: value.groom_name,
    image_url: value.image_url,
    primary_color: value.primary_color,
    status: value.status,
    status_code: value.status_code,
    template: TemplateToJSON(value.template),
    title: value.title,
  };
}
