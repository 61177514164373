/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpGuestBookSummaryResponseData } from "./RsvpGuestBookSummaryResponseData";
import {
  RsvpGuestBookSummaryResponseDataFromJSON,
  RsvpGuestBookSummaryResponseDataFromJSONTyped,
  RsvpGuestBookSummaryResponseDataToJSON,
} from "./RsvpGuestBookSummaryResponseData";

/**
 *
 * @export
 * @interface RsvpGuestBookSummaryResponse
 */
export interface RsvpGuestBookSummaryResponse {
  /**
   *
   * @type {RsvpGuestBookSummaryResponseData}
   * @memberof RsvpGuestBookSummaryResponse
   */
  data?: RsvpGuestBookSummaryResponseData;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookSummaryResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookSummaryResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the RsvpGuestBookSummaryResponse interface.
 */
export function instanceOfRsvpGuestBookSummaryResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestBookSummaryResponseFromJSON(
  json: any
): RsvpGuestBookSummaryResponse {
  return RsvpGuestBookSummaryResponseFromJSONTyped(json, false);
}

export function RsvpGuestBookSummaryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestBookSummaryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : RsvpGuestBookSummaryResponseDataFromJSON(json["data"]),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function RsvpGuestBookSummaryResponseToJSON(
  value?: RsvpGuestBookSummaryResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: RsvpGuestBookSummaryResponseDataToJSON(value.data),
    status: value.status,
    status_code: value.status_code,
  };
}
