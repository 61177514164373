/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RsvpSummaryResponseData
 */
export interface RsvpSummaryResponseData {
  /**
   *
   * @type {string}
   * @memberof RsvpSummaryResponseData
   */
  event_title?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_confirmed?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_declined?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_invitations?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_pax?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_pax_confirmed?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpSummaryResponseData
   */
  total_pending?: number;
}

/**
 * Check if a given object implements the RsvpSummaryResponseData interface.
 */
export function instanceOfRsvpSummaryResponseData(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpSummaryResponseDataFromJSON(
  json: any
): RsvpSummaryResponseData {
  return RsvpSummaryResponseDataFromJSONTyped(json, false);
}

export function RsvpSummaryResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpSummaryResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_title: !exists(json, "event_title") ? undefined : json["event_title"],
    total_confirmed: !exists(json, "total_confirmed")
      ? undefined
      : json["total_confirmed"],
    total_declined: !exists(json, "total_declined")
      ? undefined
      : json["total_declined"],
    total_invitations: !exists(json, "total_invitations")
      ? undefined
      : json["total_invitations"],
    total_pax: !exists(json, "total_pax") ? undefined : json["total_pax"],
    total_pax_confirmed: !exists(json, "total_pax_confirmed")
      ? undefined
      : json["total_pax_confirmed"],
    total_pending: !exists(json, "total_pending")
      ? undefined
      : json["total_pending"],
  };
}

export function RsvpSummaryResponseDataToJSON(
  value?: RsvpSummaryResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_title: value.event_title,
    total_confirmed: value.total_confirmed,
    total_declined: value.total_declined,
    total_invitations: value.total_invitations,
    total_pax: value.total_pax,
    total_pax_confirmed: value.total_pax_confirmed,
    total_pending: value.total_pending,
  };
}
