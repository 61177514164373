/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GuestBookEventDetailResponseData
 */
export interface GuestBookEventDetailResponseData {
  /**
   *
   * @type {string}
   * @memberof GuestBookEventDetailResponseData
   */
  event_date?: string;
  /**
   *
   * @type {string}
   * @memberof GuestBookEventDetailResponseData
   */
  guest_book_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof GuestBookEventDetailResponseData
   */
  guest_book_logo_url?: string;
  /**
   *
   * @type {number}
   * @memberof GuestBookEventDetailResponseData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GuestBookEventDetailResponseData
   */
  title?: string;
}

/**
 * Check if a given object implements the GuestBookEventDetailResponseData interface.
 */
export function instanceOfGuestBookEventDetailResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GuestBookEventDetailResponseDataFromJSON(
  json: any
): GuestBookEventDetailResponseData {
  return GuestBookEventDetailResponseDataFromJSONTyped(json, false);
}

export function GuestBookEventDetailResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GuestBookEventDetailResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_date: !exists(json, "event_date") ? undefined : json["event_date"],
    guest_book_image_url: !exists(json, "guest_book_image_url")
      ? undefined
      : json["guest_book_image_url"],
    guest_book_logo_url: !exists(json, "guest_book_logo_url")
      ? undefined
      : json["guest_book_logo_url"],
    id: !exists(json, "id") ? undefined : json["id"],
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function GuestBookEventDetailResponseDataToJSON(
  value?: GuestBookEventDetailResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_date: value.event_date,
    guest_book_image_url: value.guest_book_image_url,
    guest_book_logo_url: value.guest_book_logo_url,
    id: value.id,
    title: value.title,
  };
}
