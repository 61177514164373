/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const FooterStyle = {
  HIDDEN_FOOTER_STYLE: "HIDDEN",
  STYLE1: "STYLE1",
} as const;
export type FooterStyle = (typeof FooterStyle)[keyof typeof FooterStyle];

export function FooterStyleFromJSON(json: any): FooterStyle {
  return FooterStyleFromJSONTyped(json, false);
}

export function FooterStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FooterStyle {
  return json as FooterStyle;
}

export function FooterStyleToJSON(value?: FooterStyle | null): any {
  return value as any;
}
