import type { ImageData } from "hooks/ImageUploader";
import type { ResponseDetail } from "@/services/openapi/v1";
import { ApiInstance } from ".";

export const uploadNewImageService = async (
  data: ImageData
): Promise<ResponseDetail> => {
  const fetchedData = await ApiInstance()
    .filesApi()
    .filesCreate(
      data.optionId,
      data.file_upload,
      data.section_type,
      data.sub_section,
      data.order
    );

  return fetchedData.data;
};

export const uploadUpdateImageService = async (
  data: ImageData
): Promise<ResponseDetail> => {
  const fetchedData = await ApiInstance()
    .filesApi()
    .filesUpdate(
      data.optionId,
      data.file_upload,
      data.section_type,
      data.sub_section,
      data.order,
      data.old_file_url
    );

  return fetchedData.data;
};
