import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useImageUploader from "@hooks/ImageUploader";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import type { Client, HeroBackground } from "@/services/openapi/v1";
import { ImageWrapper } from "@shared/styles/FormStyles";

const HeroBackgrounds = () => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Client>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "content.hero_backgrounds",
    keyName: "idHeroBackground",
  });

  const params = useParams<{ id: string }>();

  const [photoUrl, setPhotoUrl] = useState<string[]>(
    (
      fields as unknown as (HeroBackground & { idHeroBackground: string })[]
    ).map((item) => item.picture_url || "")
  );

  const [selectedHeroBgPhoto, setSelectedHeroBgPhoto] = useState("");
  const [selectedHeroBgPhotoindex, setSelectedHeroBgPhotoindex] = useState(-1);

  const handleClickUpdatePhoto = (
    heroBgUrl: string,
    heroBgPhotoIndex: number
  ) => {
    setSelectedHeroBgPhoto(heroBgUrl);
    setSelectedHeroBgPhotoindex(heroBgPhotoIndex);
  };

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }

    setSelectedHeroBgPhoto("");
    setSelectedHeroBgPhotoindex(-1);
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_HERO_BACKGROUND",
      old_file_url: selectedHeroBgPhoto,
      order: selectedHeroBgPhotoindex + 1,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue(
        `content.hero_backgrounds.${selectedHeroBgPhotoindex}.picture_url`,
        uploadResponse
      );
      if (photoUrl.length === selectedHeroBgPhotoindex) {
        setPhotoUrl([...photoUrl, uploadResponse]);
      } else {
        setPhotoUrl(
          photoUrl.map((photo, index) => {
            if (index === selectedHeroBgPhotoindex) {
              return uploadResponse;
            }
            return photo;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedHeroBgPhotoindex >= 0) {
      handleUpdatePhoto();
    }
  }, [selectedHeroBgPhotoindex, selectedHeroBgPhoto]);

  return (
    <>
      <section className="mb-2">
        <Button
          variant="primary"
          onClick={() => {
            append({});
          }}
        >
          Add Hero Background
        </Button>
      </section>
      <Accordion alwaysOpen>
        {(
          fields as unknown as (HeroBackground & { idHeroBackground: string })[]
        ).map((item, index) => {
          const error =
            errors.content?.hero_backgrounds &&
            errors.content?.hero_backgrounds[`${index}`];
          return (
            <Accordion.Item
              eventKey={index.toString()}
              key={item.idHeroBackground}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Accordion.Header
                  style={{ flex: "1" }}
                  className={error ? "bg-danger" : ""}
                >{`Hero Background ${index + 1}`}</Accordion.Header>
                <Button
                  variant="danger"
                  onClick={() => remove(index)}
                  style={{ margin: "10px" }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Form.Control
                          type="number"
                          {...register(
                            `content.hero_backgrounds.${index}.order`
                          )}
                          defaultValue={item.order}
                          isInvalid={!!error?.order?.message}
                        />
                        {!!error?.order?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.order?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Overlay Opacity</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.hero_backgrounds.${index}.overlay_opacity`
                          )}
                          defaultValue={item.overlay_opacity}
                          isInvalid={!!error?.overlay_opacity?.message}
                        />
                        {!!error?.overlay_opacity?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.overlay_opacity?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Col>
                  <Col md={6}>
                    <Row>
                      {photoUrl[index] && (
                        <ImageWrapper className="mb-3 mb-lg-0">
                          <img
                            src={photoUrl[index]}
                            alt={`Hero Background [${index}] photo`}
                          />
                        </ImageWrapper>
                      )}
                      <Form.Label>Picture URL</Form.Label>
                      <Form.Group as={Col} md={9}>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.hero_backgrounds.${index}.picture_url`
                          )}
                          defaultValue={item.picture_url}
                          isInvalid={!!error?.picture_url?.message}
                          disabled
                        />
                        {!!error?.picture_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.picture_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Col md={3} className="mt-3 text-end mt-lg-0">
                        <Button
                          type="button"
                          onClick={() =>
                            handleClickUpdatePhoto(photoUrl[index], index)
                          }
                          disabled={!params.id}
                        >
                          Update photo
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <ImageUploadModal />
    </>
  );
};

export default HeroBackgrounds;
