/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CreateUsherUserRequest,
  CreateUsherUserResponse,
  GuestLoginRequest,
  QrScanLoginRequest,
  QrScanLoginResponse,
  UserDetailResponse,
  UserLoginRequest,
  UserLoginResponse,
} from "../models/index";
import {
  CreateUsherUserRequestFromJSON,
  CreateUsherUserRequestToJSON,
  CreateUsherUserResponseFromJSON,
  CreateUsherUserResponseToJSON,
  GuestLoginRequestFromJSON,
  GuestLoginRequestToJSON,
  QrScanLoginRequestFromJSON,
  QrScanLoginRequestToJSON,
  QrScanLoginResponseFromJSON,
  QrScanLoginResponseToJSON,
  UserDetailResponseFromJSON,
  UserDetailResponseToJSON,
  UserLoginRequestFromJSON,
  UserLoginRequestToJSON,
  UserLoginResponseFromJSON,
  UserLoginResponseToJSON,
} from "../models/index";

export interface PublicAuthAdminCreateUsherPostRequest {
  requestBody: CreateUsherUserRequest;
}

export interface PublicAuthClientQrScanLoginPostRequest {
  requestBody: QrScanLoginRequest;
}

export interface PublicAuthGuestLoginPostRequest {
  requestBody: GuestLoginRequest;
}

export interface PublicAuthLoginPostRequest {
  requestBody: UserLoginRequest;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Create User With Usher Role By Admin
   * Create User With Usher Role By Admin
   */
  async publicAuthAdminCreateUsherPostRaw(
    requestParameters: PublicAuthAdminCreateUsherPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateUsherUserResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicAuthAdminCreateUsherPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/auth/admin/create-usher`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreateUsherUserRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateUsherUserResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create User With Usher Role By Admin
   * Create User With Usher Role By Admin
   */
  async publicAuthAdminCreateUsherPost(
    requestParameters: PublicAuthAdminCreateUsherPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateUsherUserResponse> {
    const response = await this.publicAuthAdminCreateUsherPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Qr Scan Login
   * Qr Scan Login
   */
  async publicAuthClientQrScanLoginPostRaw(
    requestParameters: PublicAuthClientQrScanLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<QrScanLoginResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicAuthClientQrScanLoginPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/public/auth/client/qr-scan/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: QrScanLoginRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QrScanLoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * Qr Scan Login
   * Qr Scan Login
   */
  async publicAuthClientQrScanLoginPost(
    requestParameters: PublicAuthClientQrScanLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<QrScanLoginResponse> {
    const response = await this.publicAuthClientQrScanLoginPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get User Detail
   * Get User Detail
   */
  async publicAuthDetailGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserDetailResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/auth/detail`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserDetailResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get User Detail
   * Get User Detail
   */
  async publicAuthDetailGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserDetailResponse> {
    const response = await this.publicAuthDetailGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Guest Login
   * Guest Login
   */
  async publicAuthGuestLoginPostRaw(
    requestParameters: PublicAuthGuestLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserLoginResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicAuthGuestLoginPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/public/auth/guest/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GuestLoginRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserLoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * Guest Login
   * Guest Login
   */
  async publicAuthGuestLoginPost(
    requestParameters: PublicAuthGuestLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserLoginResponse> {
    const response = await this.publicAuthGuestLoginPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * User Login for Admin and Client User
   * User Login
   */
  async publicAuthLoginPostRaw(
    requestParameters: PublicAuthLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserLoginResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicAuthLoginPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/public/auth/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserLoginRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserLoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * User Login for Admin and Client User
   * User Login
   */
  async publicAuthLoginPost(
    requestParameters: PublicAuthLoginPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserLoginResponse> {
    const response = await this.publicAuthLoginPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
