/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const Language = {
  EN: "en",
  ID: "id",
} as const;
export type Language = (typeof Language)[keyof typeof Language];

export function LanguageFromJSON(json: any): Language {
  return LanguageFromJSONTyped(json, false);
}

export function LanguageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Language {
  return json as Language;
}

export function LanguageToJSON(value?: Language | null): any {
  return value as any;
}
