/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const GiftStyle = {
  HIDDEN_GIFT_STYLE: "HIDDEN",
  NO_MODAL: "NO_MODAL",
  NO_ICON: "NO_ICON",
} as const;
export type GiftStyle = (typeof GiftStyle)[keyof typeof GiftStyle];

export function GiftStyleFromJSON(json: any): GiftStyle {
  return GiftStyleFromJSONTyped(json, false);
}

export function GiftStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GiftStyle {
  return json as GiftStyle;
}

export function GiftStyleToJSON(value?: GiftStyle | null): any {
  return value as any;
}
