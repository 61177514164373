/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PaginationMeta } from "./PaginationMeta";
import {
  PaginationMetaFromJSON,
  PaginationMetaFromJSONTyped,
  PaginationMetaToJSON,
} from "./PaginationMeta";
import type { RsvpGuestBookResponseData } from "./RsvpGuestBookResponseData";
import {
  RsvpGuestBookResponseDataFromJSON,
  RsvpGuestBookResponseDataFromJSONTyped,
  RsvpGuestBookResponseDataToJSON,
} from "./RsvpGuestBookResponseData";

/**
 *
 * @export
 * @interface RsvpGuestBookResponse
 */
export interface RsvpGuestBookResponse {
  /**
   *
   * @type {Array<RsvpGuestBookResponseData>}
   * @memberof RsvpGuestBookResponse
   */
  data?: Array<RsvpGuestBookResponseData>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof RsvpGuestBookResponse
   */
  meta?: PaginationMeta;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the RsvpGuestBookResponse interface.
 */
export function instanceOfRsvpGuestBookResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestBookResponseFromJSON(
  json: any
): RsvpGuestBookResponse {
  return RsvpGuestBookResponseFromJSONTyped(json, false);
}

export function RsvpGuestBookResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestBookResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : (json["data"] as Array<any>).map(RsvpGuestBookResponseDataFromJSON),
    meta: !exists(json, "meta")
      ? undefined
      : PaginationMetaFromJSON(json["meta"]),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function RsvpGuestBookResponseToJSON(
  value?: RsvpGuestBookResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : (value.data as Array<any>).map(RsvpGuestBookResponseDataToJSON),
    meta: PaginationMetaToJSON(value.meta),
    status: value.status,
    status_code: value.status_code,
  };
}
