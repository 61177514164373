/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpVersion } from "./RsvpVersion";
import {
  RsvpVersionFromJSON,
  RsvpVersionFromJSONTyped,
  RsvpVersionToJSON,
} from "./RsvpVersion";
import type { UserRole } from "./UserRole";
import {
  UserRoleFromJSON,
  UserRoleFromJSONTyped,
  UserRoleToJSON,
} from "./UserRole";

/**
 *
 * @export
 * @interface UserDetail
 */
export interface UserDetail {
  /**
   *
   * @type {number}
   * @memberof UserDetail
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserDetail
   */
  is_guest_book?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserDetail
   */
  name?: string;
  /**
   *
   * @type {UserRole}
   * @memberof UserDetail
   */
  role?: UserRole;
  /**
   *
   * @type {string}
   * @memberof UserDetail
   */
  username?: string;
  /**
   *
   * @type {RsvpVersion}
   * @memberof UserDetail
   */
  version?: RsvpVersion;
}

/**
 * Check if a given object implements the UserDetail interface.
 */
export function instanceOfUserDetail(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserDetailFromJSON(json: any): UserDetail {
  return UserDetailFromJSONTyped(json, false);
}

export function UserDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    is_guest_book: !exists(json, "is_guest_book")
      ? undefined
      : json["is_guest_book"],
    name: !exists(json, "name") ? undefined : json["name"],
    role: !exists(json, "role") ? undefined : UserRoleFromJSON(json["role"]),
    username: !exists(json, "username") ? undefined : json["username"],
    version: !exists(json, "version")
      ? undefined
      : RsvpVersionFromJSON(json["version"]),
  };
}

export function UserDetailToJSON(value?: UserDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    is_guest_book: value.is_guest_book,
    name: value.name,
    role: UserRoleToJSON(value.role),
    username: value.username,
    version: RsvpVersionToJSON(value.version),
  };
}
