/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ShareBannerStyle = {
  HIDDEN_SHARE_BANNER_STYLE: "HIDDEN",
} as const;
export type ShareBannerStyle =
  (typeof ShareBannerStyle)[keyof typeof ShareBannerStyle];

export function ShareBannerStyleFromJSON(json: any): ShareBannerStyle {
  return ShareBannerStyleFromJSONTyped(json, false);
}

export function ShareBannerStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareBannerStyle {
  return json as ShareBannerStyle;
}

export function ShareBannerStyleToJSON(value?: ShareBannerStyle | null): any {
  return value as any;
}
