/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface StoryInsertRequest
 */
export interface StoryInsertRequest {
  /**
   *
   * @type {number}
   * @memberof StoryInsertRequest
   */
  content_id?: number;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  date_time?: string;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  location?: string;
  /**
   *
   * @type {number}
   * @memberof StoryInsertRequest
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  subtitle?: string;
  /**
   *
   * @type {string}
   * @memberof StoryInsertRequest
   */
  title?: string;
}

/**
 * Check if a given object implements the StoryInsertRequest interface.
 */
export function instanceOfStoryInsertRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function StoryInsertRequestFromJSON(json: any): StoryInsertRequest {
  return StoryInsertRequestFromJSONTyped(json, false);
}

export function StoryInsertRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoryInsertRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content_id: !exists(json, "content_id") ? undefined : json["content_id"],
    date_time: !exists(json, "date_time") ? undefined : json["date_time"],
    description: !exists(json, "description") ? undefined : json["description"],
    image_url: !exists(json, "image_url") ? undefined : json["image_url"],
    location: !exists(json, "location") ? undefined : json["location"],
    order: !exists(json, "order") ? undefined : json["order"],
    subtitle: !exists(json, "subtitle") ? undefined : json["subtitle"],
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function StoryInsertRequestToJSON(
  value?: StoryInsertRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content_id: value.content_id,
    date_time: value.date_time,
    description: value.description,
    image_url: value.image_url,
    location: value.location,
    order: value.order,
    subtitle: value.subtitle,
    title: value.title,
  };
}
