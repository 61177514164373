/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { GetOptionSlugsByClientResponseData } from "./GetOptionSlugsByClientResponseData";
import {
  GetOptionSlugsByClientResponseDataFromJSON,
  GetOptionSlugsByClientResponseDataFromJSONTyped,
  GetOptionSlugsByClientResponseDataToJSON,
} from "./GetOptionSlugsByClientResponseData";

/**
 *
 * @export
 * @interface GetOptionSlugsByClientResponse
 */
export interface GetOptionSlugsByClientResponse {
  /**
   *
   * @type {Array<GetOptionSlugsByClientResponseData>}
   * @memberof GetOptionSlugsByClientResponse
   */
  data?: Array<GetOptionSlugsByClientResponseData>;
  /**
   *
   * @type {string}
   * @memberof GetOptionSlugsByClientResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof GetOptionSlugsByClientResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the GetOptionSlugsByClientResponse interface.
 */
export function instanceOfGetOptionSlugsByClientResponse(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetOptionSlugsByClientResponseFromJSON(
  json: any
): GetOptionSlugsByClientResponse {
  return GetOptionSlugsByClientResponseFromJSONTyped(json, false);
}

export function GetOptionSlugsByClientResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOptionSlugsByClientResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : (json["data"] as Array<any>).map(
          GetOptionSlugsByClientResponseDataFromJSON
        ),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function GetOptionSlugsByClientResponseToJSON(
  value?: GetOptionSlugsByClientResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : (value.data as Array<any>).map(
            GetOptionSlugsByClientResponseDataToJSON
          ),
    status: value.status,
    status_code: value.status_code,
  };
}
