import { CustomSectionHeaders } from "@pages/Clients/ClientWebDetail/styles";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { customTextData } from "@constants/customTextData";
import { formatLabelText } from "@shared/utils/FormUtils/formUtils";
import type { Client } from "@/services/openapi/v1";

const CustomText = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Client>();

  return (
    <Form.Group>
      <Form.Label>
        <CustomSectionHeaders>Custom Text</CustomSectionHeaders>
      </Form.Label>
      <Row>
        {customTextData.map((data, index) => {
          return (
            <React.Fragment key={index}>
              <Col md={6} className="mb-3">
                <Form.Label>{formatLabelText(data.label)}</Form.Label>
                <Form.Control
                  type="input"
                  {...register(`option.custom_text.${data.name}`)}
                  isInvalid={!!errors.option?.custom_text?.[data.name]?.message}
                />
                {!!errors.option?.custom_text?.[data.name]?.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.option?.custom_text?.[data.name]?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </Form.Group>
  );
};

export default CustomText;
