/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { DtoBookingRequestEventDto } from "./DtoBookingRequestEventDto";
import {
  DtoBookingRequestEventDtoFromJSON,
  DtoBookingRequestEventDtoFromJSONTyped,
  DtoBookingRequestEventDtoToJSON,
} from "./DtoBookingRequestEventDto";

/**
 *
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
  /**
   *
   * @type {string}
   * @memberof BookingRequest
   */
  additional_fields?: string;
  /**
   *
   * @type {Array<DtoBookingRequestEventDto>}
   * @memberof BookingRequest
   */
  events?: Array<DtoBookingRequestEventDto>;
  /**
   *
   * @type {number}
   * @memberof BookingRequest
   */
  guest_total?: number;
  /**
   *
   * @type {boolean}
   * @memberof BookingRequest
   */
  is_attending?: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingRequest
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof BookingRequest
   */
  name?: string;
}

/**
 * Check if a given object implements the BookingRequest interface.
 */
export function instanceOfBookingRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function BookingRequestFromJSON(json: any): BookingRequest {
  return BookingRequestFromJSONTyped(json, false);
}

export function BookingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    additional_fields: !exists(json, "additional_fields")
      ? undefined
      : json["additional_fields"],
    events: !exists(json, "events")
      ? undefined
      : (json["events"] as Array<any>).map(DtoBookingRequestEventDtoFromJSON),
    guest_total: !exists(json, "guest_total") ? undefined : json["guest_total"],
    is_attending: !exists(json, "is_attending")
      ? undefined
      : json["is_attending"],
    message: !exists(json, "message") ? undefined : json["message"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function BookingRequestToJSON(value?: BookingRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    additional_fields: value.additional_fields,
    events:
      value.events === undefined
        ? undefined
        : (value.events as Array<any>).map(DtoBookingRequestEventDtoToJSON),
    guest_total: value.guest_total,
    is_attending: value.is_attending,
    message: value.message,
    name: value.name,
  };
}
