/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BrideGroomStyle } from "./BrideGroomStyle";
import {
  BrideGroomStyleFromJSON,
  BrideGroomStyleFromJSONTyped,
  BrideGroomStyleToJSON,
} from "./BrideGroomStyle";
import type { CountdownStyle } from "./CountdownStyle";
import {
  CountdownStyleFromJSON,
  CountdownStyleFromJSONTyped,
  CountdownStyleToJSON,
} from "./CountdownStyle";
import type { FooterStyle } from "./FooterStyle";
import {
  FooterStyleFromJSON,
  FooterStyleFromJSONTyped,
  FooterStyleToJSON,
} from "./FooterStyle";
import type { GalleryStyle } from "./GalleryStyle";
import {
  GalleryStyleFromJSON,
  GalleryStyleFromJSONTyped,
  GalleryStyleToJSON,
} from "./GalleryStyle";
import type { GiftStyle } from "./GiftStyle";
import {
  GiftStyleFromJSON,
  GiftStyleFromJSONTyped,
  GiftStyleToJSON,
} from "./GiftStyle";
import type { HealthProtocolStyle } from "./HealthProtocolStyle";
import {
  HealthProtocolStyleFromJSON,
  HealthProtocolStyleFromJSONTyped,
  HealthProtocolStyleToJSON,
} from "./HealthProtocolStyle";
import type { HeroStyle } from "./HeroStyle";
import {
  HeroStyleFromJSON,
  HeroStyleFromJSONTyped,
  HeroStyleToJSON,
} from "./HeroStyle";
import type { Language } from "./Language";
import {
  LanguageFromJSON,
  LanguageFromJSONTyped,
  LanguageToJSON,
} from "./Language";
import type { OpeningModalStyle } from "./OpeningModalStyle";
import {
  OpeningModalStyleFromJSON,
  OpeningModalStyleFromJSONTyped,
  OpeningModalStyleToJSON,
} from "./OpeningModalStyle";
import type { OurStoryStyle } from "./OurStoryStyle";
import {
  OurStoryStyleFromJSON,
  OurStoryStyleFromJSONTyped,
  OurStoryStyleToJSON,
} from "./OurStoryStyle";
import type { QuoteStyle } from "./QuoteStyle";
import {
  QuoteStyleFromJSON,
  QuoteStyleFromJSONTyped,
  QuoteStyleToJSON,
} from "./QuoteStyle";
import type { RSVPStyle } from "./RSVPStyle";
import {
  RSVPStyleFromJSON,
  RSVPStyleFromJSONTyped,
  RSVPStyleToJSON,
} from "./RSVPStyle";
import type { ShareBannerStyle } from "./ShareBannerStyle";
import {
  ShareBannerStyleFromJSON,
  ShareBannerStyleFromJSONTyped,
  ShareBannerStyleToJSON,
} from "./ShareBannerStyle";
import type { Template } from "./Template";
import {
  TemplateFromJSON,
  TemplateFromJSONTyped,
  TemplateToJSON,
} from "./Template";
import type { WebType } from "./WebType";
import {
  WebTypeFromJSON,
  WebTypeFromJSONTyped,
  WebTypeToJSON,
} from "./WebType";
import type { WeedingEventStyle } from "./WeedingEventStyle";
import {
  WeedingEventStyleFromJSON,
  WeedingEventStyleFromJSONTyped,
  WeedingEventStyleToJSON,
} from "./WeedingEventStyle";
import type { WishesStyle } from "./WishesStyle";
import {
  WishesStyleFromJSON,
  WishesStyleFromJSONTyped,
  WishesStyleToJSON,
} from "./WishesStyle";

/**
 *
 * @export
 * @interface OptionResponse
 */
export interface OptionResponse {
  /**
   *
   * @type {boolean}
   * @memberof OptionResponse
   */
  animation?: boolean;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  announcement?: string;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  base_color?: string;
  /**
   *
   * @type {BrideGroomStyle}
   * @memberof OptionResponse
   */
  bride_groom_style?: BrideGroomStyle;
  /**
   *
   * @type {number}
   * @memberof OptionResponse
   */
  client_id?: number;
  /**
   *
   * @type {CountdownStyle}
   * @memberof OptionResponse
   */
  countdown_style?: CountdownStyle;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof OptionResponse
   */
  custom_config?: { [key: string]: object };
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof OptionResponse
   */
  custom_css?: { [key: string]: object };
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  custom_image_url?: string;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof OptionResponse
   */
  custom_text?: { [key: string]: object };
  /**
   *
   * @type {number}
   * @memberof OptionResponse
   */
  default_guest_total?: number;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  font_url?: string;
  /**
   *
   * @type {FooterStyle}
   * @memberof OptionResponse
   */
  footer_style?: FooterStyle;
  /**
   *
   * @type {GalleryStyle}
   * @memberof OptionResponse
   */
  gallery_style?: GalleryStyle;
  /**
   *
   * @type {GiftStyle}
   * @memberof OptionResponse
   */
  gift_style?: GiftStyle;
  /**
   *
   * @type {HealthProtocolStyle}
   * @memberof OptionResponse
   */
  health_protocol_style?: HealthProtocolStyle;
  /**
   *
   * @type {HeroStyle}
   * @memberof OptionResponse
   */
  hero_style?: HeroStyle;
  /**
   *
   * @type {boolean}
   * @memberof OptionResponse
   */
  is_custom_domain?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OptionResponse
   */
  is_custom_template?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OptionResponse
   */
  is_hidden?: boolean;
  /**
   *
   * @type {Language}
   * @memberof OptionResponse
   */
  language?: Language;
  /**
   *
   * @type {OpeningModalStyle}
   * @memberof OptionResponse
   */
  opening_modal_style?: OpeningModalStyle;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  orders?: string;
  /**
   *
   * @type {OurStoryStyle}
   * @memberof OptionResponse
   */
  our_story_style?: OurStoryStyle;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  primary_color?: string;
  /**
   *
   * @type {QuoteStyle}
   * @memberof OptionResponse
   */
  quote_style?: QuoteStyle;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof OptionResponse
   */
  rsvp_config?: { [key: string]: object };
  /**
   *
   * @type {RSVPStyle}
   * @memberof OptionResponse
   */
  rsvp_style?: RSVPStyle;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  secondary_color?: string;
  /**
   *
   * @type {ShareBannerStyle}
   * @memberof OptionResponse
   */
  share_banner_style?: ShareBannerStyle;
  /**
   *
   * @type {boolean}
   * @memberof OptionResponse
   */
  slider?: boolean;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  song_url?: string;
  /**
   *
   * @type {Template}
   * @memberof OptionResponse
   */
  template?: Template;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  third_color?: string;
  /**
   *
   * @type {string}
   * @memberof OptionResponse
   */
  title_color?: string;
  /**
   *
   * @type {WebType}
   * @memberof OptionResponse
   */
  web_type?: WebType;
  /**
   *
   * @type {WeedingEventStyle}
   * @memberof OptionResponse
   */
  wedding_event_style?: WeedingEventStyle;
  /**
   *
   * @type {WishesStyle}
   * @memberof OptionResponse
   */
  wishes_style?: WishesStyle;
}

/**
 * Check if a given object implements the OptionResponse interface.
 */
export function instanceOfOptionResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OptionResponseFromJSON(json: any): OptionResponse {
  return OptionResponseFromJSONTyped(json, false);
}

export function OptionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OptionResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    animation: !exists(json, "animation") ? undefined : json["animation"],
    announcement: !exists(json, "announcement")
      ? undefined
      : json["announcement"],
    base_color: !exists(json, "base_color") ? undefined : json["base_color"],
    bride_groom_style: !exists(json, "bride_groom_style")
      ? undefined
      : BrideGroomStyleFromJSON(json["bride_groom_style"]),
    client_id: !exists(json, "client_id") ? undefined : json["client_id"],
    countdown_style: !exists(json, "countdown_style")
      ? undefined
      : CountdownStyleFromJSON(json["countdown_style"]),
    custom_config: !exists(json, "custom_config")
      ? undefined
      : json["custom_config"],
    custom_css: !exists(json, "custom_css") ? undefined : json["custom_css"],
    custom_image_url: !exists(json, "custom_image_url")
      ? undefined
      : json["custom_image_url"],
    custom_text: !exists(json, "custom_text") ? undefined : json["custom_text"],
    default_guest_total: !exists(json, "default_guest_total")
      ? undefined
      : json["default_guest_total"],
    font_url: !exists(json, "font_url") ? undefined : json["font_url"],
    footer_style: !exists(json, "footer_style")
      ? undefined
      : FooterStyleFromJSON(json["footer_style"]),
    gallery_style: !exists(json, "gallery_style")
      ? undefined
      : GalleryStyleFromJSON(json["gallery_style"]),
    gift_style: !exists(json, "gift_style")
      ? undefined
      : GiftStyleFromJSON(json["gift_style"]),
    health_protocol_style: !exists(json, "health_protocol_style")
      ? undefined
      : HealthProtocolStyleFromJSON(json["health_protocol_style"]),
    hero_style: !exists(json, "hero_style")
      ? undefined
      : HeroStyleFromJSON(json["hero_style"]),
    is_custom_domain: !exists(json, "is_custom_domain")
      ? undefined
      : json["is_custom_domain"],
    is_custom_template: !exists(json, "is_custom_template")
      ? undefined
      : json["is_custom_template"],
    is_hidden: !exists(json, "is_hidden") ? undefined : json["is_hidden"],
    language: !exists(json, "language")
      ? undefined
      : LanguageFromJSON(json["language"]),
    opening_modal_style: !exists(json, "opening_modal_style")
      ? undefined
      : OpeningModalStyleFromJSON(json["opening_modal_style"]),
    orders: !exists(json, "orders") ? undefined : json["orders"],
    our_story_style: !exists(json, "our_story_style")
      ? undefined
      : OurStoryStyleFromJSON(json["our_story_style"]),
    primary_color: !exists(json, "primary_color")
      ? undefined
      : json["primary_color"],
    quote_style: !exists(json, "quote_style")
      ? undefined
      : QuoteStyleFromJSON(json["quote_style"]),
    rsvp_config: !exists(json, "rsvp_config") ? undefined : json["rsvp_config"],
    rsvp_style: !exists(json, "rsvp_style")
      ? undefined
      : RSVPStyleFromJSON(json["rsvp_style"]),
    secondary_color: !exists(json, "secondary_color")
      ? undefined
      : json["secondary_color"],
    share_banner_style: !exists(json, "share_banner_style")
      ? undefined
      : ShareBannerStyleFromJSON(json["share_banner_style"]),
    slider: !exists(json, "slider") ? undefined : json["slider"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
    song_url: !exists(json, "song_url") ? undefined : json["song_url"],
    template: !exists(json, "template")
      ? undefined
      : TemplateFromJSON(json["template"]),
    third_color: !exists(json, "third_color") ? undefined : json["third_color"],
    title_color: !exists(json, "title_color") ? undefined : json["title_color"],
    web_type: !exists(json, "web_type")
      ? undefined
      : WebTypeFromJSON(json["web_type"]),
    wedding_event_style: !exists(json, "wedding_event_style")
      ? undefined
      : WeedingEventStyleFromJSON(json["wedding_event_style"]),
    wishes_style: !exists(json, "wishes_style")
      ? undefined
      : WishesStyleFromJSON(json["wishes_style"]),
  };
}

export function OptionResponseToJSON(value?: OptionResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    animation: value.animation,
    announcement: value.announcement,
    base_color: value.base_color,
    bride_groom_style: BrideGroomStyleToJSON(value.bride_groom_style),
    client_id: value.client_id,
    countdown_style: CountdownStyleToJSON(value.countdown_style),
    custom_config: value.custom_config,
    custom_css: value.custom_css,
    custom_image_url: value.custom_image_url,
    custom_text: value.custom_text,
    default_guest_total: value.default_guest_total,
    font_url: value.font_url,
    footer_style: FooterStyleToJSON(value.footer_style),
    gallery_style: GalleryStyleToJSON(value.gallery_style),
    gift_style: GiftStyleToJSON(value.gift_style),
    health_protocol_style: HealthProtocolStyleToJSON(
      value.health_protocol_style
    ),
    hero_style: HeroStyleToJSON(value.hero_style),
    is_custom_domain: value.is_custom_domain,
    is_custom_template: value.is_custom_template,
    is_hidden: value.is_hidden,
    language: LanguageToJSON(value.language),
    opening_modal_style: OpeningModalStyleToJSON(value.opening_modal_style),
    orders: value.orders,
    our_story_style: OurStoryStyleToJSON(value.our_story_style),
    primary_color: value.primary_color,
    quote_style: QuoteStyleToJSON(value.quote_style),
    rsvp_config: value.rsvp_config,
    rsvp_style: RSVPStyleToJSON(value.rsvp_style),
    secondary_color: value.secondary_color,
    share_banner_style: ShareBannerStyleToJSON(value.share_banner_style),
    slider: value.slider,
    slug: value.slug,
    song_url: value.song_url,
    template: TemplateToJSON(value.template),
    third_color: value.third_color,
    title_color: value.title_color,
    web_type: WebTypeToJSON(value.web_type),
    wedding_event_style: WeedingEventStyleToJSON(value.wedding_event_style),
    wishes_style: WishesStyleToJSON(value.wishes_style),
  };
}
