/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpGuestBookResponseData } from "./RsvpGuestBookResponseData";
import {
  RsvpGuestBookResponseDataFromJSON,
  RsvpGuestBookResponseDataFromJSONTyped,
  RsvpGuestBookResponseDataToJSON,
} from "./RsvpGuestBookResponseData";

/**
 *
 * @export
 * @interface GetGuestEventDetailByGuestCodeAndEventIdResponse
 */
export interface GetGuestEventDetailByGuestCodeAndEventIdResponse {
  /**
   *
   * @type {RsvpGuestBookResponseData}
   * @memberof GetGuestEventDetailByGuestCodeAndEventIdResponse
   */
  data?: RsvpGuestBookResponseData;
  /**
   *
   * @type {string}
   * @memberof GetGuestEventDetailByGuestCodeAndEventIdResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof GetGuestEventDetailByGuestCodeAndEventIdResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the GetGuestEventDetailByGuestCodeAndEventIdResponse interface.
 */
export function instanceOfGetGuestEventDetailByGuestCodeAndEventIdResponse(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetGuestEventDetailByGuestCodeAndEventIdResponseFromJSON(
  json: any
): GetGuestEventDetailByGuestCodeAndEventIdResponse {
  return GetGuestEventDetailByGuestCodeAndEventIdResponseFromJSONTyped(
    json,
    false
  );
}

export function GetGuestEventDetailByGuestCodeAndEventIdResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGuestEventDetailByGuestCodeAndEventIdResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : RsvpGuestBookResponseDataFromJSON(json["data"]),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function GetGuestEventDetailByGuestCodeAndEventIdResponseToJSON(
  value?: GetGuestEventDetailByGuestCodeAndEventIdResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: RsvpGuestBookResponseDataToJSON(value.data),
    status: value.status,
    status_code: value.status_code,
  };
}
