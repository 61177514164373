/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WebType = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  BIRTHDAY: "BIRTHDAY",
} as const;
export type WebType = (typeof WebType)[keyof typeof WebType];

export function WebTypeFromJSON(json: any): WebType {
  return WebTypeFromJSONTyped(json, false);
}

export function WebTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WebType {
  return json as WebType;
}

export function WebTypeToJSON(value?: WebType | null): any {
  return value as any;
}
