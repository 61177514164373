/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WeedingEventStyle = {
  HIDDEN_WEDDING_EVENT_STYLE: "HIDDEN",
} as const;
export type WeedingEventStyle =
  (typeof WeedingEventStyle)[keyof typeof WeedingEventStyle];

export function WeedingEventStyleFromJSON(json: any): WeedingEventStyle {
  return WeedingEventStyleFromJSONTyped(json, false);
}

export function WeedingEventStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WeedingEventStyle {
  return json as WeedingEventStyle;
}

export function WeedingEventStyleToJSON(value?: WeedingEventStyle | null): any {
  return value as any;
}
