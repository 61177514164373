/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaymentUpdateRequest
 */
export interface PaymentUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  account_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  account_owner?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  bank?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentUpdateRequest
   */
  bank_id?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  custom_button_text?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentUpdateRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  payment_url?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentUpdateRequest
   */
  qr_code_image_url?: string;
}

/**
 * Check if a given object implements the PaymentUpdateRequest interface.
 */
export function instanceOfPaymentUpdateRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PaymentUpdateRequestFromJSON(json: any): PaymentUpdateRequest {
  return PaymentUpdateRequestFromJSONTyped(json, false);
}

export function PaymentUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaymentUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    account_number: !exists(json, "account_number")
      ? undefined
      : json["account_number"],
    account_owner: !exists(json, "account_owner")
      ? undefined
      : json["account_owner"],
    bank: !exists(json, "bank") ? undefined : json["bank"],
    bank_id: !exists(json, "bank_id") ? undefined : json["bank_id"],
    custom_button_text: !exists(json, "custom_button_text")
      ? undefined
      : json["custom_button_text"],
    id: !exists(json, "id") ? undefined : json["id"],
    payment_url: !exists(json, "payment_url") ? undefined : json["payment_url"],
    qr_code_image_url: !exists(json, "qr_code_image_url")
      ? undefined
      : json["qr_code_image_url"],
  };
}

export function PaymentUpdateRequestToJSON(
  value?: PaymentUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    account_number: value.account_number,
    account_owner: value.account_owner,
    bank: value.bank,
    bank_id: value.bank_id,
    custom_button_text: value.custom_button_text,
    id: value.id,
    payment_url: value.payment_url,
    qr_code_image_url: value.qr_code_image_url,
  };
}
