/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BrideRequest
 */
export interface BrideRequest {
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  child_order?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  daughter_order?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  facebook?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  full_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  image_css?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  instagram?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  photo_url?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  prefix_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  suffix_name?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  tiktok?: string;
  /**
   *
   * @type {string}
   * @memberof BrideRequest
   */
  twitter?: string;
}

/**
 * Check if a given object implements the BrideRequest interface.
 */
export function instanceOfBrideRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function BrideRequestFromJSON(json: any): BrideRequest {
  return BrideRequestFromJSONTyped(json, false);
}

export function BrideRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BrideRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    child_order: !exists(json, "child_order") ? undefined : json["child_order"],
    daughter_order: !exists(json, "daughter_order")
      ? undefined
      : json["daughter_order"],
    facebook: !exists(json, "facebook") ? undefined : json["facebook"],
    father_name: !exists(json, "father_name") ? undefined : json["father_name"],
    full_name: !exists(json, "full_name") ? undefined : json["full_name"],
    image_css: !exists(json, "image_css") ? undefined : json["image_css"],
    instagram: !exists(json, "instagram") ? undefined : json["instagram"],
    mother_name: !exists(json, "mother_name") ? undefined : json["mother_name"],
    name: !exists(json, "name") ? undefined : json["name"],
    photo_url: !exists(json, "photo_url") ? undefined : json["photo_url"],
    prefix_name: !exists(json, "prefix_name") ? undefined : json["prefix_name"],
    suffix_name: !exists(json, "suffix_name") ? undefined : json["suffix_name"],
    tiktok: !exists(json, "tiktok") ? undefined : json["tiktok"],
    twitter: !exists(json, "twitter") ? undefined : json["twitter"],
  };
}

export function BrideRequestToJSON(value?: BrideRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    child_order: value.child_order,
    daughter_order: value.daughter_order,
    facebook: value.facebook,
    father_name: value.father_name,
    full_name: value.full_name,
    image_css: value.image_css,
    instagram: value.instagram,
    mother_name: value.mother_name,
    name: value.name,
    photo_url: value.photo_url,
    prefix_name: value.prefix_name,
    suffix_name: value.suffix_name,
    tiktok: value.tiktok,
    twitter: value.twitter,
  };
}
