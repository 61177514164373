/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ContentInsertResponse } from "./ContentInsertResponse";
import {
  ContentInsertResponseFromJSON,
  ContentInsertResponseFromJSONTyped,
  ContentInsertResponseToJSON,
} from "./ContentInsertResponse";
import type { OptionInsertRequest } from "./OptionInsertRequest";
import {
  OptionInsertRequestFromJSON,
  OptionInsertRequestFromJSONTyped,
  OptionInsertRequestToJSON,
} from "./OptionInsertRequest";

/**
 *
 * @export
 * @interface ClientInsertRequest
 */
export interface ClientInsertRequest {
  /**
   *
   * @type {ContentInsertResponse}
   * @memberof ClientInsertRequest
   */
  content?: ContentInsertResponse;
  /**
   *
   * @type {string}
   * @memberof ClientInsertRequest
   */
  name?: string;
  /**
   *
   * @type {OptionInsertRequest}
   * @memberof ClientInsertRequest
   */
  option?: OptionInsertRequest;
}

/**
 * Check if a given object implements the ClientInsertRequest interface.
 */
export function instanceOfClientInsertRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ClientInsertRequestFromJSON(json: any): ClientInsertRequest {
  return ClientInsertRequestFromJSONTyped(json, false);
}

export function ClientInsertRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientInsertRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content: !exists(json, "content")
      ? undefined
      : ContentInsertResponseFromJSON(json["content"]),
    name: !exists(json, "name") ? undefined : json["name"],
    option: !exists(json, "option")
      ? undefined
      : OptionInsertRequestFromJSON(json["option"]),
  };
}

export function ClientInsertRequestToJSON(
  value?: ClientInsertRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: ContentInsertResponseToJSON(value.content),
    name: value.name,
    option: OptionInsertRequestToJSON(value.option),
  };
}
