/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const GalleryStatus = {
  SECTION_1: "SECTION_1",
  SECTION_2: "SECTION_2",
  SECTION_SHARE_BANNER: "SECTION_SHARE_BANNER",
  SECTION_RSVP_BANNER: "SECTION_RSVP_BANNER",
  SECTION_BRIDEGROOM_BANNER: "SECTION_BRIDEGROOM_BANNER",
  SECTION_FOOTER_BANNER: "SECTION_FOOTER_BANNER",
} as const;
export type GalleryStatus = (typeof GalleryStatus)[keyof typeof GalleryStatus];

export function GalleryStatusFromJSON(json: any): GalleryStatus {
  return GalleryStatusFromJSONTyped(json, false);
}

export function GalleryStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GalleryStatus {
  return json as GalleryStatus;
}

export function GalleryStatusToJSON(value?: GalleryStatus | null): any {
  return value as any;
}
