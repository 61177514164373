/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RsvpGuestListResponseDataInnerEventDetailsInner
 */
export interface RsvpGuestListResponseDataInnerEventDetailsInner {
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInnerEventDetailsInner
   */
  confirmation_status?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInnerEventDetailsInner
   */
  event_title?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInnerEventDetailsInner
   */
  invitation_link?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInnerEventDetailsInner
   */
  qr_code?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListResponseDataInnerEventDetailsInner
   */
  total_guest?: number;
}

/**
 * Check if a given object implements the RsvpGuestListResponseDataInnerEventDetailsInner interface.
 */
export function instanceOfRsvpGuestListResponseDataInnerEventDetailsInner(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestListResponseDataInnerEventDetailsInnerFromJSON(
  json: any
): RsvpGuestListResponseDataInnerEventDetailsInner {
  return RsvpGuestListResponseDataInnerEventDetailsInnerFromJSONTyped(
    json,
    false
  );
}

export function RsvpGuestListResponseDataInnerEventDetailsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestListResponseDataInnerEventDetailsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    confirmation_status: !exists(json, "confirmation_status")
      ? undefined
      : json["confirmation_status"],
    event_title: !exists(json, "event_title") ? undefined : json["event_title"],
    invitation_link: !exists(json, "invitation_link")
      ? undefined
      : json["invitation_link"],
    qr_code: !exists(json, "qr_code") ? undefined : json["qr_code"],
    total_guest: !exists(json, "total_guest") ? undefined : json["total_guest"],
  };
}

export function RsvpGuestListResponseDataInnerEventDetailsInnerToJSON(
  value?: RsvpGuestListResponseDataInnerEventDetailsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    confirmation_status: value.confirmation_status,
    event_title: value.event_title,
    invitation_link: value.invitation_link,
    qr_code: value.qr_code,
    total_guest: value.total_guest,
  };
}
