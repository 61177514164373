import type { UseMutationResult, UseMutationOptions } from "react-query";
import { useMutation } from "react-query";
import { createLinksResult } from "@services/api/createLinksResultService";
import type { ResponseDetail } from "@/services/openapi/v1";
import type { AxiosError } from "axios";

const useCreateLink = (
  options?: UseMutationOptions<ResponseDetail, AxiosError, File>
): UseMutationResult => {
  return useMutation((file) => createLinksResult(file), {
    ...options,
  });
};

export default useCreateLink;
