/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const GalleryStyle = {
  HIDDEN_GALLERY_STYLE: "HIDDEN",
  NO_DOWNLOAD_GALLERY_STYLE: "NO_DOWNLOAD",
} as const;
export type GalleryStyle = (typeof GalleryStyle)[keyof typeof GalleryStyle];

export function GalleryStyleFromJSON(json: any): GalleryStyle {
  return GalleryStyleFromJSONTyped(json, false);
}

export function GalleryStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GalleryStyle {
  return json as GalleryStyle;
}

export function GalleryStyleToJSON(value?: GalleryStyle | null): any {
  return value as any;
}
