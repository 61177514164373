import { ApiInstance } from "@/services/api";
import type { Client, Option } from "@/services/openapi/v1";
import {
  mappingCustomText,
  mappingDateDataContent,
} from "@/shared/utils/FormUtils/formUtils";
import { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  revalidateSlug,
  renderPromiseToast,
} from "@/shared/utils/FormUtils/formUtils";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
interface Return {
  data: {
    isLoading: boolean;
    webContent: Client;
    isDisabled: boolean;
    error: Record<string, never>;
  };
  method: {
    onSubmit(data: Client): Promise<void>;
  };
}

export default function useViewModel(): Return {
  const { id }: { id: string } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [webContent, setWebContent] = useState<Client>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [error, setError] = useState({});
  const history = useHistory();

  const onSubmit = useCallback(async (data: Client) => {
    setIsDisabled(true);
    const { content, option } = data;
    const newDataContent = mappingDateDataContent(content);
    const customText = option.custom_text;
    const newCustomText = mappingCustomText(customText);
    const newOption: Option = { ...option, custom_text: newCustomText };
    const newData = {
      ...data,
      content: newDataContent,
      option: newOption,
    };

    try {
      //update client data
      const updateClientData = ApiInstance()
        .clientApi()
        .clientsUpdate(id, newData);
      await renderPromiseToast(
        updateClientData,
        "Updating client data",
        "Success update client data!",
        "Error updating client data"
      );

      //revalidate slug
      console.log(data.id);
      const options = await ApiInstance()
        .clientApi()
        .clientsGetOptions(data.id.toString());
      await renderPromiseToast(
        revalidateSlug(options.data),
        "Revalidating slugs",
        "Success revalidate slugs!",
        "Error revalidating slugs"
      );
      setIsDisabled(false);
      history.push(RoutePath.CLIENT_DETAIL(data.id.toString()));
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
      const { response } = error;
      setError(response.data.error);
    }
  }, []);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const { data: fetchedWebContent } = await ApiInstance()
          .clientApi()
          .clientsDetail(id);
        setWebContent(fetchedWebContent);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClient();
  }, [id]);

  return {
    data: {
      error,
      isDisabled,
      isLoading,
      webContent,
    },
    method: {
      onSubmit,
    },
  };
}
