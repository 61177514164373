/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { HeaderMetaFormatter } from "./HeaderMetaFormatter";
import {
  HeaderMetaFormatterFromJSON,
  HeaderMetaFormatterFromJSONTyped,
  HeaderMetaFormatterToJSON,
} from "./HeaderMetaFormatter";

/**
 *
 * @export
 * @interface HeaderMeta
 */
export interface HeaderMeta {
  /**
   *
   * @type {string}
   * @memberof HeaderMeta
   */
  accessor?: string;
  /**
   *
   * @type {boolean}
   * @memberof HeaderMeta
   */
  disabled_sort_by?: boolean;
  /**
   *
   * @type {Array<HeaderMetaFormatter>}
   * @memberof HeaderMeta
   */
  formatter?: Array<HeaderMetaFormatter>;
  /**
   *
   * @type {string}
   * @memberof HeaderMeta
   */
  header?: string;
  /**
   *
   * @type {string}
   * @memberof HeaderMeta
   */
  id?: string;
}

/**
 * Check if a given object implements the HeaderMeta interface.
 */
export function instanceOfHeaderMeta(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function HeaderMetaFromJSON(json: any): HeaderMeta {
  return HeaderMetaFromJSONTyped(json, false);
}

export function HeaderMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HeaderMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessor: !exists(json, "accessor") ? undefined : json["accessor"],
    disabled_sort_by: !exists(json, "disabled_sort_by")
      ? undefined
      : json["disabled_sort_by"],
    formatter: !exists(json, "formatter")
      ? undefined
      : (json["formatter"] as Array<any>).map(HeaderMetaFormatterFromJSON),
    header: !exists(json, "header") ? undefined : json["header"],
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function HeaderMetaToJSON(value?: HeaderMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    accessor: value.accessor,
    disabled_sort_by: value.disabled_sort_by,
    formatter:
      value.formatter === undefined
        ? undefined
        : (value.formatter as Array<any>).map(HeaderMetaFormatterToJSON),
    header: value.header,
    id: value.id,
  };
}
