/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { LinkRequest } from "../models/index";
import { LinkRequestFromJSON, LinkRequestToJSON } from "../models/index";

export interface DashboardClientsLinksDownloadTemplatePostRequest {
  requestBody: LinkRequest;
}

export interface DashboardClientsLinksGenerateFilePostRequest {
  generateLinksFile: Blob;
}

/**
 *
 */
export class LinksApi extends runtime.BaseAPI {
  /**
   * Download template for generating links
   * Download Template for generating links
   */
  async dashboardClientsLinksDownloadTemplatePostRaw(
    requestParameters: DashboardClientsLinksDownloadTemplatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsLinksDownloadTemplatePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/links/download-template`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LinkRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Download template for generating links
   * Download Template for generating links
   */
  async dashboardClientsLinksDownloadTemplatePost(
    requestParameters: DashboardClientsLinksDownloadTemplatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardClientsLinksDownloadTemplatePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Upload Data for Generate Links
   * Upload Generate Links File
   */
  async dashboardClientsLinksGenerateFilePostRaw(
    requestParameters: DashboardClientsLinksGenerateFilePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.generateLinksFile === null ||
      requestParameters.generateLinksFile === undefined
    ) {
      throw new runtime.RequiredError(
        "generateLinksFile",
        "Required parameter requestParameters.generateLinksFile was null or undefined when calling dashboardClientsLinksGenerateFilePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.generateLinksFile !== undefined) {
      formParams.append(
        "generate-links-file",
        requestParameters.generateLinksFile as any
      );
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/links/generate-file`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Upload Data for Generate Links
   * Upload Generate Links File
   */
  async dashboardClientsLinksGenerateFilePost(
    requestParameters: DashboardClientsLinksGenerateFilePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardClientsLinksGenerateFilePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
