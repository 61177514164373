import type { ComponentType } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setAuthUser } from "@services/AuthUtils";
import type { User, UserRoleEnum } from "@/services/openapi/v1";
import RoleMiddleware from "./RoleMiddleware";
import { RoutePath } from "@constants/RoutesNavigator";
interface Props {
  path: string;
  // TODO: remove this any type with the correct type
  Layout: ComponentType<any>;
  Component: ComponentType<any>;
  isAuthProtected: boolean;
  exact: boolean;
  roleMiddleware?: UserRoleEnum;
  authUser: User;
}

const AuthMiddleware = (props: Props) => {
  const {
    Layout,
    Component,
    path,
    isAuthProtected,
    exact,
    roleMiddleware,
    authUser,
  } = props;
  const token = localStorage.getItem("token");

  useEffect(() => {
    setAuthUser(authUser);
  });

  return (
    <Route
      path={path}
      exact={exact}
      render={(routeProps) => {
        if (isAuthProtected && !token) {
          return (
            <Redirect
              to={{
                pathname: RoutePath.LOGIN,
                state: { from: routeProps.location },
              }}
            />
          );
        }
        if (!isAuthProtected && token) {
          return (
            <Redirect
              to={{
                pathname: RoutePath.HOME,
                state: { from: routeProps.location },
              }}
            />
          );
        }

        return (
          <RoleMiddleware
            roleMiddleware={roleMiddleware}
            routeProps={routeProps}
          >
            <Layout>
              <Component {...routeProps} />
            </Layout>
          </RoleMiddleware>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authReducer,
  };
};

export default connect(mapStateToProps)(AuthMiddleware);
