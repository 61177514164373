/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExportRsvpGuestListRequest
 */
export interface ExportRsvpGuestListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ExportRsvpGuestListRequest
   */
  slugs?: Array<string>;
}

/**
 * Check if a given object implements the ExportRsvpGuestListRequest interface.
 */
export function instanceOfExportRsvpGuestListRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ExportRsvpGuestListRequestFromJSON(
  json: any
): ExportRsvpGuestListRequest {
  return ExportRsvpGuestListRequestFromJSONTyped(json, false);
}

export function ExportRsvpGuestListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExportRsvpGuestListRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    slugs: !exists(json, "slugs") ? undefined : json["slugs"],
  };
}

export function ExportRsvpGuestListRequestToJSON(
  value?: ExportRsvpGuestListRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    slugs: value.slugs,
  };
}
