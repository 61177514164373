/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const WishesStyle = {
  HIDDEN_WISHES_STYLE: "HIDDEN",
} as const;
export type WishesStyle = (typeof WishesStyle)[keyof typeof WishesStyle];

export function WishesStyleFromJSON(json: any): WishesStyle {
  return WishesStyleFromJSONTyped(json, false);
}

export function WishesStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WishesStyle {
  return json as WishesStyle;
}

export function WishesStyleToJSON(value?: WishesStyle | null): any {
  return value as any;
}
