import type { CustomColumnExtended } from "@/components/Datatable/Types";
import { useTableCase } from "@/hooks/UseTableCase";
import { useGetClientsDetailData } from "@/hooks/useGetClientsDetailData";
import type { ClientDetail, ClientList } from "@/services/openapi/v1";
import type { ClientParams } from "@/shared/DataType";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
import type { AxiosResponse } from "axios";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import type { Row, TableInstance } from "react-table";

interface Return {
  data: {
    columns: CustomColumnExtended[];
    tableInstance: TableInstance<any>;
    isLoading: boolean;
    data: AxiosResponse<ClientDetail>;
    totalData: number;
  };
  method: {
    handleClickAbleClient(row: Row<ClientList>): void;
    handleCreateWebDetail(): void;
  };
}

export default function useViewModel(): Return {
  const { id } = useParams<ClientParams>();
  const history = useHistory();
  const columns: CustomColumnExtended[] = useMemo(
    () => [
      {
        Header: "Web URL",
        accessor: "web_url",
      },
    ],
    []
  );

  const { data, isLoading } = useGetClientsDetailData(id, {
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const clientsData = useMemo(() => {
    return data ? data.data.options : [];
  }, [data]);

  const tableInstance = useTableCase({
    columns: columns,
    data: clientsData,
  });

  const handleClickAbleClient = (row: Row<ClientList>) => {
    history.push(RoutePath.CLIENT_WEB_DETAIL(row.original.id.toString()));
  };

  const handleCreateWebDetail = () => {
    history.push(RoutePath.CREATE_WEB_DETAIL(id));
  };

  return {
    data: {
      columns,
      isLoading,
      tableInstance,
      data,
      totalData: data?.data?.options.length,
    },
    method: {
      handleClickAbleClient,
      handleCreateWebDetail,
    },
  };
}
