/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RsvpGuestBookResponseData
 */
export interface RsvpGuestBookResponseData {
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponseData
   */
  detail?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponseData
   */
  guest_event_id?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponseData
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof RsvpGuestBookResponseData
   */
  is_checked_in?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RsvpGuestBookResponseData
   */
  is_received_gift?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RsvpGuestBookResponseData
   */
  is_received_souvenir?: boolean;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponseData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponseData
   */
  phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponseData
   */
  qr_code?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookResponseData
   */
  table_number?: string;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponseData
   */
  total_guests?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponseData
   */
  total_guests_attended?: number;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookResponseData
   */
  total_guests_confirmed?: number;
}

/**
 * Check if a given object implements the RsvpGuestBookResponseData interface.
 */
export function instanceOfRsvpGuestBookResponseData(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestBookResponseDataFromJSON(
  json: any
): RsvpGuestBookResponseData {
  return RsvpGuestBookResponseDataFromJSONTyped(json, false);
}

export function RsvpGuestBookResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestBookResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    detail: !exists(json, "detail") ? undefined : json["detail"],
    guest_event_id: !exists(json, "guest_event_id")
      ? undefined
      : json["guest_event_id"],
    id: !exists(json, "id") ? undefined : json["id"],
    is_checked_in: !exists(json, "is_checked_in")
      ? undefined
      : json["is_checked_in"],
    is_received_gift: !exists(json, "is_received_gift")
      ? undefined
      : json["is_received_gift"],
    is_received_souvenir: !exists(json, "is_received_souvenir")
      ? undefined
      : json["is_received_souvenir"],
    name: !exists(json, "name") ? undefined : json["name"],
    phone_number: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    qr_code: !exists(json, "qr_code") ? undefined : json["qr_code"],
    table_number: !exists(json, "table_number")
      ? undefined
      : json["table_number"],
    total_guests: !exists(json, "total_guests")
      ? undefined
      : json["total_guests"],
    total_guests_attended: !exists(json, "total_guests_attended")
      ? undefined
      : json["total_guests_attended"],
    total_guests_confirmed: !exists(json, "total_guests_confirmed")
      ? undefined
      : json["total_guests_confirmed"],
  };
}

export function RsvpGuestBookResponseDataToJSON(
  value?: RsvpGuestBookResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    detail: value.detail,
    guest_event_id: value.guest_event_id,
    id: value.id,
    is_checked_in: value.is_checked_in,
    is_received_gift: value.is_received_gift,
    is_received_souvenir: value.is_received_souvenir,
    name: value.name,
    phone_number: value.phone_number,
    qr_code: value.qr_code,
    table_number: value.table_number,
    total_guests: value.total_guests,
    total_guests_attended: value.total_guests_attended,
    total_guests_confirmed: value.total_guests_confirmed,
  };
}
