/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const RsvpConfirmationStatus = {
  CONFIRMED: "CONFIRMED",
  DECLINED: "DECLINED",
} as const;
export type RsvpConfirmationStatus =
  (typeof RsvpConfirmationStatus)[keyof typeof RsvpConfirmationStatus];

export function RsvpConfirmationStatusFromJSON(
  json: any
): RsvpConfirmationStatus {
  return RsvpConfirmationStatusFromJSONTyped(json, false);
}

export function RsvpConfirmationStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpConfirmationStatus {
  return json as RsvpConfirmationStatus;
}

export function RsvpConfirmationStatusToJSON(
  value?: RsvpConfirmationStatus | null
): any {
  return value as any;
}
