/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { WishDataResponse } from "./WishDataResponse";
import {
  WishDataResponseFromJSON,
  WishDataResponseFromJSONTyped,
  WishDataResponseToJSON,
} from "./WishDataResponse";

/**
 *
 * @export
 * @interface DtoBookingResponseDataDto
 */
export interface DtoBookingResponseDataDto {
  /**
   *
   * @type {WishDataResponse}
   * @memberof DtoBookingResponseDataDto
   */
  wish?: WishDataResponse;
}

/**
 * Check if a given object implements the DtoBookingResponseDataDto interface.
 */
export function instanceOfDtoBookingResponseDataDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DtoBookingResponseDataDtoFromJSON(
  json: any
): DtoBookingResponseDataDto {
  return DtoBookingResponseDataDtoFromJSONTyped(json, false);
}

export function DtoBookingResponseDataDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DtoBookingResponseDataDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    wish: !exists(json, "wish")
      ? undefined
      : WishDataResponseFromJSON(json["wish"]),
  };
}

export function DtoBookingResponseDataDtoToJSON(
  value?: DtoBookingResponseDataDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    wish: WishDataResponseToJSON(value.wish),
  };
}
