import useImageUploader from "@hooks/ImageUploader";
import { CustomSectionHeaders } from "@pages/Clients/ClientWebDetail/styles";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Asterisk, ImageWrapper } from "@shared/styles/FormStyles";
import type { Client } from "@/services/openapi/v1";

const Groom = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<Client>();

  const [photoUrl, setPhotoUrl] = useState<string>(
    getValues("content.groom.photo_url")
  );

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_GROOM",
      old_file_url: photoUrl,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue("content.groom.photo_url", uploadResponse);
      setPhotoUrl(uploadResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form.Group>
      <Form.Label>
        <CustomSectionHeaders>Groom</CustomSectionHeaders>
      </Form.Label>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>
            Name <Asterisk>*</Asterisk>
          </Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.name")}
            isInvalid={!!errors.content?.groom?.name?.message}
          />
          {!!errors.content?.groom?.name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>
            Full name <Asterisk>*</Asterisk>
          </Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.full_name")}
            isInvalid={!!errors.content?.groom?.full_name?.message}
          />
          {!!errors.content?.groom?.full_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.full_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Prefix name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.prefix_name")}
            isInvalid={!!errors.content?.groom?.prefix_name?.message}
          />
          {!!errors.content?.groom?.prefix_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.prefix_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Suffix name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.suffix_name")}
            isInvalid={!!errors.content?.groom?.suffix_name?.message}
          />
          {!!errors.content?.groom?.suffix_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.suffix_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Child Order</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.child_order")}
            isInvalid={!!errors.content?.groom?.child_order?.message}
          />
          {!!errors.content?.groom?.child_order?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.child_order?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Son Order</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.son_order")}
            isInvalid={!!errors.content?.groom?.son_order?.message}
          />
          {!!errors.content?.groom?.son_order?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.son_order?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Father name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.father_name")}
            isInvalid={!!errors.content?.groom?.father_name?.message}
          />
          {!!errors.content?.groom?.father_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.father_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Mother name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.mother_name")}
            isInvalid={!!errors.content?.groom?.mother_name?.message}
          />
          {!!errors.content?.groom?.mother_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.mother_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-3">
          <Form.Label>Facebook</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.facebook")}
            isInvalid={!!errors.content?.groom?.facebook?.message}
          />
          {!!errors.content?.groom?.facebook?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.facebook?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={4} className="mb-3">
          <Form.Label>Instagram</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.instagram")}
            isInvalid={!!errors.content?.groom?.instagram?.message}
          />
          {!!errors.content?.groom?.instagram?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.instagram?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={4} className="mb-3">
          <Form.Label>Twitter</Form.Label>
          <Form.Control
            type="input"
            {...register("content.groom.twitter")}
            isInvalid={!!errors.content?.groom?.twitter?.message}
          />
          {!!errors.content?.groom?.twitter?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.groom?.twitter?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Row>
            {photoUrl && (
              <ImageWrapper className="mb-3 mb-lg-0">
                <img src={photoUrl} alt="grooms's photo" />
              </ImageWrapper>
            )}
            <Form.Label>Photo URL</Form.Label>
            <Col md={9}>
              <Form.Control
                type="input"
                {...register("content.groom.photo_url")}
                isInvalid={!!errors.content?.groom?.photo_url?.message}
                disabled
              />
              {!!errors.content?.groom?.photo_url?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.content?.groom?.photo_url?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col md={3} className="mt-3 text-end mt-lg-0">
              <Button
                type="button"
                onClick={handleUpdatePhoto}
                disabled={!getValues("content.groom.name")}
              >
                Update photo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ImageUploadModal />
    </Form.Group>
  );
};

export default Groom;
