import type { ClientsList200Response } from "../services/openapi/v1/api";
import type { AxiosError, AxiosResponse } from "axios";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { ApiInstance } from "@services/api";
import { REACT_QUERY_CACHE_KEYS } from "@/shared/constants/CacheKeys";

const fetchAPI = (totalData: number, page: number) =>
  ApiInstance().clientApi().clientsList(totalData, page);

export const useGetClientListData = (
  totalData: number,
  page: number,
  options?: UseQueryOptions<
    AxiosResponse<ClientsList200Response>,
    AxiosError,
    AxiosResponse<ClientsList200Response>,
    never
  >
) => {
  return useQuery(
    [REACT_QUERY_CACHE_KEYS.getClient, totalData, page],
    () => fetchAPI(totalData, page),
    {
      retry: (failureCount, error) => {
        return (
          error.response?.status !== 401 &&
          error.response?.status !== 403 &&
          failureCount < 3
        );
      },
      ...options,
    }
  );
};
