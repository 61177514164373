/* eslint-disable @typescript-eslint/ban-types */
import { ApiInstance } from "@/services/api";
import type { ClientOption, Option } from "@/services/openapi/v1";
import type { ClientParams } from "@/shared/DataType";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
import { clientOptionSchema } from "@/shared/constants/yupSchema";
import { mappingCustomText } from "@/shared/utils/FormUtils/formUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  revalidateSlug,
  renderPromiseToast,
} from "@/shared/utils/FormUtils/formUtils";

interface Return {
  data: {
    error: {};
    forms: UseFormReturn<ClientOption, object>;
    isDisabled: boolean;
  };
  method: {
    onSubmit(data: ClientOption): Promise<void>;
  };
}

export default function useViewModel(): Return {
  const history = useHistory();
  const { id } = useParams<ClientParams>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [error, setError] = useState({});

  // Table Data
  const forms = useForm<ClientOption>({
    resolver: yupResolver(clientOptionSchema),
  });

  const onSubmit = useCallback(async (data: ClientOption) => {
    setIsDisabled(true);
    const { option } = data;
    const customText = option.custom_text;
    const newCustomText = mappingCustomText(customText);
    const newOption: Option = { ...option, custom_text: newCustomText };
    const newData = { ...data, option: newOption };

    try {
      // create web detail
      const createWebDetail = ApiInstance()
        .clientApi()
        .optionsCreate(id, newData);
      await renderPromiseToast(
        createWebDetail,
        "Creating web detail",
        "Success create web detail!",
        "Error creating web detail"
      );

      //revalidate slugs
      const options = await ApiInstance().clientApi().clientsGetOptions(id);
      await renderPromiseToast(
        revalidateSlug(options.data),
        "Revalidating slugs",
        "Success revalidate slugs!",
        "Error revalidating slugs"
      );
      setIsDisabled(false);
      history.push(RoutePath.CLIENT_DETAIL(id));
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
      const { response } = error;
      setError(response.data.error);
    }
  }, []);

  return {
    data: { error, forms, isDisabled },
    method: { onSubmit },
  };
}
