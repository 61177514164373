/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GuestResponse
 */
export interface GuestResponse {
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof GuestResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof GuestResponse
   */
  option_id?: number;
  /**
   *
   * @type {string}
   * @memberof GuestResponse
   */
  phone_number?: string;
  /**
   *
   * @type {number}
   * @memberof GuestResponse
   */
  total_guests?: number;
}

/**
 * Check if a given object implements the GuestResponse interface.
 */
export function instanceOfGuestResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GuestResponseFromJSON(json: any): GuestResponse {
  return GuestResponseFromJSONTyped(json, false);
}

export function GuestResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GuestResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, "code") ? undefined : json["code"],
    created_at: !exists(json, "created_at") ? undefined : json["created_at"],
    details: !exists(json, "details") ? undefined : json["details"],
    email: !exists(json, "email") ? undefined : json["email"],
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    option_id: !exists(json, "option_id") ? undefined : json["option_id"],
    phone_number: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
    total_guests: !exists(json, "total_guests")
      ? undefined
      : json["total_guests"],
  };
}

export function GuestResponseToJSON(value?: GuestResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    created_at: value.created_at,
    details: value.details,
    email: value.email,
    id: value.id,
    name: value.name,
    option_id: value.option_id,
    phone_number: value.phone_number,
    total_guests: value.total_guests,
  };
}
