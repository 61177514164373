/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface HeaderMetaFormatter
 */
export interface HeaderMetaFormatter {
  /**
   *
   * @type {object}
   * @memberof HeaderMetaFormatter
   */
  key?: object;
  /**
   *
   * @type {string}
   * @memberof HeaderMetaFormatter
   */
  value?: string;
}

/**
 * Check if a given object implements the HeaderMetaFormatter interface.
 */
export function instanceOfHeaderMetaFormatter(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function HeaderMetaFormatterFromJSON(json: any): HeaderMetaFormatter {
  return HeaderMetaFormatterFromJSONTyped(json, false);
}

export function HeaderMetaFormatterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HeaderMetaFormatter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: !exists(json, "key") ? undefined : json["key"],
    value: !exists(json, "value") ? undefined : json["value"],
  };
}

export function HeaderMetaFormatterToJSON(
  value?: HeaderMetaFormatter | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    value: value.value,
  };
}
