/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateRsvpGuestRequest
 */
export interface UpdateRsvpGuestRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateRsvpGuestRequest
   */
  details?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateRsvpGuestRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateRsvpGuestRequest
   */
  total_guests?: number;
}

/**
 * Check if a given object implements the UpdateRsvpGuestRequest interface.
 */
export function instanceOfUpdateRsvpGuestRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateRsvpGuestRequestFromJSON(
  json: any
): UpdateRsvpGuestRequest {
  return UpdateRsvpGuestRequestFromJSONTyped(json, false);
}

export function UpdateRsvpGuestRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateRsvpGuestRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    details: !exists(json, "details") ? undefined : json["details"],
    name: !exists(json, "name") ? undefined : json["name"],
    total_guests: !exists(json, "total_guests")
      ? undefined
      : json["total_guests"],
  };
}

export function UpdateRsvpGuestRequestToJSON(
  value?: UpdateRsvpGuestRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    details: value.details,
    name: value.name,
    total_guests: value.total_guests,
  };
}
