/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EventType = {
  HOLY_MATRIMONY: "HOLY_MATRIMONY",
  WEDDING_RECEPTION: "WEDDING_RECEPTION",
  SANGJIT: "SANGJIT",
  TEAPAI: "TEAPAI",
  BIRTHDAY_EVENT: "BIRTHDAY",
  COCKTAIL: "COCKTAIL",
} as const;
export type EventType = (typeof EventType)[keyof typeof EventType];

export function EventTypeFromJSON(json: any): EventType {
  return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EventType {
  return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
  return value as any;
}
