/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const OpeningModalImageType = {
  PHOTO: "PHOTO",
  LOGO: "LOGO",
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
} as const;
export type OpeningModalImageType =
  (typeof OpeningModalImageType)[keyof typeof OpeningModalImageType];

export function OpeningModalImageTypeFromJSON(
  json: any
): OpeningModalImageType {
  return OpeningModalImageTypeFromJSONTyped(json, false);
}

export function OpeningModalImageTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpeningModalImageType {
  return json as OpeningModalImageType;
}

export function OpeningModalImageTypeToJSON(
  value?: OpeningModalImageType | null
): any {
  return value as any;
}
