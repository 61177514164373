import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDefaultDateTime } from "@shared/utils/DateUtils";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Asterisk } from "@shared/styles/FormStyles";
import { EventTypeEnum } from "@/services/openapi/v1";

// Types
import type { FieldError } from "react-hook-form";
import type { Client, Event } from "@/services/openapi/v1";

const Events = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<Client>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "content.events",
    keyName: "idForm",
  });

  return (
    <>
      <section className="mb-2">
        <Button
          variant="primary"
          onClick={() => {
            append({});
          }}
        >
          Add Event
        </Button>
      </section>
      <Accordion alwaysOpen>
        {(fields as unknown as (Event & { idForm: string })[]).map(
          (item, index) => {
            const error =
              errors.content?.events && errors.content?.events[`${index}`];
            return (
              <Accordion.Item eventKey={index.toString()} key={item.idForm}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Accordion.Header
                    style={{ flex: "1" }}
                    className={error ? "bg-danger" : ""}
                  >{`Event ${index + 1}`}</Accordion.Header>
                  <Button
                    variant="danger"
                    onClick={() => remove(index)}
                    style={{ margin: "10px" }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
                <Accordion.Body>
                  <Row>
                    <Col className="mb-3" md={6}>
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.title`)}
                          defaultValue={item.title}
                          isInvalid={!!error?.title?.message}
                        />
                        {!!error?.title?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.title?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col className="mb-3" md={6}>
                      <Form.Group>
                        <Form.Label>
                          Type <Asterisk>*</Asterisk>
                        </Form.Label>
                        <Controller
                          control={control}
                          name={`content.events.${index}.type`}
                          render={({ field }) => {
                            return (
                              <Form.Group>
                                <Form.Select
                                  defaultValue={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  isInvalid={
                                    !!(error?.type as FieldError)?.message
                                  }
                                >
                                  {Object.values(EventTypeEnum).map(
                                    (item, index) => {
                                      const formattedItem = item
                                        .replace(/_/g, " ")
                                        .toLowerCase();
                                      return (
                                        <option value={item} key={index}>
                                          {formattedItem}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                                {!!(error?.type as FieldError)?.message && (
                                  <Form.Control.Feedback type="invalid">
                                    {(error?.type as FieldError)?.message}
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Group>
                        <Form.Label>Custom Text Time</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          {...register(
                            `content.events.${index}.custom_time_text`
                          )}
                          defaultValue={item.watch_url}
                          isInvalid={!!error?.custom_time_text?.message}
                        />
                        {!!error?.custom_time_text?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.custom_time_text?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>
                          Start date time <Asterisk>*</Asterisk>
                        </Form.Label>
                        <Controller
                          control={control}
                          name={`content.events.${index}.start_datetime`}
                          render={({ field }) => (
                            <Form.Group>
                              <Form.Control
                                defaultValue={formatDefaultDateTime(
                                  field.value
                                )}
                                onChange={(e) => field.onChange(e.target.value)}
                                type="datetime-local"
                                isInvalid={!!error?.start_datetime?.message}
                              />
                              {!!error?.start_datetime?.message && (
                                <Form.Control.Feedback type="invalid">
                                  {error?.start_datetime?.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>End date time</Form.Label>
                        <Controller
                          control={control}
                          name={`content.events.${index}.end_datetime`}
                          render={({ field }) => (
                            <Form.Group>
                              <Form.Control
                                defaultValue={formatDefaultDateTime(
                                  field.value
                                )}
                                isInvalid={!!error?.end_datetime?.message}
                                onChange={(e) => field.onChange(e.target.value)}
                                type="datetime-local"
                              />
                              {!!error?.end_datetime?.message && (
                                <Form.Control.Feedback type="invalid">
                                  {error?.end_datetime?.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          {...register(`content.events.${index}.location`)}
                          defaultValue={item.location}
                          isInvalid={!!error?.location?.message}
                        />
                        {!!error?.location?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.location?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          {...register(`content.events.${index}.street`)}
                          defaultValue={item.street}
                          isInvalid={!!error?.street?.message}
                        />
                        {!!error?.street?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.street?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Maps url</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.maps_url`)}
                          defaultValue={item.maps_url}
                          isInvalid={!!error?.maps_url?.message}
                        />
                        {!!error?.maps_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.maps_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Watch url</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.watch_url`)}
                          defaultValue={item.watch_url}
                          isInvalid={!!error?.watch_url?.message}
                        />
                        {!!error?.watch_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.watch_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Session Type</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.session_type`)}
                          defaultValue={item.session_type}
                          isInvalid={!!error?.session_type?.message}
                        />
                        {!!error?.session_type?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.session_type?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Time Zone</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.timezone`)}
                          defaultValue={item.timezone}
                          isInvalid={!!error?.timezone?.message}
                        />
                        {!!error?.timezone?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.timezone?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Icon URL</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.events.${index}.icon_url`)}
                          defaultValue={item.icon_url}
                          isInvalid={!!error?.icon_url?.message}
                        />
                        {!!error?.icon_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.icon_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            );
          }
        )}
      </Accordion>
    </>
  );
};

export default Events;
