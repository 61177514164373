/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  BaseResponse,
  BookingRequest,
  BookingResponse,
  ErrorResponse,
  ExportRsvpGuestBookRequest,
  ExportRsvpGuestListRequest,
  RsvpGuestBookRequest,
  RsvpGuestBookResponse,
  RsvpGuestBookSummaryResponse,
  RsvpGuestListRequest,
  RsvpGuestListResponse,
  RsvpSummaryResponse,
  SubmitBulkUpdateTableNumberResponse,
  UpdateGuestEventTableNumberRequest,
  UpdateGuestEventTableNumberResponse,
  UpdateRsvpGuestRequest,
  UploadGuestRSVPFileResponse,
} from "../models/index";
import {
  BaseResponseFromJSON,
  BaseResponseToJSON,
  BookingRequestFromJSON,
  BookingRequestToJSON,
  BookingResponseFromJSON,
  BookingResponseToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ExportRsvpGuestBookRequestFromJSON,
  ExportRsvpGuestBookRequestToJSON,
  ExportRsvpGuestListRequestFromJSON,
  ExportRsvpGuestListRequestToJSON,
  RsvpGuestBookRequestFromJSON,
  RsvpGuestBookRequestToJSON,
  RsvpGuestBookResponseFromJSON,
  RsvpGuestBookResponseToJSON,
  RsvpGuestBookSummaryResponseFromJSON,
  RsvpGuestBookSummaryResponseToJSON,
  RsvpGuestListRequestFromJSON,
  RsvpGuestListRequestToJSON,
  RsvpGuestListResponseFromJSON,
  RsvpGuestListResponseToJSON,
  RsvpSummaryResponseFromJSON,
  RsvpSummaryResponseToJSON,
  SubmitBulkUpdateTableNumberResponseFromJSON,
  SubmitBulkUpdateTableNumberResponseToJSON,
  UpdateGuestEventTableNumberRequestFromJSON,
  UpdateGuestEventTableNumberRequestToJSON,
  UpdateGuestEventTableNumberResponseFromJSON,
  UpdateGuestEventTableNumberResponseToJSON,
  UpdateRsvpGuestRequestFromJSON,
  UpdateRsvpGuestRequestToJSON,
  UploadGuestRSVPFileResponseFromJSON,
  UploadGuestRSVPFileResponseToJSON,
} from "../models/index";

export interface DashboardClientsRsvpDeleteGuestGuestCodeDeleteRequest {
  guestCode: string;
}

export interface DashboardClientsRsvpExportGuestBookPostRequest {
  requestBody: ExportRsvpGuestBookRequest;
}

export interface DashboardClientsRsvpExportGuestListPostRequest {
  requestBody: ExportRsvpGuestListRequest;
}

export interface DashboardClientsRsvpExportRsvpGuestbookSummaryGetRequest {
  eventId: number;
}

export interface DashboardClientsRsvpExportRsvpSummaryGetRequest {
  eventId: number;
  slug: string;
}

export interface DashboardClientsRsvpFileGeneratedGuestListSlugGetRequest {
  slug: string;
}

export interface DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGetRequest {
  eventId: number;
}

export interface DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPutRequest {
  eventId: number;
  bulkUpdateTableNumber: Blob;
}

export interface DashboardClientsRsvpGuestBookPostRequest {
  requestBody: RsvpGuestBookRequest;
}

export interface DashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatchRequest {
  guestEventId: number;
  requestBody: UpdateGuestEventTableNumberRequest;
}

export interface DashboardClientsRsvpGuestListPostRequest {
  requestBody: RsvpGuestListRequest;
}

export interface DashboardClientsRsvpSummaryGetRequest {
  eventId: number;
  slug: string;
}

export interface DashboardClientsRsvpSummaryGuestBookEventIdGetRequest {
  eventId: number;
}

export interface DashboardClientsRsvpUpdateGuestPhoneNumberPutRequest {
  phoneNumber: string;
  requestBody: UpdateRsvpGuestRequest;
}

export interface DashboardClientsRsvpUploadRsvpFilePostRequest {
  rsvpGuestFile: Blob;
}

export interface PublicGuestsRsvpClientIdPostRequest {
  clientId: number;
  guestCode: string;
  slug: string;
  requestBody: BookingRequest;
}

/**
 *
 */
export class RsvpApi extends runtime.BaseAPI {
  /**
   * Delete Guest By Id
   * Delete Guest By Id
   */
  async dashboardClientsRsvpDeleteGuestGuestCodeDeleteRaw(
    requestParameters: DashboardClientsRsvpDeleteGuestGuestCodeDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BaseResponse>> {
    if (
      requestParameters.guestCode === null ||
      requestParameters.guestCode === undefined
    ) {
      throw new runtime.RequiredError(
        "guestCode",
        "Required parameter requestParameters.guestCode was null or undefined when calling dashboardClientsRsvpDeleteGuestGuestCodeDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/delete-guest/{guestCode}`.replace(
          `{${"guestCode"}}`,
          encodeURIComponent(String(requestParameters.guestCode))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BaseResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete Guest By Id
   * Delete Guest By Id
   */
  async dashboardClientsRsvpDeleteGuestGuestCodeDelete(
    requestParameters: DashboardClientsRsvpDeleteGuestGuestCodeDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BaseResponse> {
    const response =
      await this.dashboardClientsRsvpDeleteGuestGuestCodeDeleteRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Export RSVP Guest Book Data Into SpreadSheet File
   * Export RSVP Guest Book Data Into SpreadSheet File
   */
  async dashboardClientsRsvpExportGuestBookPostRaw(
    requestParameters: DashboardClientsRsvpExportGuestBookPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpExportGuestBookPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/export/guest-book`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ExportRsvpGuestBookRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Export RSVP Guest Book Data Into SpreadSheet File
   * Export RSVP Guest Book Data Into SpreadSheet File
   */
  async dashboardClientsRsvpExportGuestBookPost(
    requestParameters: DashboardClientsRsvpExportGuestBookPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardClientsRsvpExportGuestBookPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Export RSVP Guest List Data Into SpreadSheet File
   * Export RSVP Guest List Data Into SpreadSheet File
   */
  async dashboardClientsRsvpExportGuestListPostRaw(
    requestParameters: DashboardClientsRsvpExportGuestListPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpExportGuestListPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/export/guest-list`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ExportRsvpGuestListRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Export RSVP Guest List Data Into SpreadSheet File
   * Export RSVP Guest List Data Into SpreadSheet File
   */
  async dashboardClientsRsvpExportGuestListPost(
    requestParameters: DashboardClientsRsvpExportGuestListPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardClientsRsvpExportGuestListPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Export RSVP Guest Book Summary Into PDF File
   * Export RSVP Guest Book Summary Into PDF File
   */
  async dashboardClientsRsvpExportRsvpGuestbookSummaryGetRaw(
    requestParameters: DashboardClientsRsvpExportRsvpGuestbookSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpExportRsvpGuestbookSummaryGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.eventId !== undefined) {
      queryParameters["eventId"] = requestParameters.eventId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/export/rsvp-guestbook-summary`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Export RSVP Guest Book Summary Into PDF File
   * Export RSVP Guest Book Summary Into PDF File
   */
  async dashboardClientsRsvpExportRsvpGuestbookSummaryGet(
    requestParameters: DashboardClientsRsvpExportRsvpGuestbookSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response =
      await this.dashboardClientsRsvpExportRsvpGuestbookSummaryGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Export RSVP Summary Into PDF File
   * Export RSVP Summary Into PDF File
   */
  async dashboardClientsRsvpExportRsvpSummaryGetRaw(
    requestParameters: DashboardClientsRsvpExportRsvpSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpExportRsvpSummaryGet."
      );
    }

    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling dashboardClientsRsvpExportRsvpSummaryGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.eventId !== undefined) {
      queryParameters["eventId"] = requestParameters.eventId;
    }

    if (requestParameters.slug !== undefined) {
      queryParameters["slug"] = requestParameters.slug;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/export/rsvp-summary`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Export RSVP Summary Into PDF File
   * Export RSVP Summary Into PDF File
   */
  async dashboardClientsRsvpExportRsvpSummaryGet(
    requestParameters: DashboardClientsRsvpExportRsvpSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardClientsRsvpExportRsvpSummaryGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Rsvp Generated Guest List File By Slug
   * Get Rsvp Generated Guest List File By Slug
   */
  async dashboardClientsRsvpFileGeneratedGuestListSlugGetRaw(
    requestParameters: DashboardClientsRsvpFileGeneratedGuestListSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling dashboardClientsRsvpFileGeneratedGuestListSlugGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/file/generated-guest-list/{slug}`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Get Rsvp Generated Guest List File By Slug
   * Get Rsvp Generated Guest List File By Slug
   */
  async dashboardClientsRsvpFileGeneratedGuestListSlugGet(
    requestParameters: DashboardClientsRsvpFileGeneratedGuestListSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response =
      await this.dashboardClientsRsvpFileGeneratedGuestListSlugGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Get Bulk Update Table Number SpreadSheet
   * Get Bulk Update Table Number SpreadSheet
   */
  async dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGetRaw(
    requestParameters: DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/guest-book/bulk/update-table-number/{eventId}`.replace(
          `{${"eventId"}}`,
          encodeURIComponent(String(requestParameters.eventId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Get Bulk Update Table Number SpreadSheet
   * Get Bulk Update Table Number SpreadSheet
   */
  async dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGet(
    requestParameters: DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response =
      await this.dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Submit Bulk Update Table Number File
   * Submit Bulk Update Table Number File
   */
  async dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPutRaw(
    requestParameters: DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SubmitBulkUpdateTableNumberResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPut."
      );
    }

    if (
      requestParameters.bulkUpdateTableNumber === null ||
      requestParameters.bulkUpdateTableNumber === undefined
    ) {
      throw new runtime.RequiredError(
        "bulkUpdateTableNumber",
        "Required parameter requestParameters.bulkUpdateTableNumber was null or undefined when calling dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.bulkUpdateTableNumber !== undefined) {
      formParams.append(
        "bulk-update-table-number",
        requestParameters.bulkUpdateTableNumber as any
      );
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/guest-book/bulk/update-table-number/{eventId}`.replace(
          `{${"eventId"}}`,
          encodeURIComponent(String(requestParameters.eventId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitBulkUpdateTableNumberResponseFromJSON(jsonValue)
    );
  }

  /**
   * Submit Bulk Update Table Number File
   * Submit Bulk Update Table Number File
   */
  async dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPut(
    requestParameters: DashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitBulkUpdateTableNumberResponse> {
    const response =
      await this.dashboardClientsRsvpGuestBookBulkUpdateTableNumberEventIdPutRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Get Rsvp Guest Book Data
   * Get Rsvp Guest Book Data
   */
  async dashboardClientsRsvpGuestBookPostRaw(
    requestParameters: DashboardClientsRsvpGuestBookPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RsvpGuestBookResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpGuestBookPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/guest-book`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RsvpGuestBookRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RsvpGuestBookResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Rsvp Guest Book Data
   * Get Rsvp Guest Book Data
   */
  async dashboardClientsRsvpGuestBookPost(
    requestParameters: DashboardClientsRsvpGuestBookPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RsvpGuestBookResponse> {
    const response = await this.dashboardClientsRsvpGuestBookPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Single Table Number
   * Update Single Table Number
   */
  async dashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatchRaw(
    requestParameters: DashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateGuestEventTableNumberResponse>> {
    if (
      requestParameters.guestEventId === null ||
      requestParameters.guestEventId === undefined
    ) {
      throw new runtime.RequiredError(
        "guestEventId",
        "Required parameter requestParameters.guestEventId was null or undefined when calling dashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatch."
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatch."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/guest-book/update-table-number/{guestEventId}`.replace(
          `{${"guestEventId"}}`,
          encodeURIComponent(String(requestParameters.guestEventId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateGuestEventTableNumberRequestToJSON(
          requestParameters.requestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateGuestEventTableNumberResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Single Table Number
   * Update Single Table Number
   */
  async dashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatch(
    requestParameters: DashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateGuestEventTableNumberResponse> {
    const response =
      await this.dashboardClientsRsvpGuestBookUpdateTableNumberGuestEventIdPatchRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Get RSVP Guest List Data By Slugs
   * Get RSVP Guest List Data By Slugs
   */
  async dashboardClientsRsvpGuestListPostRaw(
    requestParameters: DashboardClientsRsvpGuestListPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RsvpGuestListResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpGuestListPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/guest-list`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RsvpGuestListRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RsvpGuestListResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get RSVP Guest List Data By Slugs
   * Get RSVP Guest List Data By Slugs
   */
  async dashboardClientsRsvpGuestListPost(
    requestParameters: DashboardClientsRsvpGuestListPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RsvpGuestListResponse> {
    const response = await this.dashboardClientsRsvpGuestListPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get RSVP Guest Book Summary By Event Id
   * Get RSVP Guest Book Summary By Event Id
   */
  async dashboardClientsRsvpSummaryGetRaw(
    requestParameters: DashboardClientsRsvpSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RsvpSummaryResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpSummaryGet."
      );
    }

    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling dashboardClientsRsvpSummaryGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.eventId !== undefined) {
      queryParameters["eventId"] = requestParameters.eventId;
    }

    if (requestParameters.slug !== undefined) {
      queryParameters["slug"] = requestParameters.slug;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/summary`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RsvpSummaryResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get RSVP Guest Book Summary By Event Id
   * Get RSVP Guest Book Summary By Event Id
   */
  async dashboardClientsRsvpSummaryGet(
    requestParameters: DashboardClientsRsvpSummaryGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RsvpSummaryResponse> {
    const response = await this.dashboardClientsRsvpSummaryGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get RSVP Guest Book Summary By Event Id
   * Get RSVP Guest Book Summary By Event Id
   */
  async dashboardClientsRsvpSummaryGuestBookEventIdGetRaw(
    requestParameters: DashboardClientsRsvpSummaryGuestBookEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RsvpGuestBookSummaryResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardClientsRsvpSummaryGuestBookEventIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/summary/guest-book/{eventId}`.replace(
          `{${"eventId"}}`,
          encodeURIComponent(String(requestParameters.eventId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RsvpGuestBookSummaryResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get RSVP Guest Book Summary By Event Id
   * Get RSVP Guest Book Summary By Event Id
   */
  async dashboardClientsRsvpSummaryGuestBookEventIdGet(
    requestParameters: DashboardClientsRsvpSummaryGuestBookEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RsvpGuestBookSummaryResponse> {
    const response =
      await this.dashboardClientsRsvpSummaryGuestBookEventIdGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Update Rsvp Guest
   * Update Rsvp Guest
   */
  async dashboardClientsRsvpUpdateGuestPhoneNumberPutRaw(
    requestParameters: DashboardClientsRsvpUpdateGuestPhoneNumberPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BaseResponse>> {
    if (
      requestParameters.phoneNumber === null ||
      requestParameters.phoneNumber === undefined
    ) {
      throw new runtime.RequiredError(
        "phoneNumber",
        "Required parameter requestParameters.phoneNumber was null or undefined when calling dashboardClientsRsvpUpdateGuestPhoneNumberPut."
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardClientsRsvpUpdateGuestPhoneNumberPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/update-guest/{phoneNumber}`.replace(
          `{${"phoneNumber"}}`,
          encodeURIComponent(String(requestParameters.phoneNumber))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateRsvpGuestRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BaseResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Rsvp Guest
   * Update Rsvp Guest
   */
  async dashboardClientsRsvpUpdateGuestPhoneNumberPut(
    requestParameters: DashboardClientsRsvpUpdateGuestPhoneNumberPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BaseResponse> {
    const response =
      await this.dashboardClientsRsvpUpdateGuestPhoneNumberPutRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Upload Guest RSVP File
   * Upload Guest RSVP File
   */
  async dashboardClientsRsvpUploadRsvpFilePostRaw(
    requestParameters: DashboardClientsRsvpUploadRsvpFilePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UploadGuestRSVPFileResponse>> {
    if (
      requestParameters.rsvpGuestFile === null ||
      requestParameters.rsvpGuestFile === undefined
    ) {
      throw new runtime.RequiredError(
        "rsvpGuestFile",
        "Required parameter requestParameters.rsvpGuestFile was null or undefined when calling dashboardClientsRsvpUploadRsvpFilePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.rsvpGuestFile !== undefined) {
      formParams.append(
        "rsvp-guest-file",
        requestParameters.rsvpGuestFile as any
      );
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/rsvp/upload-rsvp-file`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UploadGuestRSVPFileResponseFromJSON(jsonValue)
    );
  }

  /**
   * Upload Guest RSVP File
   * Upload Guest RSVP File
   */
  async dashboardClientsRsvpUploadRsvpFilePost(
    requestParameters: DashboardClientsRsvpUploadRsvpFilePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UploadGuestRSVPFileResponse> {
    const response = await this.dashboardClientsRsvpUploadRsvpFilePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Insert Booking and Wishes By ClientId
   * Booking Insert Book
   */
  async publicGuestsRsvpClientIdPostRaw(
    requestParameters: PublicGuestsRsvpClientIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BookingResponse>> {
    if (
      requestParameters.clientId === null ||
      requestParameters.clientId === undefined
    ) {
      throw new runtime.RequiredError(
        "clientId",
        "Required parameter requestParameters.clientId was null or undefined when calling publicGuestsRsvpClientIdPost."
      );
    }

    if (
      requestParameters.guestCode === null ||
      requestParameters.guestCode === undefined
    ) {
      throw new runtime.RequiredError(
        "guestCode",
        "Required parameter requestParameters.guestCode was null or undefined when calling publicGuestsRsvpClientIdPost."
      );
    }

    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling publicGuestsRsvpClientIdPost."
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicGuestsRsvpClientIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (
      requestParameters.guestCode !== undefined &&
      requestParameters.guestCode !== null
    ) {
      headerParameters["Guest-Code"] = String(requestParameters.guestCode);
    }

    if (
      requestParameters.slug !== undefined &&
      requestParameters.slug !== null
    ) {
      headerParameters["Slug"] = String(requestParameters.slug);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // GuestBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/{clientId}`.replace(
          `{${"clientId"}}`,
          encodeURIComponent(String(requestParameters.clientId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BookingRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BookingResponseFromJSON(jsonValue)
    );
  }

  /**
   * Insert Booking and Wishes By ClientId
   * Booking Insert Book
   */
  async publicGuestsRsvpClientIdPost(
    requestParameters: PublicGuestsRsvpClientIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BookingResponse> {
    const response = await this.publicGuestsRsvpClientIdPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
