/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { TemplateFileName } from "./TemplateFileName";
import {
  TemplateFileNameFromJSON,
  TemplateFileNameFromJSONTyped,
  TemplateFileNameToJSON,
} from "./TemplateFileName";

/**
 *
 * @export
 * @interface LinkRequest
 */
export interface LinkRequest {
  /**
   *
   * @type {TemplateFileName}
   * @memberof LinkRequest
   */
  file_name: TemplateFileName;
}

/**
 * Check if a given object implements the LinkRequest interface.
 */
export function instanceOfLinkRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "file_name" in value;

  return isInstance;
}

export function LinkRequestFromJSON(json: any): LinkRequest {
  return LinkRequestFromJSONTyped(json, false);
}

export function LinkRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LinkRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    file_name: TemplateFileNameFromJSON(json["file_name"]),
  };
}

export function LinkRequestToJSON(value?: LinkRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    file_name: TemplateFileNameToJSON(value.file_name),
  };
}
