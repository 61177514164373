/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ContentResponse } from "./ContentResponse";
import {
  ContentResponseFromJSON,
  ContentResponseFromJSONTyped,
  ContentResponseToJSON,
} from "./ContentResponse";
import type { GuestResponse } from "./GuestResponse";
import {
  GuestResponseFromJSON,
  GuestResponseFromJSONTyped,
  GuestResponseToJSON,
} from "./GuestResponse";
import type { OptionResponse } from "./OptionResponse";
import {
  OptionResponseFromJSON,
  OptionResponseFromJSONTyped,
  OptionResponseToJSON,
} from "./OptionResponse";
import type { RsvpVersion } from "./RsvpVersion";
import {
  RsvpVersionFromJSON,
  RsvpVersionFromJSONTyped,
  RsvpVersionToJSON,
} from "./RsvpVersion";

/**
 *
 * @export
 * @interface GetClientBySlugResponse
 */
export interface GetClientBySlugResponse {
  /**
   *
   * @type {ContentResponse}
   * @memberof GetClientBySlugResponse
   */
  content?: ContentResponse;
  /**
   *
   * @type {GuestResponse}
   * @memberof GetClientBySlugResponse
   */
  guest?: GuestResponse;
  /**
   *
   * @type {number}
   * @memberof GetClientBySlugResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GetClientBySlugResponse
   */
  name?: string;
  /**
   *
   * @type {OptionResponse}
   * @memberof GetClientBySlugResponse
   */
  option?: OptionResponse;
  /**
   *
   * @type {string}
   * @memberof GetClientBySlugResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof GetClientBySlugResponse
   */
  status_code?: number;
  /**
   *
   * @type {RsvpVersion}
   * @memberof GetClientBySlugResponse
   */
  version?: RsvpVersion;
}

/**
 * Check if a given object implements the GetClientBySlugResponse interface.
 */
export function instanceOfGetClientBySlugResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetClientBySlugResponseFromJSON(
  json: any
): GetClientBySlugResponse {
  return GetClientBySlugResponseFromJSONTyped(json, false);
}

export function GetClientBySlugResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetClientBySlugResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content: !exists(json, "content")
      ? undefined
      : ContentResponseFromJSON(json["content"]),
    guest: !exists(json, "guest")
      ? undefined
      : GuestResponseFromJSON(json["guest"]),
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    option: !exists(json, "option")
      ? undefined
      : OptionResponseFromJSON(json["option"]),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
    version: !exists(json, "version")
      ? undefined
      : RsvpVersionFromJSON(json["version"]),
  };
}

export function GetClientBySlugResponseToJSON(
  value?: GetClientBySlugResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: ContentResponseToJSON(value.content),
    guest: GuestResponseToJSON(value.guest),
    id: value.id,
    name: value.name,
    option: OptionResponseToJSON(value.option),
    status: value.status,
    status_code: value.status_code,
    version: RsvpVersionToJSON(value.version),
  };
}
