/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { DeleteWishResponse, WishResponse } from "../models/index";
import {
  DeleteWishResponseFromJSON,
  DeleteWishResponseToJSON,
  WishResponseFromJSON,
  WishResponseToJSON,
} from "../models/index";

export interface DashboardAdminWishesGetRequest {
  limit: number;
  page: number;
}

export interface DashboardAdminWishesIdDeleteRequest {
  id: number;
}

export interface PublicWishesClientIdGetRequest {
  clientId: number;
  limit: number;
  page: number;
}

/**
 *
 */
export class WishesApi extends runtime.BaseAPI {
  /**
   * Export Wishes Data Into SpreadSheet File
   * Export Wishes Data Into SpreadSheet File
   */
  async dashboardAdminWishesExportGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/wishes/export`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Export Wishes Data Into SpreadSheet File
   * Export Wishes Data Into SpreadSheet File
   */
  async dashboardAdminWishesExportGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.dashboardAdminWishesExportGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Fetch Wishes Data By Client Id
   * Fetch Wishes Data By Client Id
   */
  async dashboardAdminWishesGetRaw(
    requestParameters: DashboardAdminWishesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WishResponse>> {
    if (
      requestParameters.limit === null ||
      requestParameters.limit === undefined
    ) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling dashboardAdminWishesGet."
      );
    }

    if (
      requestParameters.page === null ||
      requestParameters.page === undefined
    ) {
      throw new runtime.RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling dashboardAdminWishesGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/wishes`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WishResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Wishes Data By Client Id
   * Fetch Wishes Data By Client Id
   */
  async dashboardAdminWishesGet(
    requestParameters: DashboardAdminWishesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WishResponse> {
    const response = await this.dashboardAdminWishesGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete wish Data By Id
   * Delete wish data By Id
   */
  async dashboardAdminWishesIdDeleteRaw(
    requestParameters: DashboardAdminWishesIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeleteWishResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling dashboardAdminWishesIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/wishes/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeleteWishResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete wish Data By Id
   * Delete wish data By Id
   */
  async dashboardAdminWishesIdDelete(
    requestParameters: DashboardAdminWishesIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeleteWishResponse> {
    const response = await this.dashboardAdminWishesIdDeleteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Fetch Wishes Data By Client Id
   * Fetch Wishes Data By Client Id
   */
  async publicWishesClientIdGetRaw(
    requestParameters: PublicWishesClientIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WishResponse>> {
    if (
      requestParameters.clientId === null ||
      requestParameters.clientId === undefined
    ) {
      throw new runtime.RequiredError(
        "clientId",
        "Required parameter requestParameters.clientId was null or undefined when calling publicWishesClientIdGet."
      );
    }

    if (
      requestParameters.limit === null ||
      requestParameters.limit === undefined
    ) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling publicWishesClientIdGet."
      );
    }

    if (
      requestParameters.page === null ||
      requestParameters.page === undefined
    ) {
      throw new runtime.RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling publicWishesClientIdGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/wishes/{clientId}`.replace(
          `{${"clientId"}}`,
          encodeURIComponent(String(requestParameters.clientId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WishResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Wishes Data By Client Id
   * Fetch Wishes Data By Client Id
   */
  async publicWishesClientIdGet(
    requestParameters: PublicWishesClientIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WishResponse> {
    const response = await this.publicWishesClientIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
