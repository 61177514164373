/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GuestLoginRequest
 */
export interface GuestLoginRequest {
  /**
   *
   * @type {string}
   * @memberof GuestLoginRequest
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof GuestLoginRequest
   */
  slug: string;
}

/**
 * Check if a given object implements the GuestLoginRequest interface.
 */
export function instanceOfGuestLoginRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "phoneNumber" in value;
  isInstance = isInstance && "slug" in value;

  return isInstance;
}

export function GuestLoginRequestFromJSON(json: any): GuestLoginRequest {
  return GuestLoginRequestFromJSONTyped(json, false);
}

export function GuestLoginRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GuestLoginRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phoneNumber: json["phoneNumber"],
    slug: json["slug"],
  };
}

export function GuestLoginRequestToJSON(value?: GuestLoginRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phoneNumber: value.phoneNumber,
    slug: value.slug,
  };
}
