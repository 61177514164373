/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitEventRsvpGuestBookConfirmationRequest
 */
export interface SubmitEventRsvpGuestBookConfirmationRequest {
  /**
   *
   * @type {number}
   * @memberof SubmitEventRsvpGuestBookConfirmationRequest
   */
  event_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof SubmitEventRsvpGuestBookConfirmationRequest
   */
  gift?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubmitEventRsvpGuestBookConfirmationRequest
   */
  guest_code?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubmitEventRsvpGuestBookConfirmationRequest
   */
  souvenir?: boolean;
  /**
   *
   * @type {number}
   * @memberof SubmitEventRsvpGuestBookConfirmationRequest
   */
  total_guest_attending?: number;
}

/**
 * Check if a given object implements the SubmitEventRsvpGuestBookConfirmationRequest interface.
 */
export function instanceOfSubmitEventRsvpGuestBookConfirmationRequest(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function SubmitEventRsvpGuestBookConfirmationRequestFromJSON(
  json: any
): SubmitEventRsvpGuestBookConfirmationRequest {
  return SubmitEventRsvpGuestBookConfirmationRequestFromJSONTyped(json, false);
}

export function SubmitEventRsvpGuestBookConfirmationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitEventRsvpGuestBookConfirmationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_id: !exists(json, "event_id") ? undefined : json["event_id"],
    gift: !exists(json, "gift") ? undefined : json["gift"],
    guest_code: !exists(json, "guest_code") ? undefined : json["guest_code"],
    souvenir: !exists(json, "souvenir") ? undefined : json["souvenir"],
    total_guest_attending: !exists(json, "total_guest_attending")
      ? undefined
      : json["total_guest_attending"],
  };
}

export function SubmitEventRsvpGuestBookConfirmationRequestToJSON(
  value?: SubmitEventRsvpGuestBookConfirmationRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_id: value.event_id,
    gift: value.gift,
    guest_code: value.guest_code,
    souvenir: value.souvenir,
    total_guest_attending: value.total_guest_attending,
  };
}
