/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  last_page?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  next_page?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  previous_page?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  total_data?: number;
}

/**
 * Check if a given object implements the PaginationMeta interface.
 */
export function instanceOfPaginationMeta(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PaginationMetaFromJSON(json: any): PaginationMeta {
  return PaginationMetaFromJSONTyped(json, false);
}

export function PaginationMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaginationMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    current_page: !exists(json, "current_page")
      ? undefined
      : json["current_page"],
    last_page: !exists(json, "last_page") ? undefined : json["last_page"],
    limit: !exists(json, "limit") ? undefined : json["limit"],
    next_page: !exists(json, "next_page") ? undefined : json["next_page"],
    previous_page: !exists(json, "previous_page")
      ? undefined
      : json["previous_page"],
    total_data: !exists(json, "total_data") ? undefined : json["total_data"],
  };
}

export function PaginationMetaToJSON(value?: PaginationMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_page: value.current_page,
    last_page: value.last_page,
    limit: value.limit,
    next_page: value.next_page,
    previous_page: value.previous_page,
    total_data: value.total_data,
  };
}
