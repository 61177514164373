/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { GuestBookEventDetailResponseData } from "./GuestBookEventDetailResponseData";
import {
  GuestBookEventDetailResponseDataFromJSON,
  GuestBookEventDetailResponseDataFromJSONTyped,
  GuestBookEventDetailResponseDataToJSON,
} from "./GuestBookEventDetailResponseData";

/**
 *
 * @export
 * @interface GetGuestBookEventsResponseData
 */
export interface GetGuestBookEventsResponseData {
  /**
   *
   * @type {string}
   * @memberof GetGuestBookEventsResponseData
   */
  client_name?: string;
  /**
   *
   * @type {Array<GuestBookEventDetailResponseData>}
   * @memberof GetGuestBookEventsResponseData
   */
  events?: Array<GuestBookEventDetailResponseData>;
}

/**
 * Check if a given object implements the GetGuestBookEventsResponseData interface.
 */
export function instanceOfGetGuestBookEventsResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetGuestBookEventsResponseDataFromJSON(
  json: any
): GetGuestBookEventsResponseData {
  return GetGuestBookEventsResponseDataFromJSONTyped(json, false);
}

export function GetGuestBookEventsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetGuestBookEventsResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    client_name: !exists(json, "client_name") ? undefined : json["client_name"],
    events: !exists(json, "events")
      ? undefined
      : (json["events"] as Array<any>).map(
          GuestBookEventDetailResponseDataFromJSON
        ),
  };
}

export function GetGuestBookEventsResponseDataToJSON(
  value?: GetGuestBookEventsResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    client_name: value.client_name,
    events:
      value.events === undefined
        ? undefined
        : (value.events as Array<any>).map(
            GuestBookEventDetailResponseDataToJSON
          ),
  };
}
