/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const RSVPStyle = {
  HIDDEN_RSVP_STYLE: "HIDDEN",
  WISH_ONLY: "WISH_ONLY",
} as const;
export type RSVPStyle = (typeof RSVPStyle)[keyof typeof RSVPStyle];

export function RSVPStyleFromJSON(json: any): RSVPStyle {
  return RSVPStyleFromJSONTyped(json, false);
}

export function RSVPStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RSVPStyle {
  return json as RSVPStyle;
}

export function RSVPStyleToJSON(value?: RSVPStyle | null): any {
  return value as any;
}
