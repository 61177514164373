/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SubmitEventRsvpAttendanceBarcodeResponseData } from "./SubmitEventRsvpAttendanceBarcodeResponseData";
import {
  SubmitEventRsvpAttendanceBarcodeResponseDataFromJSON,
  SubmitEventRsvpAttendanceBarcodeResponseDataFromJSONTyped,
  SubmitEventRsvpAttendanceBarcodeResponseDataToJSON,
} from "./SubmitEventRsvpAttendanceBarcodeResponseData";

/**
 *
 * @export
 * @interface SubmitEventRsvpGuestBookConfirmationResponse
 */
export interface SubmitEventRsvpGuestBookConfirmationResponse {
  /**
   *
   * @type {SubmitEventRsvpAttendanceBarcodeResponseData}
   * @memberof SubmitEventRsvpGuestBookConfirmationResponse
   */
  data?: SubmitEventRsvpAttendanceBarcodeResponseData;
  /**
   *
   * @type {string}
   * @memberof SubmitEventRsvpGuestBookConfirmationResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof SubmitEventRsvpGuestBookConfirmationResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the SubmitEventRsvpGuestBookConfirmationResponse interface.
 */
export function instanceOfSubmitEventRsvpGuestBookConfirmationResponse(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function SubmitEventRsvpGuestBookConfirmationResponseFromJSON(
  json: any
): SubmitEventRsvpGuestBookConfirmationResponse {
  return SubmitEventRsvpGuestBookConfirmationResponseFromJSONTyped(json, false);
}

export function SubmitEventRsvpGuestBookConfirmationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitEventRsvpGuestBookConfirmationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : SubmitEventRsvpAttendanceBarcodeResponseDataFromJSON(json["data"]),
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function SubmitEventRsvpGuestBookConfirmationResponseToJSON(
  value?: SubmitEventRsvpGuestBookConfirmationResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: SubmitEventRsvpAttendanceBarcodeResponseDataToJSON(value.data),
    status: value.status,
    status_code: value.status_code,
  };
}
