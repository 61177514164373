import type { User } from "../../services/openapi/v1";

export enum AuthReducerType {
  ASSIGN_USER = "ASSIGN_USER",
}

const authReducer = (state: User = { name: "", email: "" }, action) => {
  switch (action.type) {
    case AuthReducerType.ASSIGN_USER:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default authReducer;
