/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { GetOptionSlugsByClientResponse } from "../models/index";
import {
  GetOptionSlugsByClientResponseFromJSON,
  GetOptionSlugsByClientResponseToJSON,
} from "../models/index";

/**
 *
 */
export class OptionsApi extends runtime.BaseAPI {
  /**
   * Fetch Option Slugs By Client
   * Fetch Option Slugs By Client
   */
  async dashboardClientsOptionsSlugsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetOptionSlugsByClientResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/options/slugs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetOptionSlugsByClientResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Option Slugs By Client
   * Fetch Option Slugs By Client
   */
  async dashboardClientsOptionsSlugsGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetOptionSlugsByClientResponse> {
    const response =
      await this.dashboardClientsOptionsSlugsGetRaw(initOverrides);
    return await response.value();
  }
}
