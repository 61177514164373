/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const OurStoryStyle = {
  HIDDEN_OUR_STORY_STYLE: "HIDDEN",
  PARAGRAPH: "PARAGRAPH",
} as const;
export type OurStoryStyle = (typeof OurStoryStyle)[keyof typeof OurStoryStyle];

export function OurStoryStyleFromJSON(json: any): OurStoryStyle {
  return OurStoryStyleFromJSONTyped(json, false);
}

export function OurStoryStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OurStoryStyle {
  return json as OurStoryStyle;
}

export function OurStoryStyleToJSON(value?: OurStoryStyle | null): any {
  return value as any;
}
