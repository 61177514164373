import type { ClientDetail } from "../services/openapi/v1/api";
import type { AxiosError, AxiosResponse } from "axios";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { ApiInstance } from "@services/api";
import { REACT_QUERY_CACHE_KEYS } from "@/shared/constants/CacheKeys";

const fetchAPI = (id: string) =>
  ApiInstance().clientApi().clientsGetOptions(id);

export const useGetClientsDetailData = (
  id: string,
  options?: UseQueryOptions<
    AxiosResponse<ClientDetail>,
    AxiosError,
    AxiosResponse<ClientDetail>,
    never
  >
) => {
  return useQuery(
    [REACT_QUERY_CACHE_KEYS.getClientDetail, id],
    () => fetchAPI(id),
    {
      retry: (failureCount, error) => {
        return (
          error.response?.status !== 401 &&
          error.response?.status !== 403 &&
          failureCount < 3
        );
      },
      ...options,
    }
  );
};
