/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpGuestListResponseDataInnerEventDetailsInner } from "./RsvpGuestListResponseDataInnerEventDetailsInner";
import {
  RsvpGuestListResponseDataInnerEventDetailsInnerFromJSON,
  RsvpGuestListResponseDataInnerEventDetailsInnerFromJSONTyped,
  RsvpGuestListResponseDataInnerEventDetailsInnerToJSON,
} from "./RsvpGuestListResponseDataInnerEventDetailsInner";

/**
 *
 * @export
 * @interface RsvpGuestListResponseDataInner
 */
export interface RsvpGuestListResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInner
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInner
   */
  details?: string | null;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInner
   */
  email?: string | null;
  /**
   *
   * @type {Array<RsvpGuestListResponseDataInnerEventDetailsInner>}
   * @memberof RsvpGuestListResponseDataInner
   */
  event_details?: Array<RsvpGuestListResponseDataInnerEventDetailsInner> | null;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListResponseDataInner
   */
  guest_total?: number | null;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestListResponseDataInner
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof RsvpGuestListResponseDataInner
   */
  is_attending?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestListResponseDataInner
   */
  phone_number?: string | null;
}

/**
 * Check if a given object implements the RsvpGuestListResponseDataInner interface.
 */
export function instanceOfRsvpGuestListResponseDataInner(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function RsvpGuestListResponseDataInnerFromJSON(
  json: any
): RsvpGuestListResponseDataInner {
  return RsvpGuestListResponseDataInnerFromJSONTyped(json, false);
}

export function RsvpGuestListResponseDataInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestListResponseDataInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: !exists(json, "code") ? undefined : json["code"],
    details: !exists(json, "details") ? undefined : json["details"],
    email: !exists(json, "email") ? undefined : json["email"],
    event_details: !exists(json, "event_details")
      ? undefined
      : json["event_details"] === null
        ? null
        : (json["event_details"] as Array<any>).map(
            RsvpGuestListResponseDataInnerEventDetailsInnerFromJSON
          ),
    guest_total: !exists(json, "guest_total") ? undefined : json["guest_total"],
    id: !exists(json, "id") ? undefined : json["id"],
    is_attending: !exists(json, "is_attending")
      ? undefined
      : json["is_attending"],
    name: !exists(json, "name") ? undefined : json["name"],
    phone_number: !exists(json, "phone_number")
      ? undefined
      : json["phone_number"],
  };
}

export function RsvpGuestListResponseDataInnerToJSON(
  value?: RsvpGuestListResponseDataInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    details: value.details,
    email: value.email,
    event_details:
      value.event_details === undefined
        ? undefined
        : value.event_details === null
          ? null
          : (value.event_details as Array<any>).map(
              RsvpGuestListResponseDataInnerEventDetailsInnerToJSON
            ),
    guest_total: value.guest_total,
    id: value.id,
    is_attending: value.is_attending,
    name: value.name,
    phone_number: value.phone_number,
  };
}
