/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EventInsertRequest
 */
export interface EventInsertRequest {
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  custom_time_text?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  end_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  icon_url?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  maps_url?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  session_type?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  start_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof EventInsertRequest
   */
  watch_url?: string;
}

/**
 * Check if a given object implements the EventInsertRequest interface.
 */
export function instanceOfEventInsertRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EventInsertRequestFromJSON(json: any): EventInsertRequest {
  return EventInsertRequestFromJSONTyped(json, false);
}

export function EventInsertRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EventInsertRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    custom_time_text: !exists(json, "custom_time_text")
      ? undefined
      : json["custom_time_text"],
    end_datetime: !exists(json, "end_datetime")
      ? undefined
      : json["end_datetime"],
    icon_url: !exists(json, "icon_url") ? undefined : json["icon_url"],
    location: !exists(json, "location") ? undefined : json["location"],
    maps_url: !exists(json, "maps_url") ? undefined : json["maps_url"],
    session_type: !exists(json, "session_type")
      ? undefined
      : json["session_type"],
    start_datetime: !exists(json, "start_datetime")
      ? undefined
      : json["start_datetime"],
    street: !exists(json, "street") ? undefined : json["street"],
    timezone: !exists(json, "timezone") ? undefined : json["timezone"],
    title: !exists(json, "title") ? undefined : json["title"],
    type: !exists(json, "type") ? undefined : json["type"],
    watch_url: !exists(json, "watch_url") ? undefined : json["watch_url"],
  };
}

export function EventInsertRequestToJSON(
  value?: EventInsertRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    custom_time_text: value.custom_time_text,
    end_datetime: value.end_datetime,
    icon_url: value.icon_url,
    location: value.location,
    maps_url: value.maps_url,
    session_type: value.session_type,
    start_datetime: value.start_datetime,
    street: value.street,
    timezone: value.timezone,
    title: value.title,
    type: value.type,
    watch_url: value.watch_url,
  };
}
