/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ContentResponse } from "./ContentResponse";
import {
  ContentResponseFromJSON,
  ContentResponseFromJSONTyped,
  ContentResponseToJSON,
} from "./ContentResponse";
import type { OptionResponse } from "./OptionResponse";
import {
  OptionResponseFromJSON,
  OptionResponseFromJSONTyped,
  OptionResponseToJSON,
} from "./OptionResponse";

/**
 *
 * @export
 * @interface ClientResponseData
 */
export interface ClientResponseData {
  /**
   *
   * @type {ContentResponse}
   * @memberof ClientResponseData
   */
  content?: ContentResponse;
  /**
   *
   * @type {number}
   * @memberof ClientResponseData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClientResponseData
   */
  name?: string;
  /**
   *
   * @type {OptionResponse}
   * @memberof ClientResponseData
   */
  option?: OptionResponse;
  /**
   *
   * @type {string}
   * @memberof ClientResponseData
   */
  version?: string;
}

/**
 * Check if a given object implements the ClientResponseData interface.
 */
export function instanceOfClientResponseData(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ClientResponseDataFromJSON(json: any): ClientResponseData {
  return ClientResponseDataFromJSONTyped(json, false);
}

export function ClientResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content: !exists(json, "content")
      ? undefined
      : ContentResponseFromJSON(json["content"]),
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    option: !exists(json, "option")
      ? undefined
      : OptionResponseFromJSON(json["option"]),
    version: !exists(json, "version") ? undefined : json["version"],
  };
}

export function ClientResponseDataToJSON(
  value?: ClientResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: ContentResponseToJSON(value.content),
    id: value.id,
    name: value.name,
    option: OptionResponseToJSON(value.option),
    version: value.version,
  };
}
