import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useImageUploader from "@hooks/ImageUploader";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { GallerySectionEnum } from "@/services/openapi/v1";
import { optionFields } from "@shared/utils/FormUtils/formUtils";
import { ImageWrapper } from "@shared/styles/FormStyles";

// types
import type { Client, Gallery } from "@/services/openapi/v1";

const Galleries = () => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Client>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "content.galleries",
    keyName: "idGallery",
  });

  const params = useParams<{ id: string }>();

  const [photoUrl, setPhotoUrl] = useState<string[]>(
    (fields as unknown as (Gallery & { idGallery: string })[]).map(
      (item) => item.picture_url || ""
    )
  );

  const galleriesSection = (
    fields as unknown as (Gallery & { idGallery: string })[]
  ).map((item) => item.section || "");
  const [selectedGalleriesSection, setSelectedGalleriesSection] = useState("");

  const [selectedGalleryPhoto, setSelectedGalleryPhoto] = useState("");
  const [selectedGalleryIndex, setSelectedGalleryIndex] = useState(-1);

  const handleClickUpdatePhoto = (
    galleryPhoto: string,
    galleryIndex: number
  ) => {
    setSelectedGalleryPhoto(galleryPhoto);
    setSelectedGalleryIndex(galleryIndex);
    setSelectedGalleriesSection(galleriesSection[galleryIndex]);
  };

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }

    setSelectedGalleryIndex(-1);
    setSelectedGalleryPhoto("");
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_GALLERIES",
      old_file_url: selectedGalleryPhoto,
      order: selectedGalleryIndex + 1,
      sub_section: selectedGalleriesSection,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue(
        `content.galleries.${selectedGalleryIndex}.picture_url`,
        uploadResponse
      );
      if (photoUrl.length === selectedGalleryIndex) {
        setPhotoUrl([...photoUrl, uploadResponse]);
      } else {
        setPhotoUrl(
          photoUrl.map((photo, index) => {
            if (index === selectedGalleryIndex) {
              return uploadResponse;
            }
            return photo;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedGalleryIndex >= 0) {
      handleUpdatePhoto();
    }
  }, [selectedGalleryIndex, selectedGalleryPhoto]);

  return (
    <>
      <section className="mb-2">
        <Button
          variant="primary"
          onClick={() => {
            append({});
          }}
        >
          Add Gallery
        </Button>
      </section>
      <Accordion alwaysOpen>
        {(fields as unknown as (Gallery & { idGallery: string })[]).map(
          (item, index) => {
            const error =
              errors.content?.galleries &&
              errors.content?.galleries[`${index}`];
            return (
              <Accordion.Item eventKey={index.toString()} key={item.idGallery}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Accordion.Header
                    style={{ flex: "1" }}
                    className={error ? "bg-danger" : ""}
                  >{`Gallery ${index + 1}`}</Accordion.Header>
                  <Button
                    variant="danger"
                    onClick={() => remove(index)}
                    style={{ margin: "10px" }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Col md={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="input"
                            {...register(`content.galleries.${index}.title`)}
                            defaultValue={item.title}
                            isInvalid={!!error?.title?.message}
                          />
                          {!!error?.title?.message && (
                            <Form.Control.Feedback type="invalid">
                              {error?.title?.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>Section</Form.Label>
                          <Controller
                            control={control}
                            name={`content.galleries.${index}.section`}
                            render={({ field }) => {
                              return (
                                <Form.Group>
                                  <Form.Select
                                    defaultValue={field.value}
                                    onChange={(e) => {
                                      galleriesSection[index] = e.target
                                        .value as GallerySectionEnum;

                                      if (photoUrl[index]) {
                                        setPhotoUrl(
                                          photoUrl.map((photo, photoIndex) => {
                                            if (index === photoIndex) {
                                              setValue(
                                                `content.galleries.${index}.picture_url`,
                                                ""
                                              );
                                              return undefined;
                                            }
                                            return photo;
                                          })
                                        );
                                      }

                                      return field.onChange(e.target.value);
                                    }}
                                    isInvalid={!!error?.section?.message}
                                  >
                                    {optionFields(GallerySectionEnum)}
                                  </Form.Select>
                                  {!!error?.section?.message && (
                                    <Form.Control.Feedback type="invalid">
                                      {error?.section?.message}
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} className="mb-3">
                        <Form.Group>
                          <Form.Label>Overlay Opacity</Form.Label>
                          <Form.Control
                            type="input"
                            {...register(
                              `content.galleries.${index}.overlay_opacity`
                            )}
                            defaultValue={item.overlay_opacity}
                            isInvalid={!!error?.overlay_opacity?.message}
                          />
                          {!!error?.overlay_opacity?.message && (
                            <Form.Control.Feedback type="invalid">
                              {error?.overlay_opacity?.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Col>
                    <Col md={6}>
                      <Row>
                        {photoUrl[index] && (
                          <ImageWrapper className="mb-3 mb-lg-0">
                            <img
                              src={photoUrl[index]}
                              alt={`gallery[${index}] photo`}
                            />
                          </ImageWrapper>
                        )}
                        <Form.Label>Picture URL</Form.Label>
                        <Form.Group as={Col} md={9}>
                          <Form.Control
                            type="input"
                            {...register(
                              `content.galleries.${index}.picture_url`
                            )}
                            defaultValue={item.picture_url}
                            isInvalid={!!error?.picture_url?.message}
                            disabled
                          />
                          {!!error?.picture_url?.message && (
                            <Form.Control.Feedback type="invalid">
                              {error?.picture_url?.message}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Col md={3} className="mt-3 text-end mt-lg-0">
                          <Button
                            type="button"
                            onClick={() =>
                              handleClickUpdatePhoto(photoUrl[index], index)
                            }
                            disabled={!params.id}
                          >
                            Update photo
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            );
          }
        )}
      </Accordion>
      <ImageUploadModal />
    </>
  );
};

export default Galleries;
