/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const OpeningModalStyle = {
  HIDDEN: "HIDDEN",
  QUERY_REVERSE: "QUERY_REVERSE",
  STYLE2: "STYLE2",
} as const;
export type OpeningModalStyle =
  (typeof OpeningModalStyle)[keyof typeof OpeningModalStyle];

export function OpeningModalStyleFromJSON(json: any): OpeningModalStyle {
  return OpeningModalStyleFromJSONTyped(json, false);
}

export function OpeningModalStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpeningModalStyle {
  return json as OpeningModalStyle;
}

export function OpeningModalStyleToJSON(value?: OpeningModalStyle | null): any {
  return value as any;
}
