import { toZonedIsoString, toZonedIsoStringV2 } from "@shared/utils/DateUtils";
import type { Content, ClientDetail, OptionList } from "@/services/openapi/v1";
import { revalidateSlugService } from "@/services/api/revalidateSlugService";
import { toast } from "react-toastify";
import type { AxiosResponse } from "axios";

const formatText = (item) => {
  return item ? item.replace(/_/g, " ").toLowerCase() : "default";
};

export const optionFields = (propsObject: Record<string, string>) => {
  const data = { default: null, ...propsObject };
  return Object.values(data).map((item, index) => {
    const formattedItem = formatText(item);
    return (
      <option value={item} key={index}>
        {formattedItem}
      </option>
    );
  });
};

export const mappingDateDataContent = (data: Content) => {
  const { galleries, events, stories, wedding_date } = data;
  const newGalleries = galleries.map((value) => {
    return {
      ...value,
    };
  });
  const newEvents = events.map((value) => {
    return {
      ...value,
      start_datetime: toZonedIsoString(value.start_datetime),
      end_datetime: toZonedIsoString(value.end_datetime),
    };
  });
  const newStories = stories.map((value) => {
    return {
      ...value,
      datetime: toZonedIsoString(value.datetime),
    };
  });
  const newDataContent = {
    ...data,
    wedding_date: toZonedIsoString(wedding_date),
    galleries: newGalleries,
    events: newEvents,
    stories: newStories,
  };
  return newDataContent;
};

export const mappingDateDataContentV2 = (data: Content) => {
  const { galleries, events, stories, wedding_date } = data;
  const newGalleries = galleries.map((value) => {
    return {
      ...value,
    };
  });
  const newEvents = events.map((value) => {
    return {
      ...value,
      start_datetime: toZonedIsoStringV2(value.start_datetime),
      end_datetime: toZonedIsoStringV2(value.end_datetime),
    };
  });
  const newStories = stories.map((value) => {
    return {
      ...value,
      datetime: toZonedIsoStringV2(value.datetime),
    };
  });
  const newDataContent = {
    ...data,
    wedding_date: toZonedIsoStringV2(wedding_date),
    galleries: newGalleries,
    events: newEvents,
    stories: newStories,
  };
  return newDataContent;
};

export const mappingCustomText = (customText: { [key: string]: string }) => {
  const newCustomText = {};
  for (const key in customText) {
    if (customText[key]) {
      newCustomText[key] = customText[key];
    }
  }
  return newCustomText;
};

export const formatLabelText = (title: string) => {
  return (title[0].toUpperCase() + title.slice(1)).replace(/_/g, " ");
};

const urlToSlug = (option: OptionList) => {
  const urlParts = option.web_url.split("/");
  return urlParts[urlParts.length - 1];
};

export const renderPromiseToast = (
  promise: Promise<any>,
  pendingMsg: string,
  succesMsg: string,
  errMsg: string
): Promise<any> => {
  return toast.promise(promise, {
    pending: pendingMsg,
    success: succesMsg,
    error: {
      render({ data }) {
        return `${errMsg}, ${data}`;
      },
    },
  });
};

export const revalidateSlug = async (
  clientsDetailData: ClientDetail
): Promise<AxiosResponse<{ slug: string; revalidated: boolean }>[]> => {
  return Promise.all(
    clientsDetailData.options.map(async (option) => {
      const slug = urlToSlug(option);
      return revalidateSlugService(slug)
        .then((res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          if (err.status === 500) {
            toast.error(
              <div>
                Error validating!
                <br />
                slug: {err.response.data.slug}
              </div>
            );
          }
          return Promise.reject(err);
        });
    })
  );
};
