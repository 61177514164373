/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { GalleryStatus } from "./GalleryStatus";
import {
  GalleryStatusFromJSON,
  GalleryStatusFromJSONTyped,
  GalleryStatusToJSON,
} from "./GalleryStatus";

/**
 *
 * @export
 * @interface GalleryResponse
 */
export interface GalleryResponse {
  /**
   *
   * @type {string}
   * @memberof GalleryResponse
   */
  css?: string;
  /**
   *
   * @type {number}
   * @memberof GalleryResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GalleryResponse
   */
  overlay_opacity?: string;
  /**
   *
   * @type {string}
   * @memberof GalleryResponse
   */
  picture_url?: string;
  /**
   *
   * @type {GalleryStatus}
   * @memberof GalleryResponse
   */
  section?: GalleryStatus;
  /**
   *
   * @type {string}
   * @memberof GalleryResponse
   */
  title?: string;
}

/**
 * Check if a given object implements the GalleryResponse interface.
 */
export function instanceOfGalleryResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GalleryResponseFromJSON(json: any): GalleryResponse {
  return GalleryResponseFromJSONTyped(json, false);
}

export function GalleryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GalleryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    css: !exists(json, "css") ? undefined : json["css"],
    id: !exists(json, "id") ? undefined : json["id"],
    overlay_opacity: !exists(json, "overlay_opacity")
      ? undefined
      : json["overlay_opacity"],
    picture_url: !exists(json, "picture_url") ? undefined : json["picture_url"],
    section: !exists(json, "section")
      ? undefined
      : GalleryStatusFromJSON(json["section"]),
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function GalleryResponseToJSON(value?: GalleryResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    css: value.css,
    id: value.id,
    overlay_opacity: value.overlay_opacity,
    picture_url: value.picture_url,
    section: GalleryStatusToJSON(value.section),
    title: value.title,
  };
}
