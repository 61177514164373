/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ClientInsertRequest,
  ClientUpdateRequest,
  CreateClientResponse,
  GetClientByOptionIdResponse,
  GetClientBySlugResponse,
  GetClientMetaResponse,
  GetLockConfigByOptionSlugResponse,
  GetOptionSlugResponse,
  UpdateClientResponse,
} from "../models/index";
import {
  ClientInsertRequestFromJSON,
  ClientInsertRequestToJSON,
  ClientUpdateRequestFromJSON,
  ClientUpdateRequestToJSON,
  CreateClientResponseFromJSON,
  CreateClientResponseToJSON,
  GetClientByOptionIdResponseFromJSON,
  GetClientByOptionIdResponseToJSON,
  GetClientBySlugResponseFromJSON,
  GetClientBySlugResponseToJSON,
  GetClientMetaResponseFromJSON,
  GetClientMetaResponseToJSON,
  GetLockConfigByOptionSlugResponseFromJSON,
  GetLockConfigByOptionSlugResponseToJSON,
  GetOptionSlugResponseFromJSON,
  GetOptionSlugResponseToJSON,
  UpdateClientResponseFromJSON,
  UpdateClientResponseToJSON,
} from "../models/index";

export interface DashboardAdminClientsOptionIdGetRequest {
  optionId: string;
}

export interface DashboardAdminClientsOptionIdPutRequest {
  optionId: string;
  requestBody: ClientUpdateRequest;
}

export interface DashboardAdminClientsPostRequest {
  requestBody: ClientInsertRequest;
}

export interface DashboardAdminPhpClientsPostRequest {
  requestBody: ClientInsertRequest;
}

export interface PublicClientsGetSlugsGetRequest {
  filter?: string;
}

export interface PublicClientsSlugGetRequest {
  slug: string;
  guestCode?: string;
}

export interface PublicClientsSlugLockGetRequest {
  slug: string;
}

export interface PublicClientsSlugMetaGetRequest {
  slug: string;
}

/**
 *
 */
export class ClientsApi extends runtime.BaseAPI {
  /**
   * Get Client Detail By OptionId
   * Get Client Detail By OptionId
   */
  async dashboardAdminClientsOptionIdGetRaw(
    requestParameters: DashboardAdminClientsOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetClientByOptionIdResponse>> {
    if (
      requestParameters.optionId === null ||
      requestParameters.optionId === undefined
    ) {
      throw new runtime.RequiredError(
        "optionId",
        "Required parameter requestParameters.optionId was null or undefined when calling dashboardAdminClientsOptionIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/clients/{optionId}`.replace(
          `{${"optionId"}}`,
          encodeURIComponent(String(requestParameters.optionId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetClientByOptionIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Client Detail By OptionId
   * Get Client Detail By OptionId
   */
  async dashboardAdminClientsOptionIdGet(
    requestParameters: DashboardAdminClientsOptionIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetClientByOptionIdResponse> {
    const response = await this.dashboardAdminClientsOptionIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Client Data
   * Update Client
   */
  async dashboardAdminClientsOptionIdPutRaw(
    requestParameters: DashboardAdminClientsOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateClientResponse>> {
    if (
      requestParameters.optionId === null ||
      requestParameters.optionId === undefined
    ) {
      throw new runtime.RequiredError(
        "optionId",
        "Required parameter requestParameters.optionId was null or undefined when calling dashboardAdminClientsOptionIdPut."
      );
    }

    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardAdminClientsOptionIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/clients/{optionId}`.replace(
          `{${"optionId"}}`,
          encodeURIComponent(String(requestParameters.optionId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ClientUpdateRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateClientResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Client Data
   * Update Client
   */
  async dashboardAdminClientsOptionIdPut(
    requestParameters: DashboardAdminClientsOptionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateClientResponse> {
    const response = await this.dashboardAdminClientsOptionIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Client Data
   * Create Client
   */
  async dashboardAdminClientsPostRaw(
    requestParameters: DashboardAdminClientsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateClientResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardAdminClientsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/clients`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ClientInsertRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateClientResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Client Data
   * Create Client
   */
  async dashboardAdminClientsPost(
    requestParameters: DashboardAdminClientsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateClientResponse> {
    const response = await this.dashboardAdminClientsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Client From Old Dashboard
   * Create Client From Old Dashboard
   */
  async dashboardAdminPhpClientsPostRaw(
    requestParameters: DashboardAdminPhpClientsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateClientResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling dashboardAdminPhpClientsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // PHPBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/php/clients`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ClientInsertRequestToJSON(requestParameters.requestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateClientResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Client From Old Dashboard
   * Create Client From Old Dashboard
   */
  async dashboardAdminPhpClientsPost(
    requestParameters: DashboardAdminPhpClientsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateClientResponse> {
    const response = await this.dashboardAdminPhpClientsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Display all slugs data
   * Get Client Slugs
   */
  async publicClientsGetSlugsGetRaw(
    requestParameters: PublicClientsGetSlugsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetOptionSlugResponse>> {
    const queryParameters: any = {};

    if (requestParameters.filter !== undefined) {
      queryParameters["filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/clients/get/slugs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetOptionSlugResponseFromJSON(jsonValue)
    );
  }

  /**
   * Display all slugs data
   * Get Client Slugs
   */
  async publicClientsGetSlugsGet(
    requestParameters: PublicClientsGetSlugsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetOptionSlugResponse> {
    const response = await this.publicClientsGetSlugsGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Client Detail By Option Slug
   * Get Client Detail By Option Slug
   */
  async publicClientsSlugGetRaw(
    requestParameters: PublicClientsSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetClientBySlugResponse>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling publicClientsSlugGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.guestCode !== undefined &&
      requestParameters.guestCode !== null
    ) {
      headerParameters["Guest-Code"] = String(requestParameters.guestCode);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // GuestBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/clients/{slug}`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetClientBySlugResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Client Detail By Option Slug
   * Get Client Detail By Option Slug
   */
  async publicClientsSlugGet(
    requestParameters: PublicClientsSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetClientBySlugResponse> {
    const response = await this.publicClientsSlugGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Lock Screen Config By Option Slug
   * Get Lock Config By Option Slug
   */
  async publicClientsSlugLockGetRaw(
    requestParameters: PublicClientsSlugLockGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetLockConfigByOptionSlugResponse>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling publicClientsSlugLockGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/clients/{slug}/lock`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetLockConfigByOptionSlugResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Lock Screen Config By Option Slug
   * Get Lock Config By Option Slug
   */
  async publicClientsSlugLockGet(
    requestParameters: PublicClientsSlugLockGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetLockConfigByOptionSlugResponse> {
    const response = await this.publicClientsSlugLockGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Client Meta By Option Slug
   * Get Client Meta By Option Slug
   */
  async publicClientsSlugMetaGetRaw(
    requestParameters: PublicClientsSlugMetaGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetClientMetaResponse>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling publicClientsSlugMetaGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/public/clients/{slug}/meta`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetClientMetaResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Client Meta By Option Slug
   * Get Client Meta By Option Slug
   */
  async publicClientsSlugMetaGet(
    requestParameters: PublicClientsSlugMetaGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetClientMetaResponse> {
    const response = await this.publicClientsSlugMetaGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
