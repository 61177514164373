import axios from "axios";
import {
  AuthApiFactory,
  BooksApiFactory,
  ClientApiFactory,
  WishesApiFactory,
  Configuration,
  FilesApiFactory,
  LinksApiFactory,
  BanksApiFactory,
} from "@/services/openapi/v1";

import type { ConfigurationParameters } from "@/services/openapi/v2";
import {
  ClientsApi,
  Configuration as ConfigurationV2,
  EventsApi,
  OptionsApi,
  LinksApi,
  RsvpApi,
  WishesApi,
  AuthApi,
} from "@/services/openapi/v2";

// API V1
export const basePath = process.env.REACT_APP_API_HOST;
export const axiosInstance = () => {
  const axiosApi = axios;
  axiosApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
      }
      console.error(error);
      return Promise.reject(error);
    }
  );
  return axiosApi;
};

export const ApiInstance = () => {
  const configuration = new Configuration({
    accessToken: localStorage.getItem("token") || "",
  });

  return {
    clientApi() {
      return ClientApiFactory(configuration, basePath, axiosInstance());
    },
    authApi() {
      return AuthApiFactory(configuration, basePath, axiosInstance());
    },
    booksApi() {
      return BooksApiFactory(configuration, basePath, axiosInstance());
    },
    wishesApi() {
      return WishesApiFactory(configuration, basePath, axiosInstance());
    },
    filesApi() {
      return FilesApiFactory(configuration, basePath, axiosInstance());
    },
    linksApi() {
      return LinksApiFactory(configuration, basePath, axiosInstance());
    },
    banksApi() {
      return BanksApiFactory(configuration, basePath, axiosInstance());
    },
  };
};

// API V2
const getConfigAPI = (defaultConfig: ConfigurationParameters) => {
  const getAccessToken = () => {
    return localStorage.getItem("token") || "";
  };
  const accessToken = defaultConfig.accessToken ?? getAccessToken();

  return new ConfigurationV2({
    ...defaultConfig,
    apiKey: `Bearer ${accessToken}`,
    middleware: [
      {
        post: async (context) => {
          if (context?.response?.status === 401) {
            // TODO: handle 401 error
            // localStorage.removeItem("authUser");
            // localStorage.removeItem("token");
          }
        },
      },
    ],
  });
};

export const apiClientV2 = (config?: ConfigurationParameters) => {
  const defaultConfig: ConfigurationParameters = {
    ...(config || {}),
    basePath: process.env.REACT_APP_NEXT_PUBLIC_API_V2_URL,
  };
  const configAPI = getConfigAPI(defaultConfig);
  return {
    client: new ClientsApi(configAPI),
    rsvp: new RsvpApi(configAPI),
    wishes: new WishesApi(configAPI),
    event: new EventsApi(configAPI),
    option: new OptionsApi(configAPI),
    links: new LinksApi(configAPI),
    auth: new AuthApi(configAPI),
  };
};
