import React from "react";
import { connect } from "react-redux";
import type { StaticContext } from "react-router";
import type { RouteComponentProps } from "react-router-dom";
import { Redirect } from "react-router-dom";
import type { User } from "@/services/openapi/v1";
import { UserRoleEnum } from "@/services/openapi/v1";
import { RoutePath } from "@constants/RoutesNavigator";

interface Props {
  children: React.ReactNode;
  roleMiddleware: UserRoleEnum;
  authUser: User;
  routeProps: RouteComponentProps<
    {
      [x: string]: string;
    },
    StaticContext,
    unknown
  >;
}

const RoleMiddleware = (props: Props) => {
  const { children, routeProps, roleMiddleware, authUser } = props;

  const renderComponent = () => {
    if (
      !roleMiddleware ||
      authUser.role === roleMiddleware ||
      authUser.role === UserRoleEnum.SuperAdmin
    ) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      switch (authUser.role) {
        case UserRoleEnum.Admin:
          if (roleMiddleware !== UserRoleEnum.SuperAdmin) {
            return <React.Fragment>{children}</React.Fragment>;
          }
          return (
            <Redirect
              to={{
                pathname: RoutePath.HOME,
                state: { from: routeProps.location },
              }}
            />
          );
        case UserRoleEnum.Client:
          if (
            roleMiddleware !== UserRoleEnum.SuperAdmin &&
            roleMiddleware !== UserRoleEnum.Admin
          ) {
            return <React.Fragment>{children}</React.Fragment>;
          }
          return (
            <Redirect
              to={{
                pathname: RoutePath.HOME,
                state: { from: routeProps.location },
              }}
            />
          );
        default:
          return (
            <Redirect
              to={{
                pathname: RoutePath.HOME,
                state: { from: routeProps.location },
              }}
            />
          );
      }
    }
  };
  return renderComponent();
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authReducer,
  };
};

export default connect(mapStateToProps)(RoleMiddleware);
