/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BrideRequest } from "./BrideRequest";
import {
  BrideRequestFromJSON,
  BrideRequestFromJSONTyped,
  BrideRequestToJSON,
} from "./BrideRequest";
import type { EventInsertRequest } from "./EventInsertRequest";
import {
  EventInsertRequestFromJSON,
  EventInsertRequestFromJSONTyped,
  EventInsertRequestToJSON,
} from "./EventInsertRequest";
import type { GalleryInsertResponse } from "./GalleryInsertResponse";
import {
  GalleryInsertResponseFromJSON,
  GalleryInsertResponseFromJSONTyped,
  GalleryInsertResponseToJSON,
} from "./GalleryInsertResponse";
import type { GroomRequest } from "./GroomRequest";
import {
  GroomRequestFromJSON,
  GroomRequestFromJSONTyped,
  GroomRequestToJSON,
} from "./GroomRequest";
import type { HeroBackgroundInsertRequest } from "./HeroBackgroundInsertRequest";
import {
  HeroBackgroundInsertRequestFromJSON,
  HeroBackgroundInsertRequestFromJSONTyped,
  HeroBackgroundInsertRequestToJSON,
} from "./HeroBackgroundInsertRequest";
import type { OpeningModalImageType } from "./OpeningModalImageType";
import {
  OpeningModalImageTypeFromJSON,
  OpeningModalImageTypeFromJSONTyped,
  OpeningModalImageTypeToJSON,
} from "./OpeningModalImageType";
import type { PaymentInsertRequest } from "./PaymentInsertRequest";
import {
  PaymentInsertRequestFromJSON,
  PaymentInsertRequestFromJSONTyped,
  PaymentInsertRequestToJSON,
} from "./PaymentInsertRequest";
import type { StoryInsertRequest } from "./StoryInsertRequest";
import {
  StoryInsertRequestFromJSON,
  StoryInsertRequestFromJSONTyped,
  StoryInsertRequestToJSON,
} from "./StoryInsertRequest";

/**
 *
 * @export
 * @interface ContentInsertResponse
 */
export interface ContentInsertResponse {
  /**
   *
   * @type {BrideRequest}
   * @memberof ContentInsertResponse
   */
  bride?: BrideRequest;
  /**
   *
   * @type {Array<EventInsertRequest>}
   * @memberof ContentInsertResponse
   */
  events?: Array<EventInsertRequest>;
  /**
   *
   * @type {Array<GalleryInsertResponse>}
   * @memberof ContentInsertResponse
   */
  galleries?: Array<GalleryInsertResponse>;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  galleries_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  galleries_mobile_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  greeting?: string;
  /**
   *
   * @type {GroomRequest}
   * @memberof ContentInsertResponse
   */
  groom?: GroomRequest;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  hashtag_word?: string;
  /**
   *
   * @type {Array<HeroBackgroundInsertRequest>}
   * @memberof ContentInsertResponse
   */
  hero_backgrounds?: Array<HeroBackgroundInsertRequest>;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  opening_modal_image?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  opening_modal_image_css?: string;
  /**
   *
   * @type {OpeningModalImageType}
   * @memberof ContentInsertResponse
   */
  opening_modal_image_type?: OpeningModalImageType;
  /**
   *
   * @type {Array<PaymentInsertRequest>}
   * @memberof ContentInsertResponse
   */
  payments?: Array<PaymentInsertRequest>;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  quote?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  quote_source?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  reminder_url?: string;
  /**
   *
   * @type {Array<StoryInsertRequest>}
   * @memberof ContentInsertResponse
   */
  stories?: Array<StoryInsertRequest>;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  video_prewed_url?: string;
  /**
   *
   * @type {string}
   * @memberof ContentInsertResponse
   */
  wedding_date?: string;
}

/**
 * Check if a given object implements the ContentInsertResponse interface.
 */
export function instanceOfContentInsertResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContentInsertResponseFromJSON(
  json: any
): ContentInsertResponse {
  return ContentInsertResponseFromJSONTyped(json, false);
}

export function ContentInsertResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContentInsertResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bride: !exists(json, "bride")
      ? undefined
      : BrideRequestFromJSON(json["bride"]),
    events: !exists(json, "events")
      ? undefined
      : (json["events"] as Array<any>).map(EventInsertRequestFromJSON),
    galleries: !exists(json, "galleries")
      ? undefined
      : (json["galleries"] as Array<any>).map(GalleryInsertResponseFromJSON),
    galleries_css: !exists(json, "galleries_css")
      ? undefined
      : json["galleries_css"],
    galleries_mobile_css: !exists(json, "galleries_mobile_css")
      ? undefined
      : json["galleries_mobile_css"],
    greeting: !exists(json, "greeting") ? undefined : json["greeting"],
    groom: !exists(json, "groom")
      ? undefined
      : GroomRequestFromJSON(json["groom"]),
    hashtag_word: !exists(json, "hashtag_word")
      ? undefined
      : json["hashtag_word"],
    hero_backgrounds: !exists(json, "hero_backgrounds")
      ? undefined
      : (json["hero_backgrounds"] as Array<any>).map(
          HeroBackgroundInsertRequestFromJSON
        ),
    opening_modal_image: !exists(json, "opening_modal_image")
      ? undefined
      : json["opening_modal_image"],
    opening_modal_image_css: !exists(json, "opening_modal_image_css")
      ? undefined
      : json["opening_modal_image_css"],
    opening_modal_image_type: !exists(json, "opening_modal_image_type")
      ? undefined
      : OpeningModalImageTypeFromJSON(json["opening_modal_image_type"]),
    payments: !exists(json, "payments")
      ? undefined
      : (json["payments"] as Array<any>).map(PaymentInsertRequestFromJSON),
    quote: !exists(json, "quote") ? undefined : json["quote"],
    quote_source: !exists(json, "quote_source")
      ? undefined
      : json["quote_source"],
    reminder_url: !exists(json, "reminder_url")
      ? undefined
      : json["reminder_url"],
    stories: !exists(json, "stories")
      ? undefined
      : (json["stories"] as Array<any>).map(StoryInsertRequestFromJSON),
    video_prewed_url: !exists(json, "video_prewed_url")
      ? undefined
      : json["video_prewed_url"],
    wedding_date: !exists(json, "wedding_date")
      ? undefined
      : json["wedding_date"],
  };
}

export function ContentInsertResponseToJSON(
  value?: ContentInsertResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bride: BrideRequestToJSON(value.bride),
    events:
      value.events === undefined
        ? undefined
        : (value.events as Array<any>).map(EventInsertRequestToJSON),
    galleries:
      value.galleries === undefined
        ? undefined
        : (value.galleries as Array<any>).map(GalleryInsertResponseToJSON),
    galleries_css: value.galleries_css,
    galleries_mobile_css: value.galleries_mobile_css,
    greeting: value.greeting,
    groom: GroomRequestToJSON(value.groom),
    hashtag_word: value.hashtag_word,
    hero_backgrounds:
      value.hero_backgrounds === undefined
        ? undefined
        : (value.hero_backgrounds as Array<any>).map(
            HeroBackgroundInsertRequestToJSON
          ),
    opening_modal_image: value.opening_modal_image,
    opening_modal_image_css: value.opening_modal_image_css,
    opening_modal_image_type: OpeningModalImageTypeToJSON(
      value.opening_modal_image_type
    ),
    payments:
      value.payments === undefined
        ? undefined
        : (value.payments as Array<any>).map(PaymentInsertRequestToJSON),
    quote: value.quote,
    quote_source: value.quote_source,
    reminder_url: value.reminder_url,
    stories:
      value.stories === undefined
        ? undefined
        : (value.stories as Array<any>).map(StoryInsertRequestToJSON),
    video_prewed_url: value.video_prewed_url,
    wedding_date: value.wedding_date,
  };
}
