/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const QuoteStyle = {
  HIDDEN_QUOTE_STYLE: "HIDDEN",
  NO_FLOWER: "NO_FLOWER",
} as const;
export type QuoteStyle = (typeof QuoteStyle)[keyof typeof QuoteStyle];

export function QuoteStyleFromJSON(json: any): QuoteStyle {
  return QuoteStyleFromJSONTyped(json, false);
}

export function QuoteStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuoteStyle {
  return json as QuoteStyle;
}

export function QuoteStyleToJSON(value?: QuoteStyle | null): any {
  return value as any;
}
