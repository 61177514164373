import { Redirect } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import Login from "../../pages/Auth/Login";
import Links from "@pages/Links";
import Wishes from "@pages/Wishes";
import Rsvp from "@pages/Rsvp";
import Home from "@pages/Home";
import type { UserRoleEnum } from "@/services/openapi/v1";
import ClientList from "@pages/Clients/ClientList";
import ClientDetailPage from "@pages/Clients/ClientDetail";
import ClientWebDetailPage from "@pages/Clients/ClientWebDetail";
import ClientCreateWebDetail from "@pages/Clients/ClientCreateWebDetail";
import ClientFormPage from "@pages/Clients/ClientFormPage";
import type { ComponentType } from "react";
import { RoutePath } from "@constants/RoutesNavigator";

export interface Route {
  path: string;
  // TODO: remove this any type with the correct type
  component: ComponentType<any>;
  exact: boolean;
  roleMiddleware?: UserRoleEnum;
}

const userRoutes: Route[] = [
  { path: RoutePath.HOME, exact: true, component: Home },
  { path: RoutePath.DASHBOARD, exact: true, component: Dashboard },
  {
    path: RoutePath.WISHES,
    exact: true,
    component: Wishes,
  },
  { path: RoutePath.RSVP, exact: true, component: Rsvp },
  { path: RoutePath.CLIENTS, exact: true, component: ClientList },
  { path: RoutePath.CLIENT_ADD, exact: true, component: ClientFormPage },
  {
    path: RoutePath.LINKS,
    exact: true,
    component: Links,
  },
  {
    path: RoutePath.CREATE_WEB_DETAIL(":id"),
    exact: true,
    component: ClientCreateWebDetail,
  },
  {
    path: RoutePath.CLIENT_DETAIL(":id"),
    exact: true,
    component: ClientDetailPage,
  },
  {
    path: RoutePath.CLIENT_WEB_DETAIL(":id"),
    exact: true,
    component: ClientWebDetailPage,
  },
  // this route should be at the end of all other routes
  {
    path: RoutePath.ROOT,
    exact: true,
    component: () => <Redirect to={RoutePath.HOME} />,
  },
];

const publicRoutes: Route[] = [
  // Auth
  { path: RoutePath.LOGIN, component: Login, exact: true },

  // this route should be at the end of all other routes
  {
    path: "*",
    component: () => <Redirect to={RoutePath.LOGIN} />,
    exact: true,
  },
];

export { userRoutes, publicRoutes };
