/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const HealthProtocolStyle = {
  HIDDEN_HEALTH_PROTOCOL_STYLE: "HIDDEN",
  SHOW: "SHOW",
  MODAL1: "MODAL1",
} as const;
export type HealthProtocolStyle =
  (typeof HealthProtocolStyle)[keyof typeof HealthProtocolStyle];

export function HealthProtocolStyleFromJSON(json: any): HealthProtocolStyle {
  return HealthProtocolStyleFromJSONTyped(json, false);
}

export function HealthProtocolStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HealthProtocolStyle {
  return json as HealthProtocolStyle;
}

export function HealthProtocolStyleToJSON(
  value?: HealthProtocolStyle | null
): any {
  return value as any;
}
