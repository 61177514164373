/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserLoginRequest
 */
export interface UserLoginRequest {
  /**
   *
   * @type {string}
   * @memberof UserLoginRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UserLoginRequest
   */
  username: string;
}

/**
 * Check if a given object implements the UserLoginRequest interface.
 */
export function instanceOfUserLoginRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "password" in value;
  isInstance = isInstance && "username" in value;

  return isInstance;
}

export function UserLoginRequestFromJSON(json: any): UserLoginRequest {
  return UserLoginRequestFromJSONTyped(json, false);
}

export function UserLoginRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserLoginRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: json["password"],
    username: json["username"],
  };
}

export function UserLoginRequestToJSON(value?: UserLoginRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    username: value.username,
  };
}
