/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  GetEventsByClientIdResponse,
  GetEventsBySlugResponse,
  GetGuestBookEventDetailByIdResponse,
  GetGuestBookEventsResponse,
  GetGuestEventDetailByGuestCodeAndEventIdResponse,
  RsvpAttendanceReconcilationResponse,
  SearchGuestsInCheckInAppResponse,
  SubmitEventRsvpAttendanceBarcodeRequest,
  SubmitEventRsvpAttendanceBarcodeResponse,
  SubmitEventRsvpAttendancePhoneNumberRequest,
  SubmitEventRsvpGuestBookConfirmationRequest,
  SubmitEventRsvpGuestBookConfirmationResponse,
} from "../models/index";
import {
  GetEventsByClientIdResponseFromJSON,
  GetEventsByClientIdResponseToJSON,
  GetEventsBySlugResponseFromJSON,
  GetEventsBySlugResponseToJSON,
  GetGuestBookEventDetailByIdResponseFromJSON,
  GetGuestBookEventDetailByIdResponseToJSON,
  GetGuestBookEventsResponseFromJSON,
  GetGuestBookEventsResponseToJSON,
  GetGuestEventDetailByGuestCodeAndEventIdResponseFromJSON,
  GetGuestEventDetailByGuestCodeAndEventIdResponseToJSON,
  RsvpAttendanceReconcilationResponseFromJSON,
  RsvpAttendanceReconcilationResponseToJSON,
  SearchGuestsInCheckInAppResponseFromJSON,
  SearchGuestsInCheckInAppResponseToJSON,
  SubmitEventRsvpAttendanceBarcodeRequestFromJSON,
  SubmitEventRsvpAttendanceBarcodeRequestToJSON,
  SubmitEventRsvpAttendanceBarcodeResponseFromJSON,
  SubmitEventRsvpAttendanceBarcodeResponseToJSON,
  SubmitEventRsvpAttendancePhoneNumberRequestFromJSON,
  SubmitEventRsvpAttendancePhoneNumberRequestToJSON,
  SubmitEventRsvpGuestBookConfirmationRequestFromJSON,
  SubmitEventRsvpGuestBookConfirmationRequestToJSON,
  SubmitEventRsvpGuestBookConfirmationResponseFromJSON,
  SubmitEventRsvpGuestBookConfirmationResponseToJSON,
} from "../models/index";

export interface DashboardAdminEventsSlugGetRequest {
  slug: string;
}

export interface DashboardAdminRsvpEventsReconciliationEventIdPostRequest {
  eventId: number;
  rsvpAttendanceReconciliation: Blob;
}

export interface PublicGuestsRsvpEventsCheckInPostRequest {
  requestBody: SubmitEventRsvpGuestBookConfirmationRequest;
}

export interface PublicGuestsRsvpEventsGuestBookEventDetailEventIdGetRequest {
  eventId: number;
}

export interface PublicGuestsRsvpEventsGuestBookSearchGuestsGetRequest {
  eventId: number;
  search: string;
  page: number;
  limit: number;
}

export interface PublicGuestsRsvpEventsGuestDetailGuestCodeEventIdGetRequest {
  guestCode: string;
  eventId: number;
}

export interface PublicGuestsRsvpEventsPhoneNumberPostRequest {
  requestBody: SubmitEventRsvpAttendancePhoneNumberRequest;
}

export interface PublicGuestsRsvpEventsScanPostRequest {
  requestBody: SubmitEventRsvpAttendanceBarcodeRequest;
}

/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
  /**
   * Fetch Events By Slug
   * Fetch Events By Slug
   */
  async dashboardAdminEventsSlugGetRaw(
    requestParameters: DashboardAdminEventsSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetEventsBySlugResponse>> {
    if (
      requestParameters.slug === null ||
      requestParameters.slug === undefined
    ) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling dashboardAdminEventsSlugGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/events/{slug}`.replace(
          `{${"slug"}}`,
          encodeURIComponent(String(requestParameters.slug))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetEventsBySlugResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Events By Slug
   * Fetch Events By Slug
   */
  async dashboardAdminEventsSlugGet(
    requestParameters: DashboardAdminEventsSlugGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetEventsBySlugResponse> {
    const response = await this.dashboardAdminEventsSlugGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Rsvp Attendance Reconciliation
   * Rsvp Attendance Reconciliation
   */
  async dashboardAdminRsvpEventsReconciliationEventIdPostRaw(
    requestParameters: DashboardAdminRsvpEventsReconciliationEventIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RsvpAttendanceReconcilationResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling dashboardAdminRsvpEventsReconciliationEventIdPost."
      );
    }

    if (
      requestParameters.rsvpAttendanceReconciliation === null ||
      requestParameters.rsvpAttendanceReconciliation === undefined
    ) {
      throw new runtime.RequiredError(
        "rsvpAttendanceReconciliation",
        "Required parameter requestParameters.rsvpAttendanceReconciliation was null or undefined when calling dashboardAdminRsvpEventsReconciliationEventIdPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.rsvpAttendanceReconciliation !== undefined) {
      formParams.append(
        "rsvp-attendance-reconciliation",
        requestParameters.rsvpAttendanceReconciliation as any
      );
    }

    const response = await this.request(
      {
        path: `/dashboard/admin/rsvp/events/reconciliation/{eventId}`.replace(
          `{${"eventId"}}`,
          encodeURIComponent(String(requestParameters.eventId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RsvpAttendanceReconcilationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Rsvp Attendance Reconciliation
   * Rsvp Attendance Reconciliation
   */
  async dashboardAdminRsvpEventsReconciliationEventIdPost(
    requestParameters: DashboardAdminRsvpEventsReconciliationEventIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RsvpAttendanceReconcilationResponse> {
    const response =
      await this.dashboardAdminRsvpEventsReconciliationEventIdPostRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Fetch Events By Client Id
   * Fetch Events By Client Id
   */
  async dashboardClientsEventsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetEventsByClientIdResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/dashboard/clients/events`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetEventsByClientIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Events By Client Id
   * Fetch Events By Client Id
   */
  async dashboardClientsEventsGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetEventsByClientIdResponse> {
    const response = await this.dashboardClientsEventsGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Submit Rsvp Guest Confirmation Before Entering The Venue
   * Submit Rsvp Guest Confirmation Before Entering The Venue
   */
  async publicGuestsRsvpEventsCheckInPostRaw(
    requestParameters: PublicGuestsRsvpEventsCheckInPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<
    runtime.ApiResponse<SubmitEventRsvpGuestBookConfirmationResponse>
  > {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicGuestsRsvpEventsCheckInPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/check-in`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEventRsvpGuestBookConfirmationRequestToJSON(
          requestParameters.requestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitEventRsvpGuestBookConfirmationResponseFromJSON(jsonValue)
    );
  }

  /**
   * Submit Rsvp Guest Confirmation Before Entering The Venue
   * Submit Rsvp Guest Confirmation Before Entering The Venue
   */
  async publicGuestsRsvpEventsCheckInPost(
    requestParameters: PublicGuestsRsvpEventsCheckInPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitEventRsvpGuestBookConfirmationResponse> {
    const response = await this.publicGuestsRsvpEventsCheckInPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Fetch Guest Book Event Detail By Id
   * Fetch Guest Book Event Detail By Id
   */
  async publicGuestsRsvpEventsGuestBookEventDetailEventIdGetRaw(
    requestParameters: PublicGuestsRsvpEventsGuestBookEventDetailEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetGuestBookEventDetailByIdResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling publicGuestsRsvpEventsGuestBookEventDetailEventIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/guest-book/event-detail/{eventId}`.replace(
          `{${"eventId"}}`,
          encodeURIComponent(String(requestParameters.eventId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGuestBookEventDetailByIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Guest Book Event Detail By Id
   * Fetch Guest Book Event Detail By Id
   */
  async publicGuestsRsvpEventsGuestBookEventDetailEventIdGet(
    requestParameters: PublicGuestsRsvpEventsGuestBookEventDetailEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetGuestBookEventDetailByIdResponse> {
    const response =
      await this.publicGuestsRsvpEventsGuestBookEventDetailEventIdGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Fetch Guest Book Events
   * Fetch Guest Book Events
   */
  async publicGuestsRsvpEventsGuestBookGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetGuestBookEventsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UserBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/guest-book`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGuestBookEventsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Guest Book Events
   * Fetch Guest Book Events
   */
  async publicGuestsRsvpEventsGuestBookGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetGuestBookEventsResponse> {
    const response =
      await this.publicGuestsRsvpEventsGuestBookGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Fetch Guest Event Detail By Guest Code And Event Id
   * Fetch Guest Event Detail By Guest Code And Event Id
   */
  async publicGuestsRsvpEventsGuestBookSearchGuestsGetRaw(
    requestParameters: PublicGuestsRsvpEventsGuestBookSearchGuestsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SearchGuestsInCheckInAppResponse>> {
    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling publicGuestsRsvpEventsGuestBookSearchGuestsGet."
      );
    }

    if (
      requestParameters.search === null ||
      requestParameters.search === undefined
    ) {
      throw new runtime.RequiredError(
        "search",
        "Required parameter requestParameters.search was null or undefined when calling publicGuestsRsvpEventsGuestBookSearchGuestsGet."
      );
    }

    if (
      requestParameters.page === null ||
      requestParameters.page === undefined
    ) {
      throw new runtime.RequiredError(
        "page",
        "Required parameter requestParameters.page was null or undefined when calling publicGuestsRsvpEventsGuestBookSearchGuestsGet."
      );
    }

    if (
      requestParameters.limit === null ||
      requestParameters.limit === undefined
    ) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling publicGuestsRsvpEventsGuestBookSearchGuestsGet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.eventId !== undefined) {
      queryParameters["eventId"] = requestParameters.eventId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/guest-book/search/guests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SearchGuestsInCheckInAppResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Guest Event Detail By Guest Code And Event Id
   * Fetch Guest Event Detail By Guest Code And Event Id
   */
  async publicGuestsRsvpEventsGuestBookSearchGuestsGet(
    requestParameters: PublicGuestsRsvpEventsGuestBookSearchGuestsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SearchGuestsInCheckInAppResponse> {
    const response =
      await this.publicGuestsRsvpEventsGuestBookSearchGuestsGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Fetch Guest Event Detail By Guest Code And Event Id
   * Fetch Guest Event Detail By Guest Code And Event Id
   */
  async publicGuestsRsvpEventsGuestDetailGuestCodeEventIdGetRaw(
    requestParameters: PublicGuestsRsvpEventsGuestDetailGuestCodeEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<
    runtime.ApiResponse<GetGuestEventDetailByGuestCodeAndEventIdResponse>
  > {
    if (
      requestParameters.guestCode === null ||
      requestParameters.guestCode === undefined
    ) {
      throw new runtime.RequiredError(
        "guestCode",
        "Required parameter requestParameters.guestCode was null or undefined when calling publicGuestsRsvpEventsGuestDetailGuestCodeEventIdGet."
      );
    }

    if (
      requestParameters.eventId === null ||
      requestParameters.eventId === undefined
    ) {
      throw new runtime.RequiredError(
        "eventId",
        "Required parameter requestParameters.eventId was null or undefined when calling publicGuestsRsvpEventsGuestDetailGuestCodeEventIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/guest-detail/{guestCode}/{eventId}`
          .replace(
            `{${"guestCode"}}`,
            encodeURIComponent(String(requestParameters.guestCode))
          )
          .replace(
            `{${"eventId"}}`,
            encodeURIComponent(String(requestParameters.eventId))
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetGuestEventDetailByGuestCodeAndEventIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch Guest Event Detail By Guest Code And Event Id
   * Fetch Guest Event Detail By Guest Code And Event Id
   */
  async publicGuestsRsvpEventsGuestDetailGuestCodeEventIdGet(
    requestParameters: PublicGuestsRsvpEventsGuestDetailGuestCodeEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetGuestEventDetailByGuestCodeAndEventIdResponse> {
    const response =
      await this.publicGuestsRsvpEventsGuestDetailGuestCodeEventIdGetRaw(
        requestParameters,
        initOverrides
      );
    return await response.value();
  }

  /**
   * Submit Phone Number By Guest To Attend An Event
   * Submit Phone Number By Guest To Attend An Event
   */
  async publicGuestsRsvpEventsPhoneNumberPostRaw(
    requestParameters: PublicGuestsRsvpEventsPhoneNumberPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SubmitEventRsvpAttendanceBarcodeResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicGuestsRsvpEventsPhoneNumberPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/phone-number`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEventRsvpAttendancePhoneNumberRequestToJSON(
          requestParameters.requestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitEventRsvpAttendanceBarcodeResponseFromJSON(jsonValue)
    );
  }

  /**
   * Submit Phone Number By Guest To Attend An Event
   * Submit Phone Number By Guest To Attend An Event
   */
  async publicGuestsRsvpEventsPhoneNumberPost(
    requestParameters: PublicGuestsRsvpEventsPhoneNumberPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitEventRsvpAttendanceBarcodeResponse> {
    const response = await this.publicGuestsRsvpEventsPhoneNumberPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Scan Barcode By Guest To Attend An Event
   * Scan Barcode By Guest To Attend An Event
   */
  async publicGuestsRsvpEventsScanPostRaw(
    requestParameters: PublicGuestsRsvpEventsScanPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SubmitEventRsvpAttendanceBarcodeResponse>> {
    if (
      requestParameters.requestBody === null ||
      requestParameters.requestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling publicGuestsRsvpEventsScanPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] =
        this.configuration.apiKey("Authorization"); // UsherBearerAuth authentication
    }

    const response = await this.request(
      {
        path: `/public/guests/rsvp/events/scan`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SubmitEventRsvpAttendanceBarcodeRequestToJSON(
          requestParameters.requestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubmitEventRsvpAttendanceBarcodeResponseFromJSON(jsonValue)
    );
  }

  /**
   * Scan Barcode By Guest To Attend An Event
   * Scan Barcode By Guest To Attend An Event
   */
  async publicGuestsRsvpEventsScanPost(
    requestParameters: PublicGuestsRsvpEventsScanPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SubmitEventRsvpAttendanceBarcodeResponse> {
    const response = await this.publicGuestsRsvpEventsScanPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
