/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
  /**
   *
   * @type {string}
   * @memberof BaseResponse
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof BaseResponse
   */
  status_code?: number;
}

/**
 * Check if a given object implements the BaseResponse interface.
 */
export function instanceOfBaseResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function BaseResponseFromJSON(json: any): BaseResponse {
  return BaseResponseFromJSONTyped(json, false);
}

export function BaseResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BaseResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, "status") ? undefined : json["status"],
    status_code: !exists(json, "status_code") ? undefined : json["status_code"],
  };
}

export function BaseResponseToJSON(value?: BaseResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
    status_code: value.status_code,
  };
}
