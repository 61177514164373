/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const CountdownStyle = {
  HIDDEN_COUNTDOWN_STYLE: "HIDDEN",
} as const;
export type CountdownStyle =
  (typeof CountdownStyle)[keyof typeof CountdownStyle];

export function CountdownStyleFromJSON(json: any): CountdownStyle {
  return CountdownStyleFromJSONTyped(json, false);
}

export function CountdownStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CountdownStyle {
  return json as CountdownStyle;
}

export function CountdownStyleToJSON(value?: CountdownStyle | null): any {
  return value as any;
}
