/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { OrderType } from "./OrderType";
import {
  OrderTypeFromJSON,
  OrderTypeFromJSONTyped,
  OrderTypeToJSON,
} from "./OrderType";
import type { RsvpGuestBookOrderBy } from "./RsvpGuestBookOrderBy";
import {
  RsvpGuestBookOrderByFromJSON,
  RsvpGuestBookOrderByFromJSONTyped,
  RsvpGuestBookOrderByToJSON,
} from "./RsvpGuestBookOrderBy";

/**
 *
 * @export
 * @interface RsvpGuestBookRequest
 */
export interface RsvpGuestBookRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof RsvpGuestBookRequest
   */
  event_ids: Array<number>;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookRequest
   */
  limit: number;
  /**
   *
   * @type {RsvpGuestBookOrderBy}
   * @memberof RsvpGuestBookRequest
   */
  order_by?: RsvpGuestBookOrderBy;
  /**
   *
   * @type {OrderType}
   * @memberof RsvpGuestBookRequest
   */
  order_type?: OrderType;
  /**
   *
   * @type {number}
   * @memberof RsvpGuestBookRequest
   */
  page: number;
  /**
   *
   * @type {string}
   * @memberof RsvpGuestBookRequest
   */
  search_query?: string;
}

/**
 * Check if a given object implements the RsvpGuestBookRequest interface.
 */
export function instanceOfRsvpGuestBookRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "event_ids" in value;
  isInstance = isInstance && "limit" in value;
  isInstance = isInstance && "page" in value;

  return isInstance;
}

export function RsvpGuestBookRequestFromJSON(json: any): RsvpGuestBookRequest {
  return RsvpGuestBookRequestFromJSONTyped(json, false);
}

export function RsvpGuestBookRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpGuestBookRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_ids: json["event_ids"],
    limit: json["limit"],
    order_by: !exists(json, "order_by")
      ? undefined
      : RsvpGuestBookOrderByFromJSON(json["order_by"]),
    order_type: !exists(json, "order_type")
      ? undefined
      : OrderTypeFromJSON(json["order_type"]),
    page: json["page"],
    search_query: !exists(json, "search_query")
      ? undefined
      : json["search_query"],
  };
}

export function RsvpGuestBookRequestToJSON(
  value?: RsvpGuestBookRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_ids: value.event_ids,
    limit: value.limit,
    order_by: RsvpGuestBookOrderByToJSON(value.order_by),
    order_type: OrderTypeToJSON(value.order_type),
    page: value.page,
    search_query: value.search_query,
  };
}
