/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const BrideGroomStyle = {
  HIDDEN_BRIDE_GROOM_STYLE: "HIDDEN",
  STYLE2_BRIDE_GROOM_STYLE: "STYLE2",
  STYLE3_BRIDE_GROOM: "STYLE3",
  STYLE4_BRIDE_GROOM: "STYLE4",
  ONE_PHOTO: "ONE_PHOTO",
} as const;
export type BrideGroomStyle =
  (typeof BrideGroomStyle)[keyof typeof BrideGroomStyle];

export function BrideGroomStyleFromJSON(json: any): BrideGroomStyle {
  return BrideGroomStyleFromJSONTyped(json, false);
}

export function BrideGroomStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BrideGroomStyle {
  return json as BrideGroomStyle;
}

export function BrideGroomStyleToJSON(value?: BrideGroomStyle | null): any {
  return value as any;
}
