/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DefaultBankResponse
 */
export interface DefaultBankResponse {
  /**
   *
   * @type {string}
   * @memberof DefaultBankResponse
   */
  bank_logo_url?: string;
  /**
   *
   * @type {string}
   * @memberof DefaultBankResponse
   */
  bank_name?: string;
  /**
   *
   * @type {number}
   * @memberof DefaultBankResponse
   */
  id?: number;
}

/**
 * Check if a given object implements the DefaultBankResponse interface.
 */
export function instanceOfDefaultBankResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function DefaultBankResponseFromJSON(json: any): DefaultBankResponse {
  return DefaultBankResponseFromJSONTyped(json, false);
}

export function DefaultBankResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DefaultBankResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bank_logo_url: !exists(json, "bank_logo_url")
      ? undefined
      : json["bank_logo_url"],
    bank_name: !exists(json, "bank_name") ? undefined : json["bank_name"],
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function DefaultBankResponseToJSON(
  value?: DefaultBankResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bank_logo_url: value.bank_logo_url,
    bank_name: value.bank_name,
    id: value.id,
  };
}
