/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RsvpVersion } from "./RsvpVersion";
import {
  RsvpVersionFromJSON,
  RsvpVersionFromJSONTyped,
  RsvpVersionToJSON,
} from "./RsvpVersion";

/**
 *
 * @export
 * @interface ClientMetaResponse
 */
export interface ClientMetaResponse {
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  bride_name?: string;
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  groom_name?: string;
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  image?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClientMetaResponse
   */
  is_locked?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  theme_color?: string;
  /**
   *
   * @type {string}
   * @memberof ClientMetaResponse
   */
  title?: string;
  /**
   *
   * @type {RsvpVersion}
   * @memberof ClientMetaResponse
   */
  version?: RsvpVersion;
}

/**
 * Check if a given object implements the ClientMetaResponse interface.
 */
export function instanceOfClientMetaResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ClientMetaResponseFromJSON(json: any): ClientMetaResponse {
  return ClientMetaResponseFromJSONTyped(json, false);
}

export function ClientMetaResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientMetaResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bride_name: !exists(json, "bride_name") ? undefined : json["bride_name"],
    description: !exists(json, "description") ? undefined : json["description"],
    groom_name: !exists(json, "groom_name") ? undefined : json["groom_name"],
    image: !exists(json, "image") ? undefined : json["image"],
    is_locked: !exists(json, "is_locked") ? undefined : json["is_locked"],
    theme_color: !exists(json, "theme_color") ? undefined : json["theme_color"],
    title: !exists(json, "title") ? undefined : json["title"],
    version: !exists(json, "version")
      ? undefined
      : RsvpVersionFromJSON(json["version"]),
  };
}

export function ClientMetaResponseToJSON(
  value?: ClientMetaResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bride_name: value.bride_name,
    description: value.description,
    groom_name: value.groom_name,
    image: value.image,
    is_locked: value.is_locked,
    theme_color: value.theme_color,
    title: value.title,
    version: RsvpVersionToJSON(value.version),
  };
}
