/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SubmitEventRsvpAttendanceBarcodeResponseData
 */
export interface SubmitEventRsvpAttendanceBarcodeResponseData {
  /**
   *
   * @type {number}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  event_id?: number;
  /**
   *
   * @type {string}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  event_title?: string;
  /**
   *
   * @type {string}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  guest_code?: string;
  /**
   *
   * @type {boolean}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  is_received_gift?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  is_received_souvenir?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SubmitEventRsvpAttendanceBarcodeResponseData
   */
  is_scanned?: boolean;
}

/**
 * Check if a given object implements the SubmitEventRsvpAttendanceBarcodeResponseData interface.
 */
export function instanceOfSubmitEventRsvpAttendanceBarcodeResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function SubmitEventRsvpAttendanceBarcodeResponseDataFromJSON(
  json: any
): SubmitEventRsvpAttendanceBarcodeResponseData {
  return SubmitEventRsvpAttendanceBarcodeResponseDataFromJSONTyped(json, false);
}

export function SubmitEventRsvpAttendanceBarcodeResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SubmitEventRsvpAttendanceBarcodeResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_id: !exists(json, "event_id") ? undefined : json["event_id"],
    event_title: !exists(json, "event_title") ? undefined : json["event_title"],
    guest_code: !exists(json, "guest_code") ? undefined : json["guest_code"],
    is_received_gift: !exists(json, "is_received_gift")
      ? undefined
      : json["is_received_gift"],
    is_received_souvenir: !exists(json, "is_received_souvenir")
      ? undefined
      : json["is_received_souvenir"],
    is_scanned: !exists(json, "is_scanned") ? undefined : json["is_scanned"],
  };
}

export function SubmitEventRsvpAttendanceBarcodeResponseDataToJSON(
  value?: SubmitEventRsvpAttendanceBarcodeResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_id: value.event_id,
    event_title: value.event_title,
    guest_code: value.guest_code,
    is_received_gift: value.is_received_gift,
    is_received_souvenir: value.is_received_souvenir,
    is_scanned: value.is_scanned,
  };
}
