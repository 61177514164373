/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateUsherUserRequest
 */
export interface CreateUsherUserRequest {
  /**
   *
   * @type {number}
   * @memberof CreateUsherUserRequest
   */
  client_id?: number;
  /**
   *
   * @type {string}
   * @memberof CreateUsherUserRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUsherUserRequest
   */
  username?: string;
}

/**
 * Check if a given object implements the CreateUsherUserRequest interface.
 */
export function instanceOfCreateUsherUserRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CreateUsherUserRequestFromJSON(
  json: any
): CreateUsherUserRequest {
  return CreateUsherUserRequestFromJSONTyped(json, false);
}

export function CreateUsherUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateUsherUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    client_id: !exists(json, "client_id") ? undefined : json["client_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    username: !exists(json, "username") ? undefined : json["username"],
  };
}

export function CreateUsherUserRequestToJSON(
  value?: CreateUsherUserRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    client_id: value.client_id,
    name: value.name,
    username: value.username,
  };
}
