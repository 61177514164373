import useImageUploader from "@hooks/ImageUploader";
import { CustomSectionHeaders } from "@pages/Clients/ClientWebDetail/styles";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { Asterisk, ImageWrapper } from "@shared/styles/FormStyles";
import type { Client } from "@/services/openapi/v1";

const Bride = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<Client>();

  const [photoUrl, setPhotoUrl] = useState<string>(
    getValues("content.bride.photo_url")
  );

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_BRIDE",
      old_file_url: photoUrl,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue("content.bride.photo_url", uploadResponse);
      setPhotoUrl(uploadResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form.Group>
      <Form.Label>
        <CustomSectionHeaders>Bride</CustomSectionHeaders>
      </Form.Label>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>
            Name <Asterisk>*</Asterisk>
          </Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.name")}
            isInvalid={!!errors.content?.bride?.mother_name?.message}
          />
          {!!errors.content?.bride?.mother_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.mother_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>
            Full name <Asterisk>*</Asterisk>
          </Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.full_name")}
            isInvalid={!!errors.content?.bride?.full_name?.message}
          />
          {!!errors.content?.bride?.full_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.full_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Prefix name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.prefix_name")}
            isInvalid={!!errors.content?.bride?.prefix_name?.message}
          />
          {!!errors.content?.bride?.prefix_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.prefix_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Suffix name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.suffix_name")}
            isInvalid={!!errors.content?.bride?.suffix_name?.message}
          />
          {!!errors.content?.bride?.suffix_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.suffix_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Child Order</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.child_order")}
            isInvalid={!!errors.content?.bride?.child_order?.message}
          />
          {!!errors.content?.bride?.child_order?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.child_order?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Daughter Order</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.daughter_order")}
            isInvalid={!!errors.content?.bride?.daughter_order?.message}
          />
          {!!errors.content?.bride?.daughter_order?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.daughter_order?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Label>Father name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.father_name")}
            isInvalid={!!errors.content?.bride?.father_name?.message}
          />
          {!!errors.content?.bride?.father_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.father_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={6} className="mb-3">
          <Form.Label>Mother name</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.mother_name")}
            isInvalid={!!errors.content?.bride?.mother_name?.message}
          />
          {!!errors.content?.bride?.mother_name?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.mother_name?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-3">
          <Form.Label>Facebook</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.facebook")}
            isInvalid={!!errors.content?.bride?.facebook?.message}
          />
          {!!errors.content?.bride?.facebook?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.facebook?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={4} className="mb-3">
          <Form.Label>Instagram</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.instagram")}
            isInvalid={!!errors.content?.bride?.instagram?.message}
          />
          {!!errors.content?.bride?.instagram?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.instagram?.message}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md={4} className="mb-3">
          <Form.Label>Twitter</Form.Label>
          <Form.Control
            type="input"
            {...register("content.bride.twitter")}
            isInvalid={!!errors.content?.bride?.twitter?.message}
          />
          {!!errors.content?.bride?.twitter?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.content?.bride?.twitter?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Row>
            {photoUrl && (
              <ImageWrapper className="mb-3 mb-lg-0">
                <img src={photoUrl} alt="bride's photo" />
              </ImageWrapper>
            )}
            <Form.Label>Photo URL</Form.Label>
            <Col md={9}>
              <Form.Control
                type="input"
                {...register("content.bride.photo_url")}
                isInvalid={!!errors.content?.bride?.photo_url?.message}
                disabled
              />
              {!!errors.content?.bride?.photo_url?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.content?.bride?.photo_url?.message}
                </Form.Control.Feedback>
              )}
            </Col>
            <Col md={3} className="mt-3 text-end mt-lg-0">
              <Button
                type="button"
                onClick={handleUpdatePhoto}
                disabled={!getValues("content.bride.name")}
              >
                Update photo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ImageUploadModal />
    </Form.Group>
  );
};

export default Bride;
