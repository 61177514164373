import type { BooksListV2200Response } from "../services/openapi/v1/api";
import type { AxiosError, AxiosResponse } from "axios";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { ApiInstance } from "@services/api";
import { REACT_QUERY_CACHE_KEYS } from "@/shared/constants/CacheKeys";

const fetchAPI = (dataSize: number, page: number) =>
  ApiInstance().booksApi().booksListV2(dataSize, page);

export const useGetRsvpData = (
  dataSize: number,
  page: number,
  options?: UseQueryOptions<
    AxiosResponse<BooksListV2200Response>,
    AxiosError,
    AxiosResponse<BooksListV2200Response>,
    never
  >
) => {
  return useQuery(
    [REACT_QUERY_CACHE_KEYS.getRsvp, dataSize, page],
    async () => {
      const response = await fetchAPI(dataSize, page);
      const modifiedData = response.data.data.map((item) => {
        return item;
      });
      return {
        ...response,
        data: {
          ...response.data,
          data: modifiedData,
        },
      };
    },
    {
      ...options,
    }
  );
};
