/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetOptionSlugsByClientResponseData
 */
export interface GetOptionSlugsByClientResponseData {
  /**
   *
   * @type {number}
   * @memberof GetOptionSlugsByClientResponseData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof GetOptionSlugsByClientResponseData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof GetOptionSlugsByClientResponseData
   */
  slug_alias?: string;
}

/**
 * Check if a given object implements the GetOptionSlugsByClientResponseData interface.
 */
export function instanceOfGetOptionSlugsByClientResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetOptionSlugsByClientResponseDataFromJSON(
  json: any
): GetOptionSlugsByClientResponseData {
  return GetOptionSlugsByClientResponseDataFromJSONTyped(json, false);
}

export function GetOptionSlugsByClientResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOptionSlugsByClientResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
    slug_alias: !exists(json, "slug_alias") ? undefined : json["slug_alias"],
  };
}

export function GetOptionSlugsByClientResponseDataToJSON(
  value?: GetOptionSlugsByClientResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    slug: value.slug,
    slug_alias: value.slug_alias,
  };
}
