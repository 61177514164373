import { ApiInstance, apiClientV2 } from "@/services/api";
import type { Client, Option } from "@/services/openapi/v1";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
import {
  mappingCustomText,
  mappingDateDataContentV2,
} from "@/shared/utils/FormUtils/formUtils";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  revalidateSlug,
  renderPromiseToast,
} from "@/shared/utils/FormUtils/formUtils";

interface Return {
  data: {
    client: Client;
    isDisabled: boolean;
    error: Record<string, never>;
  };
  method: {
    onSubmit(data: Client): Promise<void>;
  };
}

export default function useViewModel(): Return {
  const client: Client = {};
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [error, setError] = useState({});

  const onSubmit = async (data: Client) => {
    setIsDisabled(true);
    const { option, content } = data;
    const newDataContent = mappingDateDataContentV2(content);
    const customText = option.custom_text;
    const newCustomText = mappingCustomText(customText);
    const newOption: Option = { ...option, custom_text: newCustomText };
    const newData = { ...data, content: newDataContent, option: newOption };

    try {
      const createClient = apiClientV2().client.dashboardAdminPhpClientsPost({
        requestBody: newData as unknown as any,
      });
      const responseData = await renderPromiseToast(
        createClient,
        "Creating new client data",
        "Success create new client data!",
        "Error creating new client data"
      );
      console.log(responseData);

      // revalidate slug
      const options = await ApiInstance()
        .clientApi()
        .clientsGetOptions(responseData.data.id.toString());
      await renderPromiseToast(
        revalidateSlug(options.data),
        "Revalidating slugs",
        "Success revalidate slugs!",
        "Error revalidating slugs"
      );
      setIsDisabled(false);
      history.push(RoutePath.CLIENTS);
    } catch (error) {
      setIsDisabled(false);
      console.error(error);
      const { response } = error;
      setError(response.data.error);
    }
  };

  return {
    data: {
      client,
      error,
      isDisabled,
    },
    method: {
      onSubmit,
    },
  };
}
