/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface WishDataResponse
 */
export interface WishDataResponse {
  /**
   *
   * @type {string}
   * @memberof WishDataResponse
   */
  created_at?: string;
  /**
   *
   * @type {number}
   * @memberof WishDataResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof WishDataResponse
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof WishDataResponse
   */
  name?: string;
}

/**
 * Check if a given object implements the WishDataResponse interface.
 */
export function instanceOfWishDataResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function WishDataResponseFromJSON(json: any): WishDataResponse {
  return WishDataResponseFromJSONTyped(json, false);
}

export function WishDataResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WishDataResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created_at: !exists(json, "created_at") ? undefined : json["created_at"],
    id: !exists(json, "id") ? undefined : json["id"],
    message: !exists(json, "message") ? undefined : json["message"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function WishDataResponseToJSON(value?: WishDataResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created_at: value.created_at,
    id: value.id,
    message: value.message,
    name: value.name,
  };
}
