/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
  /**
   *
   * @type {string}
   * @memberof TokenResponse
   */
  token?: string;
}

/**
 * Check if a given object implements the TokenResponse interface.
 */
export function instanceOfTokenResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
  return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TokenResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function TokenResponseToJSON(value?: TokenResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
  };
}
