/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetEventsByClientIdResponseData
 */
export interface GetEventsByClientIdResponseData {
  /**
   *
   * @type {number}
   * @memberof GetEventsByClientIdResponseData
   */
  event_id?: number;
  /**
   *
   * @type {string}
   * @memberof GetEventsByClientIdResponseData
   */
  event_title?: string;
  /**
   *
   * @type {string}
   * @memberof GetEventsByClientIdResponseData
   */
  event_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof GetEventsByClientIdResponseData
   */
  is_guest_book?: boolean;
  /**
   *
   * @type {number}
   * @memberof GetEventsByClientIdResponseData
   */
  option_event_id?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof GetEventsByClientIdResponseData
   */
  option_ids?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof GetEventsByClientIdResponseData
   */
  slug?: string;
}

/**
 * Check if a given object implements the GetEventsByClientIdResponseData interface.
 */
export function instanceOfGetEventsByClientIdResponseData(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetEventsByClientIdResponseDataFromJSON(
  json: any
): GetEventsByClientIdResponseData {
  return GetEventsByClientIdResponseDataFromJSONTyped(json, false);
}

export function GetEventsByClientIdResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEventsByClientIdResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    event_id: !exists(json, "event_id") ? undefined : json["event_id"],
    event_title: !exists(json, "event_title") ? undefined : json["event_title"],
    event_type: !exists(json, "event_type") ? undefined : json["event_type"],
    is_guest_book: !exists(json, "is_guest_book")
      ? undefined
      : json["is_guest_book"],
    option_event_id: !exists(json, "option_event_id")
      ? undefined
      : json["option_event_id"],
    option_ids: !exists(json, "option_ids") ? undefined : json["option_ids"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
  };
}

export function GetEventsByClientIdResponseDataToJSON(
  value?: GetEventsByClientIdResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    event_id: value.event_id,
    event_title: value.event_title,
    event_type: value.event_type,
    is_guest_book: value.is_guest_book,
    option_event_id: value.option_event_id,
    option_ids: value.option_ids,
    slug: value.slug,
  };
}
