import { formatDefaultDateTime } from "@shared/utils/DateUtils";
import useImageUploader from "@hooks/ImageUploader";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import type { Client } from "@/services/openapi/v1";
import { ContentOpeningModalImageTypeEnum } from "@/services/openapi/v1";
import Events from "../ArrayForms/events";
import Galleries from "../ArrayForms/galleries";
import HeroBackgrounds from "../ArrayForms/heroBackgrounds";
import Payments from "../ArrayForms/payments";
import Stories from "../ArrayForms/stories";
import { optionFields } from "@shared/utils/FormUtils/formUtils";
import Bride from "../ObjectForms/bride";
import Groom from "../ObjectForms/groom";
import { Asterisk, ImageWrapper } from "@shared/styles/FormStyles";
import FormGroupWrapper from "./FormGroupWrapper";

const ContentFormArray = [
  {
    label: "Events",
    children: <Events />,
  },
  {
    label: "Payment",
    children: <Payments />,
  },
  {
    label: "Stories",
    children: <Stories />,
  },
  {
    label: "Galleries",
    children: <Galleries />,
  },
  {
    label: "Hero Background",
    children: <HeroBackgrounds />,
  },
];

const ContentForm = () => {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<Client>();

  const params = useParams<{ id: string }>();

  const [openingModalImageUrl, setOpeningModalImageUrl] = useState<string>(
    getValues("content.opening_modal_image")
  );

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_OPENING_MODAL",
      old_file_url: openingModalImageUrl,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue("content.opening_modal_image", uploadResponse);
      setOpeningModalImageUrl(uploadResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Bride />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Groom />
        </Col>
      </Row>
      {ContentFormArray.map((content) => (
        <Row className="mb-3" key={content.label}>
          <FormGroupWrapper label={content.label}>
            {content.children}
          </FormGroupWrapper>
        </Row>
      ))}

      <Row>
        <Col md={6}>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>
                Wedding date <Asterisk>*</Asterisk>
              </Form.Label>
              <Controller
                control={control}
                name={"content.wedding_date"}
                render={({ field }) => {
                  return (
                    <Form.Control
                      defaultValue={formatDefaultDateTime(field.value)}
                      onChange={(e) => field.onChange(e.target.value)}
                      type="datetime-local"
                      isInvalid={!!errors.content?.wedding_date?.message}
                    />
                  );
                }}
              />
              {!!errors.content?.wedding_date?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.wedding_date?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Row>
              {openingModalImageUrl && (
                <ImageWrapper className="mb-3 mb-lg-0">
                  <img src={openingModalImageUrl} alt="opening modal's photo" />
                </ImageWrapper>
              )}
              <Form.Label>Opening modal Image URL</Form.Label>
              <Col md={9}>
                <Form.Control
                  type="input"
                  {...register("content.opening_modal_image")}
                  isInvalid={!!errors.content?.opening_modal_image?.message}
                  disabled
                />
                {!!errors.content?.opening_modal_image?.message && (
                  <FormGroupWrapper isError>
                    {errors.content?.opening_modal_image?.message}
                  </FormGroupWrapper>
                )}
              </Col>
              <Col md={3} className="mt-3 text-end mt-lg-0">
                <Button
                  type="button"
                  onClick={handleUpdatePhoto}
                  disabled={!params.id}
                >
                  Update photo
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Opening modal Image Type</Form.Label>
              <Controller
                control={control}
                name={"content.opening_modal_image_type"}
                render={({ field }) => {
                  return (
                    <Form.Group>
                      <Form.Select
                        defaultValue={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        isInvalid={
                          !!errors.content?.opening_modal_image?.message
                        }
                      >
                        {optionFields(ContentOpeningModalImageTypeEnum)}
                      </Form.Select>
                      {!!errors.content?.opening_modal_image_type?.message && (
                        <FormGroupWrapper isError>
                          {errors.content?.opening_modal_image_type?.message}
                        </FormGroupWrapper>
                      )}
                    </Form.Group>
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Col>

        <Col md={6}>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Hashtag Word</Form.Label>
              <Form.Control
                type="input"
                {...register("content.hashtag_word")}
                isInvalid={!!errors.content?.hashtag_word?.message}
              />
              {!!errors.content?.hashtag_word?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.hashtag_word?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Quote</Form.Label>
              <Form.Control
                type="input"
                {...register("content.quote")}
                isInvalid={!!errors.content?.quote?.message}
              />
              {!!errors.content?.quote?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.quote?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Quote Source</Form.Label>
              <Form.Control
                type="input"
                {...register("content.quote_source")}
                isInvalid={!!errors.content?.quote_source?.message}
              />
              {!!errors.content?.quote_source?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.quote_source?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Video Preweding URL</Form.Label>
              <Form.Control
                type="input"
                {...register("content.video_prewed_url")}
                isInvalid={!!errors.content?.video_prewed_url?.message}
              />
              {!!errors.content?.video_prewed_url?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.video_prewed_url?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group>
              <Form.Label>Reminder URL</Form.Label>
              <Form.Control
                type="input"
                {...register("content.reminder_url")}
                isInvalid={!!errors.content?.reminder_url?.message}
              />
              {!!errors.content?.reminder_url?.message && (
                <FormGroupWrapper isError>
                  {errors.content?.reminder_url?.message}
                </FormGroupWrapper>
              )}
            </Form.Group>
          </Col>
        </Col>
      </Row>
      <ImageUploadModal />
    </>
  );
};

export default ContentForm;
