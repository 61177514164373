/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BrideResponse } from "./BrideResponse";
import {
  BrideResponseFromJSON,
  BrideResponseFromJSONTyped,
  BrideResponseToJSON,
} from "./BrideResponse";
import type { EventResponse } from "./EventResponse";
import {
  EventResponseFromJSON,
  EventResponseFromJSONTyped,
  EventResponseToJSON,
} from "./EventResponse";
import type { GalleryResponse } from "./GalleryResponse";
import {
  GalleryResponseFromJSON,
  GalleryResponseFromJSONTyped,
  GalleryResponseToJSON,
} from "./GalleryResponse";
import type { GroomResponse } from "./GroomResponse";
import {
  GroomResponseFromJSON,
  GroomResponseFromJSONTyped,
  GroomResponseToJSON,
} from "./GroomResponse";
import type { HeroBackgroundResponse } from "./HeroBackgroundResponse";
import {
  HeroBackgroundResponseFromJSON,
  HeroBackgroundResponseFromJSONTyped,
  HeroBackgroundResponseToJSON,
} from "./HeroBackgroundResponse";
import type { OpeningModalImageType } from "./OpeningModalImageType";
import {
  OpeningModalImageTypeFromJSON,
  OpeningModalImageTypeFromJSONTyped,
  OpeningModalImageTypeToJSON,
} from "./OpeningModalImageType";
import type { PaymentResponse } from "./PaymentResponse";
import {
  PaymentResponseFromJSON,
  PaymentResponseFromJSONTyped,
  PaymentResponseToJSON,
} from "./PaymentResponse";
import type { StoryResponse } from "./StoryResponse";
import {
  StoryResponseFromJSON,
  StoryResponseFromJSONTyped,
  StoryResponseToJSON,
} from "./StoryResponse";

/**
 *
 * @export
 * @interface ContentResponse
 */
export interface ContentResponse {
  /**
   *
   * @type {BrideResponse}
   * @memberof ContentResponse
   */
  bride?: BrideResponse;
  /**
   *
   * @type {Array<EventResponse>}
   * @memberof ContentResponse
   */
  events?: Array<EventResponse>;
  /**
   *
   * @type {Array<GalleryResponse>}
   * @memberof ContentResponse
   */
  galleries?: Array<GalleryResponse>;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  galleries_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  galleries_mobile_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  greeting?: string;
  /**
   *
   * @type {GroomResponse}
   * @memberof ContentResponse
   */
  groom?: GroomResponse;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  hashtag_word?: string;
  /**
   *
   * @type {Array<HeroBackgroundResponse>}
   * @memberof ContentResponse
   */
  hero_backgrounds?: Array<HeroBackgroundResponse>;
  /**
   *
   * @type {number}
   * @memberof ContentResponse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  opening_modal_image?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  opening_modal_image_css?: string;
  /**
   *
   * @type {OpeningModalImageType}
   * @memberof ContentResponse
   */
  opening_modal_image_type?: OpeningModalImageType;
  /**
   *
   * @type {Array<PaymentResponse>}
   * @memberof ContentResponse
   */
  payments?: Array<PaymentResponse>;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  quote?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  quote_source?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  reminder_url?: string;
  /**
   *
   * @type {Array<StoryResponse>}
   * @memberof ContentResponse
   */
  stories?: Array<StoryResponse>;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  video_prewed_url?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  wedding_date?: string;
}

/**
 * Check if a given object implements the ContentResponse interface.
 */
export function instanceOfContentResponse(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ContentResponseFromJSON(json: any): ContentResponse {
  return ContentResponseFromJSONTyped(json, false);
}

export function ContentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContentResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bride: !exists(json, "bride")
      ? undefined
      : BrideResponseFromJSON(json["bride"]),
    events: !exists(json, "events")
      ? undefined
      : (json["events"] as Array<any>).map(EventResponseFromJSON),
    galleries: !exists(json, "galleries")
      ? undefined
      : (json["galleries"] as Array<any>).map(GalleryResponseFromJSON),
    galleries_css: !exists(json, "galleries_css")
      ? undefined
      : json["galleries_css"],
    galleries_mobile_css: !exists(json, "galleries_mobile_css")
      ? undefined
      : json["galleries_mobile_css"],
    greeting: !exists(json, "greeting") ? undefined : json["greeting"],
    groom: !exists(json, "groom")
      ? undefined
      : GroomResponseFromJSON(json["groom"]),
    hashtag_word: !exists(json, "hashtag_word")
      ? undefined
      : json["hashtag_word"],
    hero_backgrounds: !exists(json, "hero_backgrounds")
      ? undefined
      : (json["hero_backgrounds"] as Array<any>).map(
          HeroBackgroundResponseFromJSON
        ),
    id: !exists(json, "id") ? undefined : json["id"],
    opening_modal_image: !exists(json, "opening_modal_image")
      ? undefined
      : json["opening_modal_image"],
    opening_modal_image_css: !exists(json, "opening_modal_image_css")
      ? undefined
      : json["opening_modal_image_css"],
    opening_modal_image_type: !exists(json, "opening_modal_image_type")
      ? undefined
      : OpeningModalImageTypeFromJSON(json["opening_modal_image_type"]),
    payments: !exists(json, "payments")
      ? undefined
      : (json["payments"] as Array<any>).map(PaymentResponseFromJSON),
    quote: !exists(json, "quote") ? undefined : json["quote"],
    quote_source: !exists(json, "quote_source")
      ? undefined
      : json["quote_source"],
    reminder_url: !exists(json, "reminder_url")
      ? undefined
      : json["reminder_url"],
    stories: !exists(json, "stories")
      ? undefined
      : (json["stories"] as Array<any>).map(StoryResponseFromJSON),
    video_prewed_url: !exists(json, "video_prewed_url")
      ? undefined
      : json["video_prewed_url"],
    wedding_date: !exists(json, "wedding_date")
      ? undefined
      : json["wedding_date"],
  };
}

export function ContentResponseToJSON(value?: ContentResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    bride: BrideResponseToJSON(value.bride),
    events:
      value.events === undefined
        ? undefined
        : (value.events as Array<any>).map(EventResponseToJSON),
    galleries:
      value.galleries === undefined
        ? undefined
        : (value.galleries as Array<any>).map(GalleryResponseToJSON),
    galleries_css: value.galleries_css,
    galleries_mobile_css: value.galleries_mobile_css,
    greeting: value.greeting,
    groom: GroomResponseToJSON(value.groom),
    hashtag_word: value.hashtag_word,
    hero_backgrounds:
      value.hero_backgrounds === undefined
        ? undefined
        : (value.hero_backgrounds as Array<any>).map(
            HeroBackgroundResponseToJSON
          ),
    id: value.id,
    opening_modal_image: value.opening_modal_image,
    opening_modal_image_css: value.opening_modal_image_css,
    opening_modal_image_type: OpeningModalImageTypeToJSON(
      value.opening_modal_image_type
    ),
    payments:
      value.payments === undefined
        ? undefined
        : (value.payments as Array<any>).map(PaymentResponseToJSON),
    quote: value.quote,
    quote_source: value.quote_source,
    reminder_url: value.reminder_url,
    stories:
      value.stories === undefined
        ? undefined
        : (value.stories as Array<any>).map(StoryResponseToJSON),
    video_prewed_url: value.video_prewed_url,
    wedding_date: value.wedding_date,
  };
}
