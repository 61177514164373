/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ContentUpdateResponse } from "./ContentUpdateResponse";
import {
  ContentUpdateResponseFromJSON,
  ContentUpdateResponseFromJSONTyped,
  ContentUpdateResponseToJSON,
} from "./ContentUpdateResponse";
import type { OptionUpdateRequest } from "./OptionUpdateRequest";
import {
  OptionUpdateRequestFromJSON,
  OptionUpdateRequestFromJSONTyped,
  OptionUpdateRequestToJSON,
} from "./OptionUpdateRequest";

/**
 *
 * @export
 * @interface ClientUpdateRequest
 */
export interface ClientUpdateRequest {
  /**
   *
   * @type {ContentUpdateResponse}
   * @memberof ClientUpdateRequest
   */
  content?: ContentUpdateResponse;
  /**
   *
   * @type {string}
   * @memberof ClientUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {OptionUpdateRequest}
   * @memberof ClientUpdateRequest
   */
  option?: OptionUpdateRequest;
}

/**
 * Check if a given object implements the ClientUpdateRequest interface.
 */
export function instanceOfClientUpdateRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function ClientUpdateRequestFromJSON(json: any): ClientUpdateRequest {
  return ClientUpdateRequestFromJSONTyped(json, false);
}

export function ClientUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    content: !exists(json, "content")
      ? undefined
      : ContentUpdateResponseFromJSON(json["content"]),
    name: !exists(json, "name") ? undefined : json["name"],
    option: !exists(json, "option")
      ? undefined
      : OptionUpdateRequestFromJSON(json["option"]),
  };
}

export function ClientUpdateRequestToJSON(
  value?: ClientUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    content: ContentUpdateResponseToJSON(value.content),
    name: value.name,
    option: OptionUpdateRequestToJSON(value.option),
  };
}
