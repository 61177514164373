/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const HeroStyle = {
  HIDDEN_HERO_STYLE: "HIDDEN",
  WITH_VIDEO: "WITH_VIDEO",
  NAME_NEW_LINES: "NAME_NEW_LINES",
} as const;
export type HeroStyle = (typeof HeroStyle)[keyof typeof HeroStyle];

export function HeroStyleFromJSON(json: any): HeroStyle {
  return HeroStyleFromJSONTyped(json, false);
}

export function HeroStyleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HeroStyle {
  return json as HeroStyle;
}

export function HeroStyleToJSON(value?: HeroStyle | null): any {
  return value as any;
}
