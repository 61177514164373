/* tslint:disable */
/* eslint-disable */
/**
 * Koendang Go API
 * Koendang API With Go Programming Language
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const RsvpVersion = {
  V1: "V1",
  V2: "V2",
} as const;
export type RsvpVersion = (typeof RsvpVersion)[keyof typeof RsvpVersion];

export function RsvpVersionFromJSON(json: any): RsvpVersion {
  return RsvpVersionFromJSONTyped(json, false);
}

export function RsvpVersionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RsvpVersion {
  return json as RsvpVersion;
}

export function RsvpVersionToJSON(value?: RsvpVersion | null): any {
  return value as any;
}
